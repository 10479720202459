import { FileOutlined, FormOutlined } from "@ant-design/icons";
import { Avatar, Divider, Space } from "antd";
import { findLevelsById } from "api/services/levels/levels.services";
import FollowIcon from "assets/images/app/Action_Follow.svg";
import AvatarTeacher from "assets/images/teacher/teacher.svg";
import { StatusCode, UserRoles } from "common/enums";
import AppLatex from "components/appLatex";
import { motion } from "framer-motion";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import LeftDrawer from "screens/teacher/components/LeftDrawer";
import { useGetPostsCountsQuery } from "store/services/teacher/activities.services";
import s from "./style.module.css";

interface Props { }

interface TeacherInfosProps {
  postsCount: any;
  docsCount: any;
}

const TeacherInfos: React.FC<TeacherInfosProps> = ({ postsCount, docsCount }) => {

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => setVisible(false);

  const { user } = useSelector((state) => state.auth);

  const { data, isLoading, isError, refetch } = useGetPostsCountsQuery(user?._id);
  const [allData, setAllData] = useState<any>({});

  const isStudent = user?.roles?.includes(UserRoles.STUDENT);
  const [tempLevelSelected, setTempLevelSelected] = useState<any>(null);

  const getLevelById = useCallback(
    async (idLevel: any) => {
      try {
        if (idLevel) {
          const response = await findLevelsById(idLevel);
          if (response.statusCode === StatusCode.OK) {
            setTempLevelSelected(response?.data['title']);
          }
        }
      } catch (error) {
        // message.error("Une erreur non connue s'est produite.");
      }
    },
    [tempLevelSelected]
  );

  useEffect(() => {
    if (data?.data) {
      setAllData(data.data);
    }
    if (isStudent) {
      getLevelById(user?.levelId);
    }
  }, [data, tempLevelSelected]);

  useEffect(() => {
    console.log(user?.career);
  }, [user]);

  return (
    <>
      <div className={isStudent ? s.student__infos : s.teacher__infos}>
        <div className={s.top}>
          <div className={s.avatar}>
            <Avatar
              size={70}
              src={user?.profile || AvatarTeacher}
              className={s.profile}
              style={{ background: "#fff" }}
            />
          </div>
        </div>

        <div className={s.content}>
          <div className={s.content_top}>
            <h2>
              {user?.firstname?.split(" ")[0]} {user?.lastname?.split(" ")[0]}
            </h2>
            {isStudent ? <div className={s.follow} style={{ background: "white", width: "120px", padding: "5px", margin: "0px auto" }}>{tempLevelSelected}</div> : <div className={s.follow} style={{ background: "white", width: "120px", padding: "5px", margin: "0px auto" }}>
              <motion.img
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.9 }}
                src={FollowIcon}
                alt=""
                style={{ position: "relative", top: "-3px", left: "-2px" }}
                width={15}
              />
              <span style={{ fontWeight: "500" }}>{user?.followNum || 0}</span>{" "}
              <span>Follower{`(s)`}</span>{" "}
            </div>}

            {<div style={{ marginTop: "10px", fontWeight: "bold" }}>
              {user?.career !== "" && <AppLatex content={user?.career?.split("<p>")[1].split("</p>")[0]} />}
            </div>}

            {user?.career?.split("<p>")[2].split("</p>")[0].length > 150 ? <div style={{ marginTop: "10px" }}>
              <AppLatex content={user?.career?.split("<p>")[2].split("</p>")[0].substring(0, 150) + "..."} />
            </div> : <div style={{ marginTop: "10px" }}>
              <AppLatex content={user?.career?.split("<p>")[2].split("</p>")[0]} />
            </div>}

            {!isStudent && <a onClick={showDrawer} className={s.readMore}>Lire la suite</a>}
          </div>

          {user?.roles.includes("teacher") && (
            <>
              <Divider />

              <div>
                <Space>
                  <FormOutlined style={{ color: "var(--first-color)" }} />{" "}
                  <div className={s.text_stats}>
                    {" "}
                    {postsCount === 0 || postsCount === 1 ? (
                      <span>{postsCount} publication en ligne</span>
                    ) : (
                      <span>{postsCount} publications en ligne</span>
                    )}
                  </div>
                </Space>
                <Space>
                  <FileOutlined style={{ color: "var(--first-color)" }} />{" "}
                  <div className={s.text_stats}>
                    {" "}
                    {docsCount === 0 || docsCount === 1 ? (
                      <span>{docsCount} document publié</span>
                    ) : (
                      <span>{docsCount} documents publiés</span>
                    )}
                  </div>
                </Space>
              </div>
            </>
          )}
        </div>
      </div>
      <LeftDrawer data={user?.career} visible={visible} onClose={onClose} userImage={user?.profile} userName={user?.firstname} userLastname={user?.lastname} />
    </>
  );
};

export default TeacherInfos;
