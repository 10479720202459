import { Col, Form, Row, Select, Skeleton } from "antd";
import React, { FC, useCallback, useRef, useState } from "react";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!COMPONENTS

//!STYLES
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { findFilterQuizzOfCours } from "api/services/studentQuizz/studentQuizz.services";
import { PlayLevel, StatusCode, UserRoles } from "common/enums";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import Flex from "components/libs/flex";
import * as appRoutes from "config/routes.config";
import useGetQuiz from "hooks/useGetQuizz";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import HeaderComponents from "screens/student/components/headerComponents";
import TeacherHeaderstabs from "../components/TeacherHeaderstabs";
import CardQuizz from "../components/cardQuizz";
import s from "./assets/easyQuiz.module.css";

const FindQuizForChapter: FC = () => {
  const { idCours } = useSelector((state: any) => state.courses);
  const { id, level } = useParams();
  const navigate = useNavigate();
  const { loading, data, setData, setLoading } = useGetQuiz(id!, level!);
  // const { quizForCours, isloading, error } = useQuizzForCours(idCours);
  //
  const refSubmited = useRef<any>();
  const { user } = useSelector((state) => state.auth);
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);

  const { Option } = Select;
  function handleChange(value: string) {
    console.log(`selected ${value}`);
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const filterQuiz = useCallback(
    async (field) => {
      setLoading(true);
      try {
        const response = await findFilterQuizzOfCours(
          // field[0]?.name[0] === "idCours" ? field[0]?.value : "",
          id!,
          field[0]?.name[0] === "quizzLevel" ? field[0]?.value : "",
          field[0]?.name[0] === "duration" ? field[0]?.value : ""
        );

        if (response?.statusCode === StatusCode.OK) {
          setData(response?.data);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [data]
  );

  const onChange = (e: CheckboxChangeEvent) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <AppScreen className={s.easyQuiz}>
        <HeaderComponents />
        <TeacherHeaderstabs level={level} onRequest={() => { }} onExit={() => { }} />
        <div className={s.header}>
          <div className="left">
            <span style={{ color: "var(--first-color)" }}>
              {data.length} Quiz trouvé(s) - {level}
            </span>
          </div>

          <div>
            <Form
              name="basic"
              // onFinish={onFinish}
              autoComplete="off"
              onFieldsChange={filterQuiz}
            >
              <Row gutter={12} align="middle" className={s.formElement}>
                <Col>
                  <div className={`${s.select}`}>
                    <Form.Item name="quizzLevel">
                      <Select placeholder="Niveau quiz" bordered={false}>
                        <Option value={PlayLevel.EASY}>{PlayLevel.EASY}</Option>
                        <Option value={PlayLevel.MEDIUM}>
                          {PlayLevel.MEDIUM}
                        </Option>
                        <Option value={PlayLevel.HARD}>{PlayLevel.HARD}</Option>
                        <Option value="">Tous les quiz</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>

                <Col>
                  <div className={`${s.select}`}>
                    <Form.Item name="duration">
                      <Select
                        placeholder="Durée"
                        // loading={isloading}
                        bordered={false}
                        allowClear
                      >
                        <Option value={15}>15min</Option>
                        <Option value={30}>30 min</Option>
                        <Option value={45}>45min</Option>
                        <Option value={60}>1h</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                {isTeacher && user?.isActive && user?.step === "completed" && !isMobile && <Col>
                  <Link to={appRoutes.TEACHER_CREATE__QUIZ}>
                    <AppButton>Proposer un quiz</AppButton>
                  </Link>
                </Col>}
              </Row>
            </Form>
          </div>
        </div>

        <div className="main">
          {loading && (
            <div>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </div>
          )}

          {!loading && (
            <Flex>
              {data?.result.map((quiz: any, i: number) => (
                <React.Fragment key={i}>
                  <CardQuizz quizz={quiz} />
                </React.Fragment>
              ))}
            </Flex>
          )}

          {!loading && data?.result?.length <= 0 && (
            <AppResult status="info" title="Aucun quiz disponible pour le moment!" />
          )}
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default FindQuizForChapter;
