import React, { useState } from 'react';
import { Drawer, Collapse, Image } from 'antd';
import AppButton from 'components/libs/button';
import docs from '../../../../assets/images/app/docs_icon.png';
const { Panel } = Collapse;

interface LinkItem {
    idDoc: string;
    title: string;
    url: string;
}

interface SectionItem {
    _id: string;
    topic: string;
    title: string;
    links: LinkItem[];
}

interface DataAccordionDrawerProps {
    data: SectionItem[];
}

const DataAccordionDrawer: React.FC<DataAccordionDrawerProps> = ({ data }) => {

    const [visible, setVisible] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    return (
        <>
            <AppButton onClick={showDrawer}>
                <img src={docs} width={15} style={{ marginRight: "10px" }} />
                Annales
            </AppButton>
            <Drawer
                title="Annales"
                placement="right"
                width={isMobile ? "100%" : "35%"}
                closable={isMobile ? true : false}
                onClose={onClose}
                visible={visible}
                style={{ borderRadius: "0px", border: "none" }}
                bodyStyle={{ borderRadius: "0px", border: "none" }}

            >
                <Collapse accordion style={{ borderRadius: "0px" }}>
                    {data.map((section, sectionIndex) => (
                        <Panel header={section.title} key={sectionIndex} style={{ borderRadius: "0px", paddingLeft: "7px" }}>
                            {section.links.map((link, linkIndex) => (
                                <div key={linkIndex} style={{ marginTop: "7px" }}><a href={link.url} target='blank' style={{ color: "#707070" }}>{link.title}</a></div>
                            ))}
                        </Panel>
                    ))}
                </Collapse>
            </Drawer>
        </>
    );
};

export default DataAccordionDrawer;
