import { PlusOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useDispatch } from "react-redux";
import { displayDrawerAdsSpecific } from "store/adventissements";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import s from "./assets/calendar.module.css";
import TeacherAvailabilitySpecific from "../availabilitySpecific";

const TeacherCalendarSpecific: FC = () => {
  const dispatch = useDispatch();

  //!FUNCTIONS
  return (
    <>
      <div className={s.calendar}>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>Mes créneaux spéciaux</H2>
          </div>

          <div className={s.center} />

          <div className={s.right}>
            <AppButton
              icon={<PlusOutlined />}
              onClick={() => {
                dispatch(displayDrawerAdsSpecific(true));
              }}
            >
              Ajouter un créneau spécial
            </AppButton>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.calendrier}>
            <TeacherAvailabilitySpecific />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherCalendarSpecific;
