import { Col, Drawer, Image, Row } from "antd";
import { findProgrammeStudent } from "api/services/studentReservation/studentReservation.services";
import React, { FC, useCallback, useEffect, useState } from "react";

//!COMPONENTS
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import Matiers from "components/matiers";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!IMAGES
import { ReactComponent as Back } from "../../../assets/images/app/backBlack.svg";
import { ReactComponent as Camera } from "../../../assets/images/app/camera.svg";
import TeacherSvg from "../.././../assets/images/teacher/teacher.svg";

//!STYLES
import moment from "moment";
import { formatDates } from "utils/formats";
import s from "./assets/calendar.module.css";
import DrawerHistorique from "./components";
import CalendarOfReservationStudent from "./components/calendarReservations";

type Props = {
  user: any,
}

const ParentStudentCalendar: FC<Props> = ({ user }) => {

  //!STATE
  const [data, setData] = useState<any>();
  const [visible, setVisible] = useState(false);
  const [openDrawerHistorique, setOpenDrawerHistorique] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedAd, setSelectedAd] = useState<any | null>(null);

  //!FUNCTION
  const showDrawer = () => setVisible(true);
  const onClose = () => setVisible(false);

  const getReservation = useCallback(async () => {
    setLoading(true);
    try {
      const response = await findProgrammeStudent(user?._id);
      if (response) {
        setData(response?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    getReservation();
  }, []);

  const THREE_DAYS_IN_MS = 3 * 24 * 60 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();

  const dateTimeAfterDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  return (
    <React.Fragment>
      <AppScreen>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: "20px" }}>
          <Image
            preview={false}
            src={user['profile']}
            width={45}
            alt="Avatar"
          />
          <span className={s.boxTitle}>{user['lastname'] + " " + user['firstname']}</span>
        </div>
        <H1 className={s.title}>Mon calendrier</H1>
        <Row justify={isMobile ? "center" : "end"}>
          <Col>
            <AppButton onClick={() => setOpenDrawerHistorique(true)}>
              Mon historique
            </AppButton>
          </Col>
        </Row>
        <div className={s.main}>
          <div className={s.calendrier}>
            <CalendarOfReservationStudent />
            {/* <Result title="Coming soon" /> */}
          </div>
        </div>
      </AppScreen>

      <Drawer
        title={null}
        width={"70%"}
        placement="right"
        onClose={onClose}
        visible={visible}
        closeIcon={
          <Back
            style={{
              width: "35px",
            }}
          />
        }
      >
        <Row justify="center">
          <div className={s.__drawer_header}>
            <h3>Réservation confirmée</h3>
            <span>
              Le {formatDates(selectedAd?.createdAt)} à{" "}
              {moment(selectedAd?.createdAt).format("LT")}min
            </span>
            <Matiers
              className={s.matiere}
              level={selectedAd?.level[0].title}
              cours={selectedAd?.topic?.title}
            />
          </div>
        </Row>
        <Row justify="center">
          <div className={s.__drawer__card}>
            <div className={s.profile}>
              <Image preview={false} className={s.__avatar} src={TeacherSvg} />
            </div>
            <h4 className={s.name}>
              {selectedAd?.teacher?.firstname} {selectedAd?.teacher?.lastname}
            </h4>

            <div className={s.card__content}>
              <span>Le cours démarre dans :</span>
              <div className={s.countDown}>
                {" "}
                <span>01 Jour : 30 Min : 10 sec</span>
              </div>
              <a href={selectedAd?.studentLink} target="_blank">
                <AppButton className={s.__button} icon={<Camera />}>
                  Lien viseoconférence
                </AppButton>
              </a>
            </div>
          </div>
        </Row>
      </Drawer>

      <DrawerHistorique
        open={openDrawerHistorique}
        setOpen={setOpenDrawerHistorique}
        user={user}
      />
    </React.Fragment>
  );
};

export default ParentStudentCalendar;
