import { Col, Form, Input, Row, Select, Spin, message } from "antd";
import * as EXERCICES_SERVICES from "api/services/exercises/exercises.services";
import { CKEditor } from "ckeditor4-react";
import { CHOICE, StatusCode, TypeExercices } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import ExercicePreviewUpdate from "components/previewExo/previewUpdate";
import * as appRoutes from "config/routes.config";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/images/app/back.svg";
import AppScreen from "../ScreenWithSidebar";
import s from "./styles.module.css";

const SingleExercise = () => {
  // !HOOKS
  const { id } = useParams();
  const navigate = useNavigate();
  const [formx] = Form.useForm();
  const [isLoading, setisLoading] = useState(false);
  const [exercice, setExercice] = useState<any>();
  const { user } = useSelector((s) => s.auth);
  const [title, setTitle] = useState<string>("");
  const [type, setType] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [comingData, setComingData] = useState<string>("");
  const [clue, setClue] = useState<string>("");
  const [solution, setSolution] = useState<string>("");
  // !!CONTROLLERS_VISIBILITY
  const [hideButton, setHideButton] = useState(false);
  const [hideButtonSolution, setHideButtonSolution] = useState(false);
  const [tempContent, setTempContent] = useState<any>("");
  const [tempContentClue, setTempContentClue] = useState<any>("");
  const [isUpdatingExercise, setIsUpdatingExercise] = useState<boolean>(false);
  const [isUpdatingBrouillonExercise, setIsUpdatingBrouillonExercise] =
    useState<boolean>(false);
  const [preview, setPreview] = useState<any>([]);
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [previewType, setPreviewType] = useState<string>("");

  // !FUNCTIONS
  const fetchExercice = useCallback(
    async (id) => {
      setisLoading(true);
      try {
        const response = await EXERCICES_SERVICES.findOne_Second(id);
        if (response?.statusCode === StatusCode.OK) {
          setisLoading(false);
          setExercice(response.data);
          formx.setFieldsValue({
            title: response.data?.title,
            description: response.data?.description,
          });
          setDescription(response.data?.description);
          setComingData(response.data?.description);
          setTitle(response.data?.title);
          setSolution(response.data?.solution);
          setClue(response.data?.clue);

          setHideButton(typeof response.data?.clue === "undefined");
          setHideButtonSolution(typeof response.data?.solution === "undefined");
        } else {
          setisLoading(false);
        }
      } catch (error: any) {
        setisLoading(false);
      }
    },
    [comingData, previewTitle]
  );

  const deleteClue = useCallback(async (idExo: string, id: string) => {
    try {
      await EXERCICES_SERVICES.deleteClue(idExo, id);
    } catch (error: any) {}
  }, []);

  const deleteSolution = useCallback(async (idExo: string, id: string) => {
    try {
      await EXERCICES_SERVICES.deleteSolution(idExo, id);
    } catch (error: any) {}
  }, []);

  const updateExercice = useCallback(
    async (
      flag: string,
      id: string,
      t: string,
      d: string,
      cl: string,
      tempContentC,
      so: string,
      tempCS: string,
      typeExercice: string
    ) => {
      try {
        let response;
        if (flag === CHOICE.SAVE_PERMANENTLY) {
          // !LoaderSavePermanently
          setIsUpdatingExercise(true);
          // !Api
          response = await EXERCICES_SERVICES.updateExercice(
            flag,
            id,
            t,
            d,
            cl,
            tempContentC,
            so,
            tempCS,
            typeExercice
          );
        } else {
          // !LoaderDraft
          setIsUpdatingBrouillonExercise(true);
          response = await EXERCICES_SERVICES.updateExercice(
            flag,
            id,
            t,
            d,
            cl,
            tempContentC,
            so,
            tempCS,
            typeExercice
          );
        }
        // !LoaderDraft
        // !LoaderSavePermanently
        setIsUpdatingExercise(false);
        setIsUpdatingBrouillonExercise(false);
        if (response?.statusCode === StatusCode.OK) {
          message.success({
            content: "Exercice modifé avec succès!",
          });
          if (flag === CHOICE.SAVE_PERMANENTLY) {
            navigate(appRoutes.TEACHER_EXERCICES);
          }
        } else {
          message.warning({
            content:
              "Certains champs ne sont pas mis à jour, veuillez réessayer ?",
          });
        }
      } catch (error: any) {}
    },
    []
  );

  const update = (
    flag: any,
    id = exercice._id,
    t = title,
    d = description,
    cl = clue,
    tempContentC = tempContentClue,
    so = solution,
    tempCS = tempContent,
    typeExercice = type
  ) => {
    try {
      updateExercice(
        flag,
        id,
        t,
        d,
        cl,
        tempContentC,
        so,
        tempCS,
        typeExercice
      );
    } catch (error) {}
  };
  // !USE_EFFECTS
  useEffect(() => {
    fetchExercice(id || "");
  }, [fetchExercice, id]);

  const { Option } = Select;

  const previewEdit = (_: any, allFields: any) => {
    let content = allFields.filter((x: any) => {
      return x?.touched === true;
    });

    setPreview(content);
  };

  return (
    <React.Fragment>
      <Breadcrumbs />
      <AppScreen>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>{exercice?.title}</H2>
          </div>
          <div className={s.center} />
          <div className={s.right}>
            <Link to={`${appRoutes.TEACHER_EXERCICES}`}>
              <AppButton
                icon={
                  <BackIcon
                    style={{
                      position: "relative",
                      top: "3px",
                      left: "-10px",
                      width: "15px",
                    }}
                  />
                }
                className={s.header__button}
              >
                Retour
              </AppButton>
            </Link>
          </div>
        </div>

        {!isLoading ? (
          <Form
            form={formx}
            style={{ padding: 20 }}
            layout="vertical"
            onFinish={() => {}}
            onFieldsChange={previewEdit}
            initialValues={exercice}
          >
            <div className={s.main__exo__edit}>
              <Row justify="space-between">
                <Col span={12}>
                  <div className={s.main__title}>
                    <h3>Cours associé : {exercice?.course?.title}</h3>
                  </div>

                  <div>
                    <div className={s.label}>
                      <label htmlFor="">Titre de l’exercice</label>
                    </div>
                    <div className={s.input}>
                      <Form.Item name="title">
                        <Input
                          bordered={false}
                          onChange={(changes: any) => {
                            const content = changes;
                            setTitle(content.target.value);
                            setPreviewTitle(changes.target.value);
                          }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <div className={s.label}>
                      <label htmlFor="">Type de l’exercice </label>
                    </div>
                    <div className={s.input}>
                      <Form.Item
                        name={"type"}
                        rules={[
                          {
                            required: true,
                            message: "Ce champ est requis",
                          },
                        ]}
                        initialValue={exercice?.type}
                      >
                        <Select
                          defaultValue={exercice?.type}
                          bordered={false}
                          allowClear
                          onChange={(value: any) => {
                            setType(value);
                            setPreviewType(value);
                          }}
                        >
                          <Option value={TypeExercices.ApplicationsDeCours}>
                            {TypeExercices.ApplicationsDeCours}
                          </Option>
                          <Option value={TypeExercices.Classiques}>
                            {TypeExercices.Classiques}
                          </Option>
                          <Option value={TypeExercices.Annales}>
                            {TypeExercices.Annales}
                          </Option>
                          <Option value={TypeExercices.Entraînement}>
                            {TypeExercices.Entraînement}
                          </Option>
                        </Select>
                        {/* <Input placeholder="" className={s.__input_text} /> */}
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <div className={s.label}>
                      <label htmlFor="">Contenu de l’énoncé</label>
                    </div>

                    <Form.Item
                      name="description"
                      getValueFromEvent={(event) => {
                        const data = event.editor.getData();
                        return data;
                      }}
                    >
                      {exercice && (
                        <div className={s.__custom__ck_editor}>
                          <CKEditor
                            initData={exercice?.description}
                            onChange={(changes: any) => {
                              const content = changes.editor.getData();
                              setDescription(content);
                            }}
                            style={{ border: "none" }}
                          />
                        </div>
                      )}
                    </Form.Item>
                  </div>

                  <div>
                    <div className={s.label}>
                      <label htmlFor="">Intitulé indice</label>
                    </div>
                    <div className={s.__custom__ck_editor}>
                      <Form.Item
                        name="clue"
                        getValueFromEvent={(event) => {
                          const data = event.editor.getData();

                          return data;
                        }}
                      >
                        <CKEditor
                          initData={clue}
                          onChange={(changes: any) => {
                            const content = changes.editor.getData();
                            setClue(content);
                          }}
                          style={{ border: "none" }}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <div className={s.label}>
                      <label htmlFor="">Intitulé solution</label>
                    </div>
                    <div className={s.__custom__ck_editor}>
                      <Form.Item
                        getValueFromEvent={(event) => {
                          const data = event.editor.getData();
                          return data;
                        }}
                      >
                        <CKEditor
                          initData={solution}
                          onChange={(changes: any) => {
                            const content = changes.editor.getData();
                            setSolution(content);
                          }}
                          style={{ border: "none" }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </Col>

                <Col span={12}>
                  <div className={s.content__exercicePreviewUpdate}>
                    <ExercicePreviewUpdate
                      exercice={exercice}
                      previewTitle={previewTitle}
                      previewType={previewType}
                      previewDescription={description}
                      previewIndice={clue}
                      previewSolution={solution}
                      data={preview}
                      className={s.timline__exo__edit}
                      classNameContainer={s.container__timeline}
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <Row justify="center" gutter={12} style={{ marginTop: "2em" }}>
              <Col>
                <AppButton
                  className={s.__submit__validation}
                  loading={isUpdatingBrouillonExercise}
                  htmlTypeSubmit={true}
                  onClick={() => update(CHOICE.SAVE_AS_DRAFT)}
                >
                  enregistrer
                </AppButton>
              </Col>
              <Col>
                <AppButton
                  className={s.__submit__validation}
                  loading={isUpdatingExercise}
                  htmlTypeSubmit={true}
                  onClick={() => update(CHOICE.SAVE_PERMANENTLY)}
                >
                  Soumettre pour validation
                </AppButton>
              </Col>
            </Row>
          </Form>
        ) : (
          <div className={s.main}>
            <div
              style={{
                height: "100%",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Spin size="large" />
            </div>
          </div>
        )}
      </AppScreen>
    </React.Fragment>
  );
};

export default SingleExercise;
