import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import arrow_history from "../../../../../assets/images/app/home/arrow_history.svg";
import arrow_up_history from "../../../../../assets/images/app/home/arrow_up_history.svg";
import director from "../../../../../assets/images/app/home/director.png";
import ellipse_history from "../../../../../assets/images/app/home/ellipse_history.svg";
import ellipse_history_title from "../../../../../assets/images/app/home/ellipse_history_title.svg";
import student_history from "../../../../../assets/images/app/home/student_history.png";
import s from "./style.module.css";

const OurHistory = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.ourHistoryBox}>
                <div className={s.mainBox}>
                    <FadeInUpOnScroll>
                        <h1 className={s.title}>Notre historique</h1>
                    </FadeInUpOnScroll>
                    <img src={ellipse_history_title} className={s.ellipseTitle}></img>
                    <FadeInOnScroll>
                        {isMobile ? <p className={s.subtitle}>Fondée en 2024 par Othmane Akherraz, Mon Ami<br />Albert a commencé comme un petit projet pour<br />aider les étudiants locaux à exceller<br />académiquement. Depuis, nous avons grandi pour devenir une<br />plateforme globale, touchant des milliers de vies<br />à travers des solutions d’apprentissage innovantes.</p> : <p className={s.subtitle}>Fondée en 2024 par Othmane Akherraz, Mon Ami Albert a commencé<br />comme un petit projet pour aider les étudiants locaux à exceller académiquement.<br />Depuis, nous avons grandi pour devenir une plateforme globale, touchant des milliers<br />de vies à travers des solutions d’apprentissage innovantes.</p>}
                    </FadeInOnScroll>
                    {isMobile ? <div className={s.secondaryBox}>
                        <div className={s.directorImageBox}>
                            <img src={director} className={s.directorImage}></img>
                        </div>
                        <div className={s.directorMessageBox}>
                            <a className={s.directorName}>Othmane Akherraz</a>
                            <a className={s.directorFunction}>Fondateur</a>
                            <p className={s.directorMessage}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in ligula est. Cras at egestas libero, sed elementum augue. Integer et nisi magna. Vivamus auctor dolor vitae urna laoreet, at vestibulum odio dictum. Donec euismod, quam quis malesuada mattis, nisi mauris lobortis lectus, at placerat diam<br />
                                <a className={s.quote}>‘’</a>
                            </p>
                        </div>
                        <img src={student_history} className={s.studentHistoryImage}></img>
                        <img src={arrow_up_history} style={{ height: "70px", position: "relative", right: "-84%", bottom: "335px" }}></img>
                    </div> : <div className={s.secondaryBox}>
                        <img src={student_history} className={s.studentHistoryImage}></img>
                        <img src={ellipse_history} style={{ height: "10px", position: "relative", left: "-8%", bottom: "-80px" }}></img>
                        <img src={arrow_history} style={{ height: "240px", position: "relative", left: "-4%", bottom: "25px" }}></img>
                        <div className={s.directorImageBox}>
                            <img src={director} className={s.directorImage}></img>
                        </div>
                        <div className={s.directorMessageBox}>
                            <a className={s.directorName}>Othmane Akherraz</a>
                            <a className={s.directorFunction}>Fondateur</a>
                            <p className={s.directorMessage}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in ligula est. Cras at egestas libero, sed elementum augue. Integer et nisi magna. Vivamus auctor dolor vitae urna laoreet, at vestibulum odio dictum. Donec euismod, quam quis malesuada mattis, nisi mauris lobortis lectus, at placerat diam<br />
                                <a className={s.quote}>‘’</a>
                            </p>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default OurHistory;