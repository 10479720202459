import { Select } from "antd";
import { FC, useEffect, useState } from "react";

//!RTK
import { useSelector } from "react-redux";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";

const { Option, OptGroup } = Select;

type Props = {
  onChange?: (a?: any, b?: any) => void;
  onClick?: () => void;
  onClear?: () => void;
  style?: React.CSSProperties;
  placeholder: string;
  className?: string;
  initValue?: string;
  levelsSelected?: string[];
};

const AppSelectGroupInscription: FC<Props> = ({
  onChange,
  onClick,
  onClear,
  style,
  placeholder,
  className,
  initValue,
  levelsSelected = [],
}) => {
  //!HOOKS
  const { data: levelsData, isLoading, isError } = useGetAllLevelsQuery("");
  const { user } = useSelector((state) => state.auth);

  const allLevels = levelsData?.data;
  const [filteredLevels, setFilteredLevels] = useState(allLevels);

  useEffect(() => {
    const removeDoublons = (level: { title: string; _id: string }[]) => {
      return level?.filter((l) => !levelsSelected?.includes(l._id));
    };

    let filteredPrimaire = removeDoublons(allLevels?.Primaire);
    let filteredCollège = removeDoublons(allLevels?.Collège);
    let filteredLycée = removeDoublons(allLevels?.Lycée);
    let filteredPrépa = removeDoublons(allLevels?.Prépa);

    setFilteredLevels({
      Primaire: filteredPrimaire,
      Collège: filteredCollège,
      Lycée: filteredLycée,
      Prépa: filteredPrépa,
    });
  }, [levelsSelected.length > 0 ? levelsSelected : undefined, allLevels]);

  return (
    <Select
      placeholder={placeholder}
      style={style}
      className={className}
      onChange={onChange}
      onClick={onClick}
      bordered={false}
      loading={isLoading}
      defaultValue={user?.levelId || initValue}
    >
      {filteredLevels?.Primaire?.length > 0 && (
        <OptGroup label={"Primaire"}>
          {filteredLevels?.Primaire?.map((college: any) => (
            <Option key={college?._id} value={college?._id}>
              {college?.title}
            </Option>
          ))}
        </OptGroup>
      )}

      {filteredLevels?.Collège?.length > 0 && (
        <OptGroup label={"Collège"}>
          {filteredLevels?.Collège?.map((college: any) => (
            <Option key={college?._id} value={college?._id}>
              {college?.title}
            </Option>
          ))}
        </OptGroup>
      )}

      {filteredLevels?.Lycée?.length && (
        <OptGroup label={"Lycée"}>
          {filteredLevels?.Lycée?.map((college: any) => (
            <Option key={college?._id} value={college?._id}>
              {college?.title}
            </Option>
          ))}
        </OptGroup>
      )}

      {filteredLevels?.Prépa?.length && (
        <OptGroup label={"Prépa"}>
          {filteredLevels?.Prépa?.map((college: any) => (
            <Option key={college?._id} value={college?._id}>
              {college?.title}
            </Option>
          ))}
        </OptGroup>
      )}
    </Select>
  );
};

export default AppSelectGroupInscription;
