import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Avatar, Image, Upload, message } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/auth/authSlice";
import pen from "./assets/pen.svg";
interface UploadAvatarInterface {
  path: string;
}
const getBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};

const beforeUpload = (file: any) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";

  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }

  const isLt2M = file.size / 1024 / 1024 < 2;

  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }

  return isJpgOrPng && isLt2M;
};

const UploadAvatar: React.FC<UploadAvatarInterface> = ({ path }) => {
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState(null);
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }

    if (info.file.status === "done") {
      if(info.file?.response?.data !== "Face not detected"){
        getBase64(info.file.originFileObj, (url: any) => {
          setLoading(false);
          setImageUrl(url);
        });
        updateIcon(info.file?.response?.data);
      } else {
        message.warning("Visage non détécté, merci de réeessayer !")
      }
      
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Télécharger
      </div>
    </div>
  );
  const updateIcon = async (input: string) => {
    try {
      let response: any = await axios.patch(path, {
        profile: input,
      });
      if (response?.data?.statusCode === 200) {
        dispatch(
          updateUser({ ...user, profile: response?.data?.data?.profile })
        );
        message.success("Photo de profile a été bien modifiée");
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    setImageUrl(
      user?.profile
        ? user.profile
        : `https://gravatar.com/avatar/${user?._id}?d=identicon`
    );
  }, [isLoggedIn]);
  return (
    <Upload
      name="file"
      method="POST"
      listType="picture-card"
      className="avatar-uploader"
      showUploadList={false}
      action="https://api-ressources.monamialbert.com/api/v1/users/profil/update/upload?type=photoProfil"
      beforeUpload={beforeUpload}
      onChange={handleChange}
    >
      {imageUrl ? (
        <Avatar src={imageUrl} alt="Avatar" size={70} />
      ) : (
        uploadButton
      )}
      <div
        style={{
          position: "absolute",
          right: "0",
          bottom: "0",
          margin: 0,
          width: "fit-content",
          transform: "translate(15px, -20px);",
          zIndex: "2000",
        }}
      >
        <Image src={pen} alt="" width={20} preview={false} />
      </div>
    </Upload>
  );
};

export default UploadAvatar;
