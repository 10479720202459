import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import * as appRoutes from "config/routes.config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_need_help from "../../../../assets/images/app/home/arrow_need_help.svg";
import ellipse_need_help from "../../../../assets/images/app/home/ellipse_need_help.svg";
import insta_prof from "../../../../assets/images/app/home/insta_prof.png";
import triangle_need_help from "../../../../assets/images/app/home/triangle_need_help.svg";
import ellipse_insta_prof from "../../../../assets/images/app/teachers/ellipse_insta_prof.svg";
import ellipse_instaprof from "../../../../assets/images/app/teachers/ellipse_instaprof.svg";
import trace_instaprof from "../../../../assets/images/app/teachers/trace_instaprof.svg";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const InstaProf = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(appRoutes.LOGINS);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.needHelpBox}>
                {!isMobile && <img src={ellipse_instaprof} style={{ position: "relative", right: "0%", top: "120px", width: "15px" }}></img>}
                {!isMobile && <img src={trace_instaprof} style={{ position: "relative", right: "-19%", top: "30px", height: "155px", zIndex: "1" }}></img>}
                <div className={s.mainBox}>
                    <div className={s.leftBox}>
                        <FadeInUpOnScroll>
                            <h1 className={s.title}>InstaProf</h1>
                        </FadeInUpOnScroll>
                        <img src={ellipse_need_help} className={s.ellipseTitle}></img>
                        {isMobile ? <FadeInOnScroll>
                            <p className={s.messageOne}>Assistance instantanée<br />pour des progrès immédiats</p>
                            <p className={s.message}>Lorem ipsum dolor sit amet, consectetur elit.<br />ligula est. Cras at egestas libero, sed augue.<br />Integer et nisi magna.</p>
                        </FadeInOnScroll> : <FadeInOnScroll>
                            <p className={s.message}>Assistance instantanée<br />pour des progrès immédiats</p>
                            <p className={s.message}>Lorem ipsum dolor sit amet, consectetur adipiscing elit.<br />Nam in ligula est. Cras at egestas libero, sed elementum augue.<br />Integer et nisi magna. Vivamus auctor dolor vitae urna laoreet,<br />at vestibulum odio dictum.</p>
                        </FadeInOnScroll>}
                        <img src={arrow_need_help} className={s.arrowNeedHelp}></img>
                        <button className={st.button} style={{ marginTop: "0px", position: "relative", top: "-45px" }}>
                            <div className={s.buttonStyle} onClick={handleButtonClick}>
                                Créer votre compte
                            </div>
                        </button>
                    </div>
                    {isMobile ? <div className={s.rightBox}>
                        <img src={insta_prof} className={s.instaProfImage}></img>
                        <img src={ellipse_insta_prof} style={{ position: "relative", right: "-27%", top: "-235px", width: "50px", zIndex: "0" }}></img>
                        <img src={triangle_need_help} style={{ position: "relative", right: "-47%", top: "-180px", width: "15px", zIndex: "2" }}></img>
                    </div> : <div className={s.rightBox}>
                        <img src={ellipse_insta_prof} style={{ position: "relative", right: "-97%", top: "-35%", width: "90px" }}></img>
                        <img src={triangle_need_help} style={{ position: "relative", right: "-110%", top: "-40%", width: "25px" }}></img>
                        <img src={insta_prof} className={s.instaProfImage}></img>
                    </div>}
                </div>
                <div className={s.callAction}>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "space-between", textAlign: "left" }}>
                        <div className={s.instaTitle}>InstaProf</div>
                        <div className={s.instaMessage}>Cours instantané</div>
                    </div>
                    <button className={st.buttonRegister} style={{ marginTop: "0px" }}>
                        <div style={{ fontFamily: "Poppins", fontWeight: "bolder", cursor: "pointer" }}>
                            Commencez le cours
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InstaProf;