import {
  DeleteOutlined,
  EditOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { message, Popconfirm } from "antd";
import Matiers from "components/matiers";
import { badRequest } from "messages";
import moment from "moment";
import { FC, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  displayDrawerAdsSpecific,
  setCurrentCreneau,
} from "store/adventissements";
import { useDeleteAdSpecificMutation } from "store/services/teacher/ads.services";
import s from "./style.module.css";

type Props = {
  item: any;
};

const CardAvailabilitySpecific: FC<Props> = ({ item }) => {
  const dispatch = useDispatch();
  const [deleteAdSpecific, { isSuccess, isError, isLoading }] =
    useDeleteAdSpecificMutation();

  useEffect(() => {
    if (isSuccess) {
      message.success("Créneau supprimé avec succès");
    }
    if (isError) {
      message.error(badRequest.error);
    }
  }, [isSuccess, isLoading, isError]);

  return (
    <div className={s.card}>
      {item?.level?.map((l: any, index: number) => (
        <div key={index} className={s.box}>
          <div className={s.action}>
            <div className={`${s.multi_button} `}>
              <Popconfirm
                title="Veux-tu supprimer le créneau ?"
                onConfirm={() => deleteAdSpecific(item?._id)}
                okText="Oui"
                cancelText="Non"
              >
                <button className="fas fa-clipboard">
                  <DeleteOutlined />
                </button>
              </Popconfirm>
            </div>
            <div>
              <span>{moment(item?.date).format("Do MMMM")}</span>
            </div>

            <div className={s.multi_button}>
              <button
                onClick={() => {
                  dispatch(displayDrawerAdsSpecific(true));
                  dispatch(setCurrentCreneau(item));
                }}
              >
                <EditOutlined />
              </button>
            </div>
          </div>

          <div>
            {item?.timeRanges[index].range[0].hour} {">"}{" "}
            {
              item?.timeRanges[index].range[
                item?.timeRanges[index].range.length - 1
              ].hour
            }
          </div>

          <div>
            {item?.timeRanges[0]?.type === "collectif" ? (
              <UsergroupAddOutlined />
            ) : (
              <UserOutlined />
            )}
          </div>

          <Matiers
            className={s.matiers}
            cours={item?.topic[index]?.title}
            level={l?.title}
          />
        </div>
      ))}

      <div className={s.container} />
    </div>
  );
};

export default CardAvailabilitySpecific;
