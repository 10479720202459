import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, Input, message, Row, Select, Steps } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { updateTeacher } from "api/services/auth/auth.services";
import { FC, Fragment, useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "store/auth/authSlice";
import * as TOPICS_SERVICES from "../../api/services/topics/topics.services";

//!COMPONENTS

//!IMAGES

//!STYLES
import { updateNivMat } from "api/services/advertissements/advertissements.services";
import { CKEditor } from "ckeditor4-react";
import {
  educSystem,
  HandlerInputError,
  Language,
  StatusCode,
} from "common/enums";
import AppButton from "components/libs/button";
import AppSelectGroup from "components/libs/selectGroup";
import H2 from "components/libs/subtitle";
import s from "./assets/stepTwo.module.css";
import AppSelectGroupInscription from "components/libs/selectGroupInscription";

const { Step } = Steps;
const { Option } = Select;

type Props = {
  // setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  // firstStepData: any;
  // setCurrent: React.Dispatch<React.SetStateAction<number>>;
  formRef?: any;
};

const UpdateProfileStepTwo: FC<Props> = ({ formRef }) => {
  //!HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const levels = useSelector((state) => state.levels);

  //!STATES
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [tempTopic, setTempTopic] = useState<any>([]);
  const refAddCreneau = useRef<any>();
  const refAddMatiere = useRef<any>();

  //!FUNCTIONS
  const UpdateUser = async (values: any) => {
    setisLoading(true);

    const cleanNivMat = values.nivMat.map((niv: any) => {
      return {
        niveau: typeof niv.niveau === "object" ? niv.niveau?._id : niv.niveau,
        matieres: niv?.matieres.map((mat: any) => {
          return {
            cost: Number(mat?.cost),
            costCollectif: Number(mat?.costCollectif),
            language: [...values.langues],
            matiere: mat?.matiere?._id || mat?.matiere?.title,
          };
        }),
      };
    });

    const finalData = {
      uid: user?._id,
      arrayNivMat: cleanNivMat,
    };

    const patchUpdate = {
      educSystem: [...values.educSystem],
      langues: [...values.langues],
      career: values.career,
    };
    try {
      const res = await updateNivMat(finalData);
      const response = await updateTeacher(user?._id, patchUpdate);
      if (res?.statusCode === StatusCode.CREATED) {
        dispatch(
          updateUser({
            ...user,
            nivMat: cleanNivMat,
          })
        );
      }
      if (response?.statusCode === StatusCode.OK) {
        const finalUser = await AUTH_SERVICES.getUserByUid(user.uid);
        if (finalUser?.data) {
          dispatch(updateUser(finalUser.data));
        }
        message.success("Modifié avec succès");
      }
    } catch (error) {
    } finally {
      setisLoading(false);
    }
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      const response = await TOPICS_SERVICES.findAll(levelId);

      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
      }
    },
    [tempTopic]
  );

  return (
    <Form onFinish={UpdateUser} initialValues={{ nivMat: user?.nivMat }} ref={formRef}>
      <div className={s.full__content}>
        <H2 className={s.title}>J'enseigne :</H2>

        <Form.List name="nivMat">
          {(fields, { add, remove }) => (
            <div>
              {fields.map(({ key, name, ...restField }) => (
                <Fragment>
                  <div className={s.__content}>
                    <div className={s.level}>
                      <div className={s.field}>
                        <Form.Item
                          style={{ margin: 0, padding: 0 }}
                          name={[name, "niveau"]}
                        >
                          <AppSelectGroupInscription
                            initValue={user?.nivMat[key]?.niveau?._id}
                            placeholder="Choisir un niveau"
                            onChange={(value: string) => OnSelectLevel(value)}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <Form.Item
                      style={{
                        margin: 0,
                      }}
                      name={[name, "matieres"]}
                    >
                      <Form.List name={[name, "matieres"]}>
                        {(fields, { add, remove }) => (
                          <Fragment>
                            {fields.map(({ key, name, ...restField }) => (
                              <div
                                style={{ position: "relative" }}
                                className={s.forms}
                                key={key}
                              >
                                <div className={s.field}>
                                  <Form.Item
                                    style={{ margin: 0, padding: 0 }}
                                    {...restField}
                                    name={[name, "matiere", "title"]}
                                  >
                                    <Select
                                      className={s.select}
                                      placeholder="Choisir une matiere"
                                      style={{ width: "100%" }}
                                      bordered={false}
                                      allowClear
                                    >
                                      {tempTopic?.map((topic: any) => (
                                        <Option
                                          key={topic._id}
                                          value={topic._id}
                                        >
                                          {topic.title}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </div>

                                {/* <div className={s.field}>
                                    <Form.Item
                                      style={{ margin: 0, padding: 0 }}
                                      {...restField}
                                      name={[name, "language"]}
                                    >
                                      <Select
                                        className={s.select}
                                        placeholder="Langues"
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        bordered={false}
                                        allowClear
                                      >
                                        <Option value={Language.ENGLISH}>
                                          Anglais
                                        </Option>
                                        <Option value={Language.FRENCH}>
                                          Français
                                        </Option>
                                      </Select>
                                    </Form.Item>
                                  </div> */}

                                <div className={s.field}>
                                  <Form.Item
                                    style={{ margin: 0, padding: 0 }}
                                    {...restField}
                                    name={[name, "cost"]}
                                    rules={[
                                      {
                                        pattern: /^(?:\d*)$/,
                                        message:
                                          "La valeur doit contenir seulement les chiffre.",
                                      },
                                      {
                                        pattern: /^[\d]{0,3}$/,
                                        message: "Cost max 500 euros",
                                      },
                                      // { max: 500, message: "Oza fou ?" },
                                    ]}
                                    validateTrigger="onBlur"
                                  >
                                    <Input
                                      bordered={false}
                                      type="number"
                                      style={{ width: "100%" }}
                                      // addonAfter="€"
                                      suffix="€/h"
                                    />
                                  </Form.Item>
                                </div>
                                <div className={s.field}>
                                  <Form.Item
                                    style={{ margin: 0, padding: 0 }}
                                    {...restField}
                                    name={[name, "costCollectif"]}
                                    rules={[
                                      {
                                        pattern: /^(?:\d*)$/,
                                        message:
                                          "La valeur doit contenir seulement les chiffre.",
                                      },
                                      {
                                        pattern: /^[\d]{0,3}$/,
                                        message: "Cost max 500 euros",
                                      },
                                      // { max: 500, message: "Oza fou ?" },
                                    ]}
                                    validateTrigger="onBlur"
                                  >
                                    <Input
                                      bordered={false}
                                      type="number"
                                      placeholder="Cost créneau collectif"
                                      style={{ width: "100%" }}
                                      // addonAfter="€"
                                      suffix="€/h"
                                    />
                                  </Form.Item>
                                </div>

                                <MinusCircleOutlined
                                  style={{
                                    position: "absolute",
                                    right: "-25px",
                                    top: "15px",
                                  }}
                                  onClick={() => remove(name)}
                                />
                              </div>
                            ))}

                            <Form.Item style={{ margin: 0, padding: 0 }}>
                              <Button
                                ref={refAddMatiere}
                                type="link"
                                className={s.button}
                                icon={<PlusOutlined />}
                                onClick={() => {
                                  add();
                                }}
                              >
                                Ajouter
                              </Button>
                            </Form.Item>
                          </Fragment>
                        )}
                      </Form.List>
                    </Form.Item>
                    <DeleteOutlined
                      style={{
                        position: "relative",
                        color: "red",
                        right: "-20px",
                        top: "15px",
                      }}
                      onClick={() => remove(name)}
                    />
                  </div>
                </Fragment>
              ))}

              <Form.Item style={{ margin: 0, padding: 0 }}>
                <Button
                  ref={refAddCreneau}
                  className={s.__new__creneaux}
                  type="link"
                  onClick={() => {
                    add();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Ajouter un autre niveau
                </Button>
              </Form.Item>
            </div>
          )}
        </Form.List>
      </div>

      <div className={s.full__content}>
        <div className={s.__content} style={{ gap: "2em" }}>
          <div className={s.levelBox}>
            <div className={s.inputBox}>
              <div>
                <H2>Système d'éducation :</H2>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <div className={s.field}>
                  <Form.Item
                    style={{ margin: 0, padding: 0 }}
                    name={"educSystem"}
                    initialValue={user?.educSystem}
                  >
                    <Select
                      className={s.select}
                      placeholder="Système d'éducation"
                      mode="multiple"
                      style={{ width: "100%" }}
                      bordered={false}
                      allowClear
                    >
                      <Option value={educSystem.MOROCCO}>
                        Système Marocain
                      </Option>
                      <Option value={educSystem.FRENCH}>
                        Système Français
                      </Option>
                      <Option value={educSystem.BELGE}>Système Belge</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className={s.languageBox}>
            <div className={s.inputBox}>
              <div>
                <H2>Je parle :</H2>
              </div>
              <div style={{ marginLeft: "10px" }}>
                <div className={s.field}>
                  <Form.Item
                    style={{ margin: 0, padding: 0 }}
                    name={"langues"}
                    initialValue={user?.langues}
                  >
                    <Select
                      className={s.select}
                      placeholder="Langues"
                      mode="multiple"
                      style={{ width: "100%" }}
                      bordered={false}
                      allowClear
                    >
                      <Option value={Language.ENGLISH}>Anglais</Option>
                      <Option value={Language.FRENCH}>Français</Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={s.full__content}>
        <H2 className={s.title}>À propos de moi et mon cours : </H2>

        <div className={s.text__area}>
          {/* <Form.Item style={{ margin: 0, padding: 0 }} name="career">
            <Input.TextArea bordered={false} rows={12} />
          </Form.Item> */}
          <Form.Item
            initialValue={user?.career}
            name={"career"}
            valuePropName="data"
            getValueFromEvent={(event) => {
              const data = event.editor.getData();
              return data;
            }}
            rules={[
              {
                required: true,
                message: HandlerInputError.content,
              },
            ]}
          >
            <CKEditor
              initData={user?.career}
              style={{
                border: "none",
              }}
            />
          </Form.Item>
        </div>
      </div>

      <div style={{ display: "flex", marginBottom: "4.5em", alignItems: "center", justifyContent: "center" }}>
        <Form.Item style={{ margin: 0, padding: 0 }}>
          <AppButton loading={isLoading} htmlTypeSubmit={true}>
            Mettre à jour
          </AppButton>
        </Form.Item>
      </div>

    </Form>
  );
};

export default UpdateProfileStepTwo;
