import {
  AppstoreOutlined,
  ArrowLeftOutlined,
  HomeOutlined,
  RightOutlined
} from "@ant-design/icons";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";

//!STYLES
import { Breadcrumb, Col, Row, Segmented, Tooltip } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import s from "./assets/breadcrumbs.module.css";

//!IMAGES
import Teacher from "./assets/images/TeacherSvg.svg";
import Ads from "./assets/images/ads.svg";
import Calendrier from "./assets/images/calendar.svg";
import Classes from "./assets/images/classes.svg";
import Content from "./assets/images/contenu.svg";
import Dashboard from "./assets/images/dashboard.svg";
import SearchProf from "./assets/images/prof.svg";
import Wallet from "./assets/images/wallet.svg";

import { UserRoles } from "common/enums";
import { useSelector } from "react-redux";
import Cours from "../../assets/images/teacher/cours.svg";
import Exo from "../../assets/images/teacher/exo.svg";
import Quiz from "../../assets/images/teacher/quiz.svg";
import { ReactComponent as ListIcon } from "./assets/images/list.svg";

type Props = {
  isMycontent?: boolean;
  isDashboard?: boolean;
  isCreateCourse?: boolean;
  isCreateQuiz?: boolean;
  isCreateExo?: boolean;
  isAds?: boolean;
  isRooms?: boolean;
  isBack?: boolean;
  pathBack?: string;
  title?: string;
  isSearchProf?: boolean;
  isProfil?: boolean;
  isCalendar?: boolean;
  isStudents?: boolean;
  isTeachers?: boolean;
  isWallet?: boolean;
};

const Breadcrumbs: FC<Props> = ({
  title,
  isMycontent,
  isDashboard,
  isCreateCourse,
  isCreateQuiz,
  isCreateExo,
  isAds,
  isBack,
  pathBack,
  isRooms,
  isSearchProf,
  isProfil,
  isCalendar,
  isStudents,
  isTeachers,
  isWallet,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, level } = useParams<any>();
  const { user } = useSelector((state) => state.auth);
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);
  const isStudent = user?.roles?.includes(UserRoles.STUDENT);
  const isParent = user?.roles?.includes(UserRoles.PARENT);
  const [router, setRouter] = useState<any>(null);

  useEffect(() => {
    setRouter(window.location.pathname);
  }, [router]);

  return (
    <div className={!isTeacher ? s.breadcrumbs : isTeacher && user?.step === "completed" && s.breadcrumbs}>
      {isTeacher ? <Container>
        {user?.step === "completed" && <Row justify="space-between">
          <Col>
            <Breadcrumb
              style={{ lineHeight: "40px" }}
              separator={
                <span className={s.arrow}>
                  <RightOutlined color="#64B7AF" />
                </span>
              }
            >
              <Breadcrumb.Item
                onClick={() => {
                  if (user?.roles.includes(UserRoles.TEACHER)) {
                    user?.step === "completed" ? navigate(appRoutes.TEACHER_DASHBOARD) : navigate(appRoutes.COMPLETE_PROFILE);
                  } else if (user?.roles.includes(UserRoles.STUDENT)) {
                    navigate(appRoutes.STUDENT_DASHBOARD);
                  } else {
                    navigate(appRoutes.HOME);
                  }
                }}
                className={s.__item}
              >
                <HomeOutlined />
              </Breadcrumb.Item>

              {isStudents && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Classes} alt="" width={25} />{" "}
                  <span>Mes élèves</span>
                </Breadcrumb.Item>
              )}

              {isMycontent && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Content} alt="" width={25} />{" "}
                  <span>Mon contenu</span>
                </Breadcrumb.Item>
              )}

              {isDashboard && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Dashboard} alt="" width={25} />{" "}
                  <span>Mon Dashboard</span>
                </Breadcrumb.Item>
              )}

              {isCreateCourse && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Cours} alt="" width={25} />{" "}
                  <span>Créer un cours</span>
                </Breadcrumb.Item>
              )}

              {isCreateQuiz && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Quiz} alt="" width={25} />{" "}
                  <span>Créer un quiz</span>
                </Breadcrumb.Item>
              )}

              {isCreateExo && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Exo} alt="" width={15} />{" "}
                  <span>Créer un exercice</span>
                </Breadcrumb.Item>
              )}

              {isAds && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Ads} alt="" width={25} /> <span>Accueil</span>
                </Breadcrumb.Item>
              )}

              {isRooms && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Classes} alt="" width={25} />{" "}
                  <span>Mes classes</span>
                </Breadcrumb.Item>
              )}

              {isSearchProf && (
                <Breadcrumb.Item className={s.__item}>
                  <img
                    src={SearchProf}
                    alt=""
                    width={18}
                    style={{ position: "relative", top: "-2px" }}
                  />{" "}
                  <span>Trouver un prof</span>
                </Breadcrumb.Item>
              )}

              {isProfil && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Teacher} alt="" width={25} />{" "}
                  <span>Mon Profil</span>
                </Breadcrumb.Item>
              )}

              {isCalendar && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Calendrier} alt="" width={25} />{" "}
                  <span>Calendrier</span>
                </Breadcrumb.Item>
              )}

              {/* <Breadcrumb.Item
                className={s.__item}
                onClick={() => navigate(-1)}
              >
                <span>Page précédente</span>
              </Breadcrumb.Item> */}
            </Breadcrumb>
          </Col>

          <Col>
            <Row gutter={12}>
              {isBack && (
                <Col>
                  <Breadcrumb
                    style={{ lineHeight: "40px" }}
                    separator={
                      <span className={s.arrow}>
                        <RightOutlined color="#64B7AF" />
                      </span>
                    }
                  >
                    {!isProfil && <Breadcrumb.Item
                      className={s.__item}
                      onClick={() => {
                        pathBack ? navigate(pathBack) : navigate(-1);
                      }}
                    >
                      <span>
                        {" "}
                        <ArrowLeftOutlined
                          style={{ position: "relative", top: "1px" }}
                        />{" "}
                        {pathBack ? "Retour aux chapitres" : "Page précédente"}
                      </span>
                    </Breadcrumb.Item>}
                  </Breadcrumb>
                </Col>
              )}

              {isMycontent && (
                isTeacher && <Col>
                  {user?.isActive && user?.step === "completed" ? (
                    <Segmented
                      style={{
                        background: "#80B1B2",
                        borderRadius: "20px",
                        padding: "0.4em",
                      }}
                      defaultValue={
                        location.pathname === appRoutes.TEACHER_COURSES
                          ? "Contributeur"
                          : location.pathname === appRoutes.TEACHER_QUIZZ
                            ? "Contributeur"
                            : location.pathname === appRoutes.TEACHER_EXERCICES
                              ? "Contributeur"
                              : "Explorateur"
                      }
                      onChange={(event) => {
                        if (event === "Contributeur") {
                          navigate(appRoutes.TEACHER_COURSES);
                        }
                        if (event === "Explorateur") {
                          navigate(appRoutes.COURSESBYLEVELS);
                        }
                      }}
                      options={[
                        {
                          label: "",
                          value: "Explorateur",
                          icon: <Tooltip title={"Mode Explorateur"} placement="bottom"><AppstoreOutlined /></Tooltip>,
                        },
                        {
                          label: (
                            <Tooltip title={"Mode Contributeur"} placement="bottom">
                              {" "}
                              <ListIcon
                                style={{
                                  position: "relative",
                                  width: "15px",
                                  height: "15px",
                                  top: "2.5px",

                                  display: "inline",
                                }}
                              />{" "}
                            </Tooltip>
                          ),
                          value: "Contributeur",
                        }
                      ]}
                    />
                  ) : <Segmented
                    style={{
                      background: "#80B1B2",
                      borderRadius: "20px",
                      padding: "0.4em",
                    }}
                    defaultValue={"Explorateur"}
                    options={[
                      {
                        label: "",
                        value: "Explorateur",
                        icon: <Tooltip title={"Mode Explorateur"} placement="bottom"><AppstoreOutlined /></Tooltip>,
                      },
                    ]}
                  />}
                </Col>
              )}
            </Row>
          </Col>
        </Row>}
      </Container> : isStudent ? <Container>
        <Row justify="space-between">
          <Col>
            <Breadcrumb
              style={{ lineHeight: "40px" }}
              separator={
                <span className={s.arrow}>
                  <RightOutlined color="#64B7AF" />
                </span>
              }
            >
              <Breadcrumb.Item
                onClick={() => {
                  if (user?.roles.includes(UserRoles.TEACHER)) {
                    user?.step === "completed" ? navigate(appRoutes.TEACHER_DASHBOARD) : navigate(appRoutes.COMPLETE_PROFILE);
                  } else if (user?.roles.includes(UserRoles.STUDENT)) {
                    navigate(appRoutes.STUDENT_DASHBOARD);
                  } else {
                    navigate(appRoutes.HOME);
                  }
                }}
                className={s.__item}
              >
                <HomeOutlined />
              </Breadcrumb.Item>

              {isMycontent && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Content} alt="" width={25} />{" "}
                  <span>Mon contenu</span>
                </Breadcrumb.Item>
              )}

              {isDashboard && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Dashboard} alt="" width={25} />{" "}
                  <span>Mon Dashboard</span>
                </Breadcrumb.Item>
              )}

              {isCreateCourse && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Cours} alt="" width={25} />{" "}
                  <span>Créer un cours</span>
                </Breadcrumb.Item>
              )}

              {isCreateQuiz && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Quiz} alt="" width={25} />{" "}
                  <span>Créer un quiz</span>
                </Breadcrumb.Item>
              )}

              {isCreateExo && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Exo} alt="" width={15} />{" "}
                  <span>Créer un exercice</span>
                </Breadcrumb.Item>
              )}

              {isAds && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Ads} alt="" width={25} /> <span>Accueil</span>
                </Breadcrumb.Item>
              )}

              {isRooms && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Classes} alt="" width={25} />{" "}
                  <span>Mes classes</span>
                </Breadcrumb.Item>
              )}

              {isSearchProf && (
                <Breadcrumb.Item className={s.__item}>
                  <img
                    src={SearchProf}
                    alt=""
                    width={18}
                    style={{ position: "relative", top: "-2px" }}
                  />{" "}
                  <span>Trouver un prof</span>
                </Breadcrumb.Item>
              )}

              {isProfil && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Classes} alt="" width={25} />{" "}
                  <span>Mon Profil</span>
                </Breadcrumb.Item>
              )}

              {isCalendar && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Calendrier} alt="" width={25} />{" "}
                  <span>Calendrier</span>
                </Breadcrumb.Item>
              )}

              {/* <Breadcrumb.Item
                className={s.__item}
                onClick={() => navigate(-1)}
              >
                <span>Page précédente</span>
              </Breadcrumb.Item> */}
            </Breadcrumb>
          </Col>

          <Col>
            <Row gutter={12}>
              {isBack && (
                <Col>
                  <Breadcrumb
                    style={{ lineHeight: "40px" }}
                    separator={
                      <span className={s.arrow}>
                        <RightOutlined color="#64B7AF" />
                      </span>
                    }
                  >
                    {!isProfil && <Breadcrumb.Item
                      className={s.__item}
                      onClick={() => {
                        pathBack ? navigate(pathBack) : navigate(-1);
                      }}
                    >
                      <span>
                        {" "}
                        <ArrowLeftOutlined
                          style={{ position: "relative", top: "1px" }}
                        />{" "}
                        {pathBack ? "Retour aux chapitres" : "Page précédente"}
                      </span>
                    </Breadcrumb.Item>}
                  </Breadcrumb>
                </Col>
              )}

              {isMycontent && (
                isTeacher && <Col>
                  {user?.isActive && user?.step === "completed" ? (
                    <Segmented
                      style={{
                        background: "#80B1B2",
                        borderRadius: "20px",
                        padding: "0.4em",
                      }}
                      defaultValue={
                        location.pathname === appRoutes.TEACHER_COURSES
                          ? "Contributeur"
                          : location.pathname === appRoutes.TEACHER_QUIZZ
                            ? "Contributeur"
                            : location.pathname === appRoutes.TEACHER_EXERCICES
                              ? "Contributeur"
                              : "Explorateur"
                      }
                      onChange={(event) => {
                        if (event === "Contributeur") {
                          navigate(appRoutes.TEACHER_COURSES);
                        }
                        if (event === "Explorateur") {
                          navigate(appRoutes.COURSESBYLEVELS);
                        }
                      }}
                      options={[
                        {
                          label: (
                            <span>
                              {" "}
                              <ListIcon
                                style={{
                                  position: "relative",
                                  width: "15px",
                                  height: "15px",
                                  top: "2.5px",

                                  display: "inline",
                                }}
                              />{" "}
                              Mode Contributeur
                            </span>
                          ),
                          value: "Contributeur",
                        },
                        {
                          label: <span>Mode Explorateur</span>,
                          value: "Explorateur",
                          icon: <AppstoreOutlined />,
                        },
                      ]}
                    />
                  ) : <Segmented
                    style={{
                      background: "#80B1B2",
                      borderRadius: "20px",
                      padding: "0.4em",
                    }}
                    defaultValue={"Explorateur"}
                    options={[
                      {
                        label: <span>Mode Explorateur</span>,
                        value: "Explorateur",
                        icon: <AppstoreOutlined />,
                      },
                    ]}
                  />}
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container> : <Container>
        <Row justify="space-between">
          <Col>
            <Breadcrumb
              style={{ lineHeight: "40px" }}
              separator={
                <span className={s.arrow}>
                  <RightOutlined color="#64B7AF" />
                </span>
              }
            >
              <Breadcrumb.Item
                onClick={() => {
                  navigate(isParent ? appRoutes.PARENT_DASHBOARD : appRoutes.ORGANISME_DASHBOARD);
                }}
                className={s.__item}
              >
                <HomeOutlined />
              </Breadcrumb.Item>

              {isStudents && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Classes} alt="" width={25} />{" "}
                  <span>Mes élèves</span>
                </Breadcrumb.Item>
              )}

              {isTeachers && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Teacher} alt="" width={25} />{" "}
                  <span>Annuaire des profs</span>
                </Breadcrumb.Item>
              )}

              {isWallet && (
                <Breadcrumb.Item className={s.__item}>
                  <img src={Wallet} alt="" width={25} />{" "}
                  <span>Portefeuille</span>
                </Breadcrumb.Item>
              )}

            </Breadcrumb>
          </Col>
          <Col>
            <Row gutter={12}>
              {isBack && (
                <Col>
                  <Breadcrumb
                    style={{ lineHeight: "40px" }}
                    separator={
                      <span className={s.arrow}>
                        <RightOutlined color="#64B7AF" />
                      </span>
                    }
                  >
                    {!isProfil && <Breadcrumb.Item
                      className={s.__item}
                      onClick={() => {
                        pathBack ? navigate(pathBack) : navigate(-1);
                      }}
                    >
                      <span>
                        {" "}
                        <ArrowLeftOutlined
                          style={{ position: "relative", top: "1px" }}
                        />{" "}
                        {pathBack ? "Retour aux chapitres" : "Page précédente"}
                      </span>
                    </Breadcrumb.Item>}
                  </Breadcrumb>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
      }
    </div>
  );
};

export default Breadcrumbs;
