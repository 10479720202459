import { Alert, Form, Image, Input, Row, Select, Steps } from "antd";
import * as appRoutes from "config/routes.config";
import { Dispatch, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";

//!COMPONENTS
import Container from "components/container";

//!IMAGES
import parent from "../../../assets/images/app/home/parent.svg";

//!STYLES
import { StatusCode } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import React from "react";
import FormStep from "screens/teacher/profileComplete/component";
import { setIsRegistering } from "store/app/appSlice";
import { registerParentOrganisationWithEmailPassword } from "store/auth/authSlice";
import { useCheckEmailMutation } from "store/services/app/user.services";
import AnimatedPage from "utils/animatedPage";
import s from "./assets/register.module.css";
import StepTwo from "./stepTwo";

const { Step } = Steps;
const { Option } = Select;

const RegisterParentOrganisme: FC = () => {
    //!HOOKS
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const [formInfos] = Form.useForm();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [current, setCurrent] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [checkEmail, { isSuccess, isError, data }] = useCheckEmailMutation();
    const [accountType, setAccountType] = useState<string>("Parent d'élève");
    const [countriesList, setCountries] = useState<any>([]);
    const [dataList] = useState<any>([]);

    //!STATES JOFREY
    const [registrationData, setRegistrationData] = useState<any>({});

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    const registerUser = async (values: any) => {
        setRegistrationData(values);
        checkEmail({ email: values.email });
        try {
            setIsLoading(true);
            const finalData = {
                ...registrationData,
                ...values,
                roles: ["parent"],
                step: "",
                organisationName: values["lastname"] + " " + values["firstname"],
                listInvite: [],
            };

            await dispatch(registerParentOrganisationWithEmailPassword(finalData));
            await dispatch(setIsRegistering(true));
            setCurrent(2);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    const getStaticLists = useCallback(
        async (type: string, dataList: any, state: Dispatch<any>) => {
            const response = await TEACHER_SERVICES.getDataList(type);
            if (response?.statusCode === StatusCode.OK) {
                state(response?.data);
            }
        },
        [dataList]
    );

    //!EFFECTS
    useEffect(() => {
        getStaticLists('pays', countriesList, setCountries);
    }, []);

    return (
        <AnimatedPage>
            <Breadcrumbs />
            <div className={s.register__teacher}>
                <Container>

                    <div className={s.content__action}>
                        <div className={s.teacher}>
                            <div className={s.avatar}>
                                <Image
                                    preview={false}
                                    width={60}
                                    src={parent}
                                    alt="parent avatar"
                                />
                            </div>

                            <div className={s.header__text}>
                                <H1>Créer votre compte d'administration</H1>
                                <p>
                                    Je suis <span style={{ color: "#000" }}>parent d'évève(s)</span>.{" "}
                                    {current === 1 && <Link to={appRoutes.LOGINS}>Modifier</Link>}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className={s.step}>
                        <Steps current={current - 1} labelPlacement={"vertical"} responsive={true}>
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div className={s.title__step}>
                                        <span>Informations personnelles</span>
                                    </div>
                                }
                                onClick={() => setCurrent(current)}
                            />
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div className={s.title__step}>
                                        <span>Invitez vos élèves</span>
                                    </div>
                                }
                                onClick={() => setCurrent(current)}
                            />
                        </Steps>
                    </div>
                    <div className={s.step_container}>
                        <FormStep index={1} title="Informations personnelles" current={current} onClick={() => setCurrent(current)} />
                        <FormStep index={2} title="Invitez vos élèves" current={current} onClick={() => setCurrent(current)} />
                    </div>
                    <div className={s.formBox}>
                        <div className={`${current === 1 ? s.form__container : ""}`}>
                            <Form
                                validateTrigger={["onFinish"]}
                                validateMessages={validateMessages}
                                name="registerParent"
                                autoComplete="off"
                                form={formInfos}
                                onFinish={registerUser}
                            >
                                {current === 1 && (
                                    <React.Fragment>

                                        <div className={s.field} style={{ marginBottom: "20px" }}>
                                            <Form.Item
                                                name="account"
                                                rules={[{ required: false }]}
                                            >
                                                <Select
                                                    placeholder="Parent d'élève"
                                                    style={{ width: "100%", color: "#000", paddingTop: "5px", fontSize: "1.2em" }}
                                                    bordered={false}
                                                    allowClear
                                                >
                                                    <Option
                                                        key={1}
                                                        value={"Parent d'élève"}
                                                    >
                                                        {"Parent d'élève"}
                                                    </Option>
                                                </Select>
                                            </Form.Item>
                                        </div>

                                        <Form.Item
                                            name="firstname"
                                            rules={[{ required: true }]}
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <Input
                                                className={s.field}
                                                placeholder={t("auth.firstname")}
                                                maxLength={30}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="lastname"
                                            rules={[{ required: true }]}
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <Input
                                                className={s.field}
                                                placeholder={t("auth.lastname")}
                                                maxLength={30}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="email"
                                            rules={[{ required: true }, { type: "email" }]}
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <Input
                                                type="email"
                                                className={s.field}
                                                placeholder={t("auth.email")}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="phone"
                                            rules={[{ required: false }]}
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <Input
                                                className={s.field}
                                                placeholder={"Numéro de téléphone"}
                                                type="number"
                                                pattern="[0-9]*"
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            style={{ marginBottom: "20px" }}
                                            name="countries"
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                                className={s.selectInput}
                                                placeholder={<div className={s.placeholderStyle}>Pays de résidence</div>}
                                                bordered={false}
                                                allowClear
                                                dropdownStyle={{ padding: "3px" }}
                                                showArrow={true}
                                            >
                                                {countriesList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                            </Select>
                                        </Form.Item>

                                        <Form.Item
                                            name="password"
                                            rules={[
                                                { required: true },
                                                {
                                                    pattern: /^.{6,}$/,
                                                    message: "Le mot de passe doit contenir au moins 6 caractères !",
                                                },
                                            ]}
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <Input.Password
                                                className={s.field}
                                                placeholder={t("auth.password")}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name="confirmPassword"
                                            rules={[
                                                { required: true },
                                                ({ getFieldValue }) => ({
                                                    validator(_: any, value: any) {
                                                        if (!value || getFieldValue("password") === value) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(
                                                            new Error("Les mots de passe ne correspondent :(")
                                                        );
                                                    },
                                                }),
                                            ]}
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <Input.Password
                                                className={s.field}
                                                placeholder={t("auth.confirmPassword")}
                                            />
                                        </Form.Item>

                                        <Row justify="center" style={{ marginTop: "30px" }}>
                                            <Form.Item>
                                                <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={s.nextButton}>SUIVANT</a></AppButton>
                                            </Form.Item>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </Form>

                            {current === 2 && (
                                <React.Fragment>
                                    <StepTwo
                                        stepOneData={registrationData}
                                        setCurrent={setCurrent}
                                    />
                                </React.Fragment>
                            )}

                            {auth.registerErrors.length > 0 &&
                                auth.registerErrors.map((error: any, index: any) => (
                                    <Alert key={index} message={error} type="error" showIcon />
                                ))}

                            {current === 1 && <div
                                className={s.text}
                                style={{ marginTop: "1em", textAlign: "center" }}
                            >
                                <p>
                                    Vous avez déjà un compte ?{" "}
                                    <Link to={appRoutes.LOGINS}>Connecte-toi</Link>
                                </p>
                            </div>}
                        </div>
                    </div>
                </Container>
            </div>
        </AnimatedPage>
    );
};

export default RegisterParentOrganisme;
