import { useState, useCallback, useEffect } from "react";
import axios from "axios";

const useGetQuizWithInswers = (id: string) => {
  const [quizWithInswers, setQuizWithInswers] = useState<any>([]);
  const [loadingQuery, setLoadingQuery] = useState<boolean>(true);
  const [errorQuery, setErrorQuery] = useState<any[]>();

  const getQuizWithInswers = useCallback(async (idQuiz: string) => {
    setLoadingQuery(true);
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL_RESSOURCES}/quizz/${idQuiz}/withInswers`);
      if (response) {
        setQuizWithInswers(response.data);
      }
    } catch (error: any) {
        setErrorQuery(error);
      console.log(error)
    } finally {
        setLoadingQuery(false);
    }
  }, []);

  useEffect(() => {
    getQuizWithInswers(id);
  }, [id]);

  return { quizWithInswers, loadingQuery, errorQuery };
};

export default useGetQuizWithInswers;
