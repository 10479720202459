import * as TOPICS_SERVICES from "api/services/topics/topics.services";
import React, { FC, Fragment, useCallback, useEffect, useState } from "react";

import { CheckOutlined, PlusOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Collapse,
  Image,
  Row,
  Select,
  Skeleton,
  Space,
  Tag,
  message,
} from "antd";
import H1 from "components/libs/title";
import SkillBar from "components/skillbar";
import AppScreen from "screens/teacher/ScreenWithSidebar";

//!IMAGES
import { ReactComponent as Arrow } from "assets/images/app/arrow.svg";
import ANGLAIS from "../../../assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "../../../assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "../../../assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "../../../assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "../../../assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "../../../assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "../../../assets/images/app/iconMatiers/SVT.png";
import Alarme from "../../../assets/images/students/alarme.svg";
import Plus from "../../../assets/images/students/plus.svg";

//!STYLES
import s from "./assets/stats.module.css";

//!CHART
import { getStatsData } from "api/services/studentQuizz/studentQuizz.services";
import { StatusCode, TypeMatiers, setIcon } from "common/enums";
import AppLatex from "components/appLatex";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import { badRequest } from "messages";
import { useDispatch, useSelector } from "react-redux";
import { openPaiementDrawer } from "store/chat/mainSlice";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";
import { useDashboardMutation } from "store/services/student/dashboard.services";
import LineChartComponent from "./components/lineChart";
import RadarChartComponent from "./components/radarChart";

const { Option, OptGroup } = Select;
const { Panel } = Collapse;

const Stats: FC = () => {
  const { data: levelsData } = useGetAllLevelsQuery("");
  const [dashboard, { data, isLoading, isError, isSuccess }] =
    useDashboardMutation();
  const { user } = useSelector((state) => state.auth);
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [idMatiere, setIdMatiere] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [tempLevelId, setTempLevelId] = useState<any | null>("");

  ///Stats part
  const [current, setCurrent] = useState<number>(0);
  const [idLevel, setIdLevel] = useState<string>(user?.levelId ? user?.levelId : "");

  const selectCourse = (courseId: string) => {
    setIdMatiere(courseId);
    setCurrent(1);
  }

  ///Stats part

  const { Option } = Select;
  function handleChange(value: string) {
    console.log(`selected ${value}`);
  }
  const dispatch = useDispatch();
  const { setShowDrawerPaiementOneMoreTime } = useSelector(
    (state) => state.auth
  );
  useEffect(() => {
    if (setShowDrawerPaiementOneMoreTime) dispatch(openPaiementDrawer());
  }, [setShowDrawerPaiementOneMoreTime]);

  const OnSelectLevel = useCallback(
    async (levelId: string | any) => {
      if (user) {
        setLoading(true);
        const response = await TOPICS_SERVICES.findAll(levelId);
        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
          setLoading(false);
        }
      }
    },
    [tempTopic]
  );

  useEffect(() => {
    if (!idMatiere) {
      OnSelectLevel(user?.levelId);
    }
    dashboard({
      studentId: user?._id,
      niveau: localStorage.getItem("tempLevelId") || user?.levelId,
      matiere: "62e00a1ebbf0e80cf96657e4",
    });
  }, [idMatiere]);

  const allLevels = levelsData?.data;

  const calculerTauxBonneReponse = (
    nbreReponses: number,
    nbreBonneReponses: number
  ) => {
    const tauxBonneReponses = (nbreBonneReponses / nbreReponses) * 100;
    const tauxArrondi = tauxBonneReponses.toFixed(2);
    return tauxArrondi;
  }

  const [courseData, setCourseData] = useState<any>([]);
  const [userStatsData, setUserStatsData] = useState<any>([]);
  const [radarAvg, setRadarAvg] = useState<any>();
  const [isStatsLoading, setIsStatsLoading] = useState<boolean>(false);

  const fetchStatsData = useCallback(
    async (levelID: string,) => {
      try {
        console.log(levelID);
        const res = await getStatsData(user?._id, levelID);
        const data = res?.data;
        const generalData = data.courseData;
        const coursesData = data.userStatsData;

        if (data.avg !== null) {
          const floatValue: number = data.avg;
          const stringValue: string = floatValue.toFixed(2);
          setRadarAvg(stringValue);
        }

        setCourseData(generalData);
        setUserStatsData(coursesData);
        setIsStatsLoading(false);

        console.log(data);
      } catch (error) {
        message.error(badRequest.error);
        setIsStatsLoading(false);
      }
    }, []
  );

  useEffect(() => {
    fetchStatsData(idLevel);
  }, [idLevel]);

  return (
    <React.Fragment>
      <Breadcrumbs isDashboard />

      <AppScreen className={s.stats}>
        <H1>DASHBOARD</H1>
        <Row justify="center">
          <Col>
            <Select
              bordered={false}
              loading={isLoading}
              defaultValue={user?.levelId}
              suffixIcon={
                <Arrow
                  className={s.iconArrowClass}
                  style={{
                    position: "relative",
                    right: "10px",
                    top: "-5px",
                  }}
                />
              }
              placeholder="Selectionner un niveau"
              className={s.__select}
              style={{
                width: "300px",
              }}
              onClick={() => {
                setTempLevelId("");
              }}
              onChange={(id: string, item) => {
                OnSelectLevel(id);
                localStorage.setItem("tempLevelId", id);
                setIdLevel(id);
                setIsStatsLoading(true);
              }}
            >
              <OptGroup label={"Primaire"}>
                {allLevels?.Primaire?.map((college: any) => (
                  <Option key={college?._id} value={college?._id}>
                    {tempLevelId?.title || college?.title}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label={"Collège"}>
                {allLevels?.Collège?.map((college: any) => (
                  <Option key={college?._id} value={college?._id}>
                    {tempLevelId?.title || college?.title}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label={"Lycée"}>
                {allLevels?.Lycée?.map((college: any) => (
                  <Option key={college?._id} value={college?._id}>
                    {tempLevelId?.title || college?.title}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label={"Prépa"}>
                {allLevels?.Prépa?.map((college: any) => (
                  <Option key={college?._id} value={college?._id}>
                    {tempLevelId?.title || college?.title}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Col>
        </Row>
        {current === 1 && <div className={s.header}>
          {tempTopic?.map((topic: any, index: number) => (
            <Fragment>
              {topic?.description && (
                <div
                  key={index}
                  className={`${s.card__matier} ${idMatiere === topic?._id && s.active
                    }`}
                  onClick={() => setIdMatiere(topic?._id)}
                >
                  <div className={s.icon__card__matiers}>
                    <Image
                      preview={false}
                      src={
                        topic?.description == TypeMatiers.mathematique
                          ? MATH
                          : topic?.description == TypeMatiers.SVT
                            ? SVT
                            : topic?.description == TypeMatiers.PhysiqueChimie
                              ? PHYSIQUE
                              : topic?.description == TypeMatiers.Philosophie
                                ? PHILO
                                : topic?.description == TypeMatiers.HistoireGeo
                                  ? HISTOIREGEO
                                  : topic?.description == TypeMatiers.Francais
                                    ? FRANCAIS
                                    : topic?.description == TypeMatiers.Anglais
                                      ? ANGLAIS
                                      : ""
                      }
                      alt={topic?.description}
                    />
                  </div>

                  <div className={s.topic_name}>
                    <h3>{topic?.title}</h3>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </div>}
        <div className={s.main}>
          {current === 1 && <div className={s.backHeader}>
            <div className={s.backLeft}>
              <H2 className={s.backTitle}>Mes résultats</H2>
            </div>

            <div className={s.center} />

            <div className={s.backRight}>
              <AppButton icon={<PlusOutlined />} onClick={() => setCurrent(0)}>
                Retour au dashboard
              </AppButton>
            </div>
          </div>}
          {isLoading ? (
            <div
              style={{
                background: "#fafafa",
                borderRadius: "15px",
                padding: "5%",
              }}
            >
              <Skeleton active style={{ marginBottom: "0.5em" }} />
              <Skeleton active style={{ marginBottom: "0.5em" }} />
              <Skeleton active style={{ marginBottom: "0.5em" }} />
            </div>
          ) : (
            <div>
              {current === 0 && <React.Fragment>
                {isStatsLoading ? (
                  <div
                    style={{
                      background: "#fafafa",
                      borderRadius: "15px",
                      padding: "5%",
                    }}
                  >
                    <Skeleton active style={{ marginBottom: "0.5em" }} />
                    <Skeleton active style={{ marginBottom: "0.5em" }} />
                    <Skeleton active style={{ marginBottom: "0.5em" }} />
                  </div>
                ) : (<div className={s.stats__dashboard}>
                  {courseData.length !== 0 ? <div className={s.statsContainer}>
                    <div className={s.radarContainer}>
                      <a className={s.boxTitle}>Performance</a>
                      <div className={s.radarBox}>
                        <a className={s.radarTitle}>Moyenne générale</a>
                        <div className={s.radarMarkBox}>
                          <a className={s.radarAVG}>{radarAvg} </a>
                          <a className={s.radarMaxAVG}> / 20</a>
                        </div>
                        <div className={s.radarChartBox}><RadarChartComponent data={courseData} /></div>
                      </div>
                    </div>
                    <div className={s.lineContainer}>
                      <div className={s.lineBox}>
                        {userStatsData.map((userData: any) => (
                          <div className={s.lineChartCard}>
                            <div className={s.lineChartCardHeader}>
                              <div className={s.lineTitle} onClick={() => selectCourse(userData.id)}><img src={setIcon(userData.icon)} height={30} /> {userData.title}</div>
                              {userData.avg !== 0 && <div className={s.lineChartCardAVG}>{userData.avg}</div>}
                            </div>
                            <LineChartComponent data={userData.stats} chartTitle={userData.title} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div> : <div
                    style={{
                      background: "#fafafa",
                      borderRadius: "15px",
                      padding: "0 5%",
                    }}
                  >
                    <AppResult
                      status="new"
                      title={`Hello ${user?.firstname}, Participe à ton premier quizz pour ce niveau, gagne des points et défie tes amis ! `}
                    />
                  </div>}
                </div>)}
              </React.Fragment>}
              {current === 1 && <React.Fragment>
                {data?.data?.quizzs.length > 0 ? (
                  <Fragment>
                    {data?.data?.quizzs.map((quiz: any, index: number) => (
                      <Collapse
                        key={index}
                        defaultActiveKey={["0"]}
                        ghost
                        style={{
                          padding: 0,
                          display: "block",
                          marginBottom: "2em",
                        }}
                      >
                        <Panel
                          showArrow={false}
                          key={index}
                          style={{ background: "#fafafa", borderRadius: "15px" }}
                          header={
                            <div className={s.card__dashboard}>
                              <div className={s.left}>
                                <div className={s.infos}>
                                  <div className={s.infos_top}>
                                    <Space>
                                      <h2>
                                        <AppLatex content={quiz?.title} />
                                      </h2>
                                      <Tag color="var(--first-color)">
                                        {quiz?.quizzLevel} | {quiz?.duration} mins
                                      </Tag>
                                    </Space>
                                  </div>

                                  <div>
                                    <Space>
                                      <Image
                                        preview={false}
                                        src={Alarme}
                                        width={20}
                                        alt="mathematique"
                                      />
                                      <span>Temps de réalisation : </span>
                                      <span>
                                        {quiz?.realisationTime ||
                                          "Non disponible"}
                                      </span>
                                    </Space>
                                  </div>
                                </div>
                              </div>

                              <div className={s.right}>
                                <Space>
                                  <div style={{ marginRight: "2em" }}>
                                    <h3>
                                      Taux de reponses correct{" "}
                                      {`(${quiz?.correctResponses}/${quiz?.questionsList?.length})`}
                                    </h3>
                                    <SkillBar
                                      className={""}
                                      width={calculerTauxBonneReponse(
                                        quiz?.questionsList?.length,
                                        quiz?.correctResponses
                                      )}
                                    />
                                  </div>
                                  <Image
                                    preview={false}
                                    src={Plus}
                                    width={20}
                                    alt="mathematique"
                                  />
                                </Space>
                              </div>
                            </div>
                          }
                        >
                          <div className={s.content__propositions}>
                            {quiz?.questionsList?.map(
                              (question: any, index: number) => (
                                <div key={index} className={s.propositions__card}>
                                  <h3>
                                    <AppLatex content={question?.title} />
                                  </h3>
                                  <div className={s.content_card}>
                                    {question?.propositionsList.map(
                                      (proposition: any, index: number) => (
                                        <div
                                          className={`${s.card} ${proposition?.isCorrect && s.isCorrect
                                            }
                              
                                        ${!proposition?.isCorrect &&
                                            proposition?.propositionSelected &&
                                            s.propositionSelected
                                            }
                                     `}
                                          key={index}
                                        >
                                          <h4>
                                            {proposition?.isCorrect && (
                                              <CheckOutlined
                                                style={{
                                                  color: "var(--first-color)",
                                                  marginRight: "10px",
                                                }}
                                              />
                                            )}

                                            <AppLatex
                                              content={proposition?.title}
                                            />
                                          </h4>

                                          {proposition?.propositionSelected ===
                                            true && (
                                              <div
                                                style={{
                                                  minWidth: "20px",
                                                }}
                                              >
                                                <Avatar
                                                  src={user?.profile}
                                                  size={26}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </Panel>
                      </Collapse>
                    ))}
                  </Fragment>
                ) : (
                  <div
                    style={{
                      background: "#fafafa",
                      borderRadius: "15px",
                      padding: "0 5%",
                    }}
                  >
                    <AppResult
                      status="new"
                      title={`Hello ${user?.firstname}, Participe à ton premier quizz pour gagner des points et défier tes amis ! `}
                    />
                  </div>
                )}
              </React.Fragment>}
            </div>
          )}
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default Stats;
