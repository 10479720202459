import { Button, Skeleton } from "antd";
import AppButton from "components/libs/button";
import React, { FC, useState, Fragment } from "react";

//!STYLE
import s from "./assets/calendarStyle.module.css";
import { useDispatch, useSelector } from "react-redux";

//!ICONS
import { displayDrawer } from "store/adventissements";
import { useDeleteAdMutation, useGetAdQuery } from "store/services/teacher/ads.services";
import AppResult from "components/appResults";
import AvailabilityCard from "./AvailabilityCard";

interface Niche {
    _id: string;
    date: string;
    level: { _id: string; title: string; levelGroup?: string }[];
    topic: { _id: string; title: string; level: string }[];
    timeRanges: { range: { hour: string; allow: boolean }[] }[];
    idCreneau: string;
}

interface Data {
    _id: string;
    niches: Niche[];
}

const TeacherRegistrationAvailability: FC = () => {
    //!HOOKS
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { data: crenaux, isLoading } = useGetAdQuery(user?._id);

    //!STATE
    const [allData, setAllData] = useState<any[]>([]);

    function sortNicheByDate(data: Data[]): { [key: string]: Niche[] } {
        if (data) {
            const nichesByDate: { [key: string]: Niche[] } = {};

            for (const { niches, _id } of data) {
                for (const niche of niches) {
                    if (!nichesByDate[niche.date]) {
                        nichesByDate[niche.date] = [];
                    }

                    nichesByDate[niche.date].push({
                        ...niche,
                        _id: _id,
                    });
                }
            }

            return nichesByDate;
        }

        return {};
    }

    return (
        <div>
            <div className={s.__form}>
                <Fragment>
                    <>
                        <div>
                            <Button type="primary" style={{ width: "125px" }}>Lundi</Button>
                            {sortNicheByDate(crenaux?.data).Monday?.map(
                                (item: any, i: number) => (
                                    <AvailabilityCard item={item} />
                                )
                            )}
                        </div>

                        <div>
                            <Button type="primary" style={{ width: "125px" }}>Mardi</Button>
                            {sortNicheByDate(crenaux?.data).Tuesday?.map(
                                (item: any, i: number) => (
                                    <AvailabilityCard item={item} />
                                )
                            )}
                        </div>
                        <div>
                            <Button
                                type="primary"
                                style={{ background: "red !important", width: "125px" }}
                            >
                                Mercredi
                            </Button>
                            {sortNicheByDate(crenaux?.data).Wednesday?.map(
                                (item: any, i: number) => (
                                    <AvailabilityCard item={item} />
                                )
                            )}
                        </div>
                        <div>
                            <Button type="primary" style={{ width: "125px" }}>Jeudi</Button>
                            {sortNicheByDate(crenaux?.data).Thursday?.map(
                                (item: any, i: number) => (
                                    <AvailabilityCard item={item} />
                                )
                            )}
                        </div>
                        <div>
                            <Button type="primary" style={{ width: "125px" }}>Vendredi</Button>
                            {sortNicheByDate(crenaux?.data).Friday?.map(
                                (item: any, i: number) => (
                                    <AvailabilityCard item={item} />
                                )
                            )}
                        </div>
                        <div>
                            <Button type="primary" style={{ width: "125px" }}>Samedi</Button>
                            {sortNicheByDate(crenaux?.data).Saturday?.map(
                                (item: any, i: number) => (
                                    <AvailabilityCard item={item} />
                                )
                            )}
                        </div>
                        <div>
                            <Button type="primary" style={{ width: "125px" }}>Dimanche</Button>
                            {sortNicheByDate(crenaux?.data).Sunday?.map(
                                (item: any, i: number) => (
                                    <AvailabilityCard item={item} />
                                )
                            )}
                        </div>
                    </>
                </Fragment>
            </div>

            <div className={s.__teacher__MAA} />
        </div>
    );
};

export default TeacherRegistrationAvailability;
