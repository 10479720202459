import { Col, Row } from "antd";
import Container from "components/container";
import React, { FC } from "react";

type Props = {
  className?: string;
};

const ScreenWithSidebar: FC<Props> = ({ className, children }) => {
  return (
    <div className={className}>
      <Container>
        <Row gutter={10}>
          <Col span={24}>{children}</Col>
          {/* <Col span={6}>
                        <Card style={{
                            position: 'sticky',
                            top: 20
                        }}>
                            200 €
                        </Card>
                    </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default ScreenWithSidebar;
