import {
  DeleteOutlined,
  PlusOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Col, Form, message, Row } from "antd";
import AppButton from "components/libs/button";
import { FC, useCallback, useEffect, useState } from "react";

//!STYLE
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import s from "../assets/adds.module.css";

//!ICONS
import AppDrawer from "components/appDrawer";
import H1 from "components/libs/title";
import Matiers from "components/matiers";
import { displayDrawer } from "store/adventissements";
import {
  useCreateAdMutation,
  useGetAdQuery,
  useUpadateAdMutation,
} from "store/services/teacher/ads.services";
import { translateDay } from "utils/formats";
import DrawerAdsCreneau from "./drawerAdsCreneau";

const DrawerAds: FC = () => {
  //!HOOKS
  const { t } = useTranslation();
  const { drawerVisible } = useSelector((state) => state.drawerAds);
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [createAd, { isLoading, isSuccess, isError }] = useCreateAdMutation();
  const { data: crenaux } = useGetAdQuery(auth.user?._id);
  const [
    upadateAd,
    { isSuccess: isSuccessAd, isLoading: isLoadingAd, isError: isErrorAd },
  ] = useUpadateAdMutation();

  const [form] = Form.useForm();

  //!STATE
  const [openDrawerAdsCreneau, setOpenDrawerAdsCreneau] =
    useState<boolean>(false);
  const [keyCreneau, setKeyCrenau] = useState<string | undefined>("");
  const [allData, setAllData] = useState<any[]>([]);
  const [currCreneaux, setCurrCreneaux] = useState<any[]>([]);
  const { refetch } = useGetAdQuery(auth.user?._id);

  const showDrawerDrawerAdsCreneau = useCallback((key?: string) => {
    setKeyCrenau(key);
    setOpenDrawerAdsCreneau(true);
  }, []);

  const removeDuplicateRange = (myArray: any) => {
    return (
      myArray.filter((item: any, index: number, self: any) => {
        const rangeStr = JSON.stringify(item.range);
        const dateStr = item.date;

        return (
          index ===
          self.findIndex((obj: any) => {
            return (
              JSON.stringify(obj.range) === rangeStr && obj.date === dateStr
            );
          })
        );
      }) || []
    );
  };

  const days = [
    { label: "Lundi", value: "Lundi" },
    { label: "Mardi", value: "Mardi" },
    { label: "Mercredi", value: "Mercredi" },
    { label: "Jeudi", value: "Jeudi" },
    { label: "Vendredi", value: "Vendredi" },
    { label: "Samedi", value: "Samedi" },
    { label: "Dimanche", value: "Dimanche" },
  ];

  const handleAdd = (day: any) => {
    showDrawerDrawerAdsCreneau(day);
  };

  const isDataEmpty = removeDuplicateRange(allData).length <= 0;

  const onFinish = useCallback(async () => {
    if (isDataEmpty) {
      const errorMsg = "Impossible d'ajouter un créneau vide";
      message.error(errorMsg);
      throw new Error(errorMsg);
    }

    const finalData = {
      niches: removeDuplicateRange(allData)
        .reduce((acc: any, curr: any) => {
          const index = acc.findIndex((x: any) => x.date === curr.date);
          if (index > -1) {
            acc[index] = {
              ...acc[index],
              items: [...acc[index].items, curr],
            };
            return acc;
          }
          return [
            ...acc,
            {
              date: curr.date,
              items: [curr],
            },
          ];
        }, [])
        .map((niche: any) => {
          return {
            date: translateDay(niche?.date),
            level: niche?.items.map((level: any) => level?.levels[0]?.value),
            topic: niche?.items.map((topic: any) => topic?.topics[0]?.value),
            timeRanges: niche?.items.map((r: any) => {
              return {
                range: [...r?.range],
                type: niche?.items.map((t: any) => t?.type)[0],
              };
            }),
          };
        }),
      teacherId: auth.user._id,
    };

    if (currCreneaux?.length >= 0) {
      const finalUpdateData = {
        currCreneaux,
        finalData,
        calendarId: crenaux?.data[0]?._id,
      };

      upadateAd(finalUpdateData);
    } else {
      createAd(finalData);
    }
  }, [allData]);

  useEffect(() => {
    const currCreneaux = crenaux?.data[0]?.niches.map((niche: any) => {
      return {
        date: niche?.date,
        level: niche?.level?.map((l: any) => l?._id),
        topic: niche?.topic?.map((t: any) => t?._id),
        timeRanges: niche?.timeRanges.map((r: any) => {
          return {
            range: [
              {
                hour: r?.range[0].hour,
              },

              { hour: r.range[r?.range.length - 1].hour },
            ],
            type: r?.type,
          };
        }),
      };
    });

    setCurrCreneaux(currCreneaux);
  }, [drawerVisible]);

  useEffect(() => {
    if (isSuccess || isSuccessAd) {
      message.success("créé avec succès !");
      setAllData([]);
      dispatch(displayDrawer(false));
    }
    if (isError || isErrorAd) {
      message.error("Une erreur inconu a été survenue");
    }
  }, [isSuccess, isError, isSuccessAd, isErrorAd]);

  const handleDelete = (index: number) => {
    const newData = [...allData];
    newData.splice(index, 1);
    setAllData(newData);
  };

  const renderDay = (day: any) => {
    return (
      <div key={day.value} style={{ width: "auto", marginRight: "10px" }}>
        <Button type="primary">{day.label}</Button>
        {removeDuplicateRange(allData)?.map((item: any, index: number) => {
          if (item.date !== day.value) {
            return null;
          }

          return (
            <div key={index} className={s.box}>
              <div>
                {item?.range[0].hour} &gt; {item?.range[1].hour}
              </div>
              <Matiers
                className={s.matiers}
                cours={item?.topics[0]?.children}
                level={item?.levels[0]?.label}
              />

              <div>
                {item?.type === "collectif" ? (
                  <UsergroupAddOutlined />
                ) : (
                  <UserOutlined />
                )}
              </div>

              <div className={s.delete___} onClick={() => handleDelete(index)}>
                <DeleteOutlined style={{ color: "red", fontWeight: 600 }} />
              </div>
            </div>
          );
        })}
        <div className={s.add___} onClick={() => handleAdd(day.value)}>
          <PlusOutlined style={{ color: "#fff", fontWeight: 600 }} />
        </div>
      </div>
    );
  };

  return (
    <AppDrawer
      title={
        <div className={s.__drawer__header}>
          <H1>Ajouter mes disponibilités</H1>
        </div>
      }
      onClose={() => {
        dispatch(displayDrawer(false));

        const isData = removeDuplicateRange(allData).length > 0;

        if (isData) {
          message.warning("Le planning n'est pas enregisté");
        }
      }}
      visible={drawerVisible}
      style={{ maxWidth: "100%" }}
    >
      <div>
        <Form name="control-hooks" onFinish={onFinish}>
          <div className={s.__form}>{days.map((day) => renderDay(day))}</div>

          <Row justify="center">
            <Col>
              <AppButton
                disabled={isDataEmpty}
                className={s.button__submit}
                htmlTypeSubmit={true}
                loading={isLoading || isLoadingAd}
              >
                Confirmer le planning
              </AppButton>
            </Col>
          </Row>
        </Form>

        <div className={s.__teacher__MAA} />
      </div>

      <DrawerAdsCreneau
        showDrawerDrawerAdsCreneau={showDrawerDrawerAdsCreneau}
        openDrawerAdsCreneau={openDrawerAdsCreneau}
        keyCreneau={keyCreneau}
        setAllData={setAllData}
        allData={allData}
        setOpenDrawerAdsCreneau={setOpenDrawerAdsCreneau}
      />
    </AppDrawer>
  );
};

export default DrawerAds;
