import { Form, Input, Row, Select, Steps, TreeSelect, message } from "antd";
import { fetchCoursForTopic } from "api/services/courses/courses.services";
import * as QUIZZ_API from "api/services/quizz/quizz.services";
import { PlayLevel, StatusCode } from "common/enums";
import * as appRoutes from "config/routes.config";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

//!COMPONNETS
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import AppScreen from "../ScreenWithSidebar";
import Quiz from "./quiz";

//!STYLE
import AppSelectGroup from "components/libs/selectGroup";
import s from "./assets/createQuiz.module.css";

const { Option } = Select;
const { Step } = Steps;
const { Search } = Input;

const { TreeNode } = TreeSelect;

const TeacherCreateQuiz: FC = () => {
  const navigate = useNavigate();
  //!HOOKS
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  //!STATE
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [firstStepData, setFirstStepData] = useState<any>({});
  const [isAddingQuizz, setIsAddingQuizz] = useState<boolean>(false);
  const [tempTopic, setTempTopic] = useState<any>();
  const [course, setCourse] = useState<any>(null);
  const [currentCourses, setCurrentCourses] = useState<any>(undefined);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isStateDraft, setIsStateDraft] = useState<boolean>(false);
  const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);

  const createQuizz = async (values: any) => {
    if (currentStep === 1) {
      setFirstStepData(values);
      return setCurrentStep(2);
    }
    values.quizz = { ...firstStepData, ...values?.quizz };
    delete values.quizz.levelId;
    try {
      setIsAddingQuizz(true);
      let response;
      response = await QUIZZ_API.create(isStateDraft, values);
      if (response?.statusCode === StatusCode.CREATED) {
        setCurrentStep(1);
        navigate(appRoutes.TEACHER_QUIZZ);
      } else {
        message.warning(response?.message);
      }
    } catch (error) {
      console.log(error);
      message.error("Une erreur inconue a été survenue");
    } finally {
      setIsAddingQuizz(false);
    }
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setIsLoading(true);
      setIsloadingTopic(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);

        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
        setIsloadingTopic(false);
      }
    },
    [tempTopic]
  );

  const OnSelectCours = useCallback(
    async (courseId: any) => {
      setIsLoading(true);
      try {
        if (courseId) {
          const response = await fetchCoursForTopic(courseId);
          if (response?.statusCode === StatusCode.OK) {
            setCourse(response?.data);
            setIsDisabled(false);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    },
    [course, isDisabled]
  );

  useEffect(() => {}, [createQuizz, isStateDraft, firstStepData]);

  return (
    <React.Fragment>
      <Breadcrumbs isCreateQuiz={true} isBack={true} />
      <Form autoComplete="off" onFinish={createQuizz}>
        <>
          {currentStep === 1 && (
            <AppScreen className={s.cours}>
              <H1>Créer un quiz</H1>
              <div className={s.teacher__maa}></div>
              <div className={s.form__container}>
                {/* <Form.Item>
                  <Steps current={currentStep}>
                    <Step />
                    <Step />
                  </Steps>
                </Form.Item> */}

                <>
                  <div className={`${s.select}`}>
                    <Form.Item
                      name="levelId"
                      style={{ marginBottom: "var(--mb-2)" }}
                    >
                      <AppSelectGroup
                        placeholder="Choisir un niveau"
                        onChange={(value: string) => OnSelectLevel(value)}
                      />
                    </Form.Item>
                  </div>

                  <div className={`${s.select}`}>
                    <Form.Item
                      style={{ marginBottom: "var(--mb-2)" }}
                      name="courseId"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est requis",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Choisir une matiere"
                        allowClear
                        loading={isloadingTopic}
                        bordered={false}
                        onChange={(courseId: string) => {
                          OnSelectCours(courseId);
                        }}
                      >
                        {tempTopic?.map((topic: any) => (
                          <Option key={topic._id} value={topic._id}>
                            {topic.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>

                  <div className={`${s.select}`}>
                    <Form.Item
                      style={{ marginBottom: "var(--mb-2)" }}
                      name="chapterId"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est requis",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Choisir un cours"
                        allowClear
                        showSearch
                        bordered={false}
                        loading={isDisabled}
                        disabled={isDisabled}
                        optionFilterProp="children"
                        filterOption={(input: string, option: any) =>
                          (option?.label.toLowerCase() ?? "").includes(input)
                        }
                        filterSort={(optionA, optionB) =>
                          (optionA?.label ?? "")
                            .toLowerCase()
                            .localeCompare((optionB?.label ?? "").toLowerCase())
                        }
                        options={course?.map((course: any) => {
                          return {
                            label: course.title,
                            value: course._id,
                          };
                        })}
                        onChange={(_, value: string) =>
                          setCurrentCourses(value)
                        }
                      />
                    </Form.Item>
                  </div>

                  <div className={`${s.select}`}>
                    <Form.Item
                      style={{ marginBottom: "var(--mb-2)" }}
                      name="quizzLevel"
                      rules={[
                        {
                          required: true,
                          message: "Ce champ est requis",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Niveau du quizz"
                        bordered={false}
                        allowClear
                      >
                        <Option value={PlayLevel.EASY}>
                          {" "}
                          {PlayLevel.EASY}{" "}
                        </Option>
                        <Option value={PlayLevel.MEDIUM}>
                          {" "}
                          {PlayLevel.MEDIUM}{" "}
                        </Option>
                        <Option value={PlayLevel.HARD}>
                          {" "}
                          {PlayLevel.HARD}{" "}
                        </Option>
                      </Select>
                    </Form.Item>
                  </div>

                  <Row justify="end">
                    <div className={`${s.select} ${s.temps}`}>
                      <Form.Item
                        name="duration"
                        rules={[
                          {
                            required: true,
                            message: "Ce champ est requis",
                          },
                        ]}
                      >
                        <Select placeholder="Durée" bordered={false} allowClear>
                          <Option value={15}>15min</Option>
                          <Option value={30}>30 min</Option>
                          <Option value={45}>45min</Option>
                          <Option value={60}>1h</Option>
                        </Select>
                      </Form.Item>
                    </div>

                    <Form.Item
                      name="teacherId"
                      hidden
                      initialValue={user?._id}
                    ></Form.Item>
                  </Row>

                  <Row justify="center">
                    <AppButton htmlTypeSubmit={true}>Suivant</AppButton>
                  </Row>
                </>
              </div>
            </AppScreen>
          )}

          <AppScreen className={s.cours}>
            {currentStep === 2 && (
              <Quiz
                currentCourses={currentCourses}
                firstStepData={firstStepData}
                setCurrentStep={setCurrentStep}
                isAddingQuizz={isAddingQuizz}
                setIsStateDraft={setIsStateDraft}
              />
            )}
          </AppScreen>
        </>
      </Form>
    </React.Fragment>
  );
};

export default TeacherCreateQuiz;
