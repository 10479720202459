import { Avatar, Col, Divider, Row, Space } from "antd";
import AppButton from "components/libs/button";
import React, { FC, Fragment, useRef } from "react";
import TEACHER from "../../../../assets/images/teacher/teacher.svg";

//
import Matiers from "components/matiers";
import { useSelector } from "react-redux";
import s from "./style.module.css";

type Props = {
  cren: any;
  currentTeacher: any;
  confirmReservations: any;
  index: number;
  isAllReservation: boolean;
  setConfirmRes: React.Dispatch<React.SetStateAction<any[]>>;
};

const CardCreneau: FC<Props> = ({
  cren,
  index,
  currentTeacher,
  isAllReservation = false,
  confirmReservations,
  setConfirmRes,
}) => {
  const { user } = useSelector((state) => state.auth);
  const dateRef = useRef<(HTMLDivElement | null)[]>([]);
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  const setRef = (index: number, element: HTMLDivElement) => {
    refs.current[index] = element;
  };

  const handleClick = (index: number) => {
    if (refs.current[index]) {
      refs.current[index]?.click();
    }
  };

  const handleChecked = (
    event: React.ChangeEvent<HTMLInputElement>,
    item: any
  ) => {
    let prev = confirmReservations;
    let itemIndex = prev.indexOf(item);
    if (itemIndex !== -1) {
      prev.splice(itemIndex, 1);
    } else {
      prev.push(item);
    }
    setConfirmRes([...prev]);
  };

  const renderElement = (cren: any) => {
    //conditions on collectif cren
    if (cren?.creneauTypes === 'collectif') {
      if (cren?.reservations?.length === 0) {
        //show button reservation
        return <div className={s.content__action__reservation}>
          <Row justify="space-between" align="middle" gutter={24}>
            <Col>
              <AppButton onClick={() => handleClick(cren?.id)}>
                Reserver pour {cren?.topic?.price} €
              </AppButton>
            </Col>
            <Col>
              <input
                ref={(element: any) => setRef(cren?.id, element)}
                type={"checkbox"}
                id={`input-${cren?.id}`}
                onChange={() => {
                  handleClick(index);
                }}
              />
            </Col>
          </Row>
        </div>
      } else {
        //here is creneau collectif and reservations length != 0 
        //check if my reservation is thhere
        let reservationFound = false
        for (let conteur = 0; conteur < cren?.reservations?.length; conteur++) {
          if (cren?.reservations[conteur]?.studId === user?._id) {
            reservationFound = true
          }
        }

        // if reservationFound = true => show button Meet
        if (reservationFound) {
          return <div style={{ width: "100%" }}>
            <Row justify="end">
              <Col>
                <a
                  href={cren?.reservations[0]?.studentLink}
                  target="_blank"
                >
                  <AppButton
                    style={{
                      maxWidth: "200px",
                      minWidth: "200px",
                    }}
                  >
                    Rejoindre le meet
                  </AppButton>
                </a>
              </Col>
            </Row>
          </div>
        } else {
          // if reservations length = 10 => show creneau reserved 
          if (cren?.reservations?.length >= 10) {
            return <div style={{ width: "100%" }}>
              <Row justify="end">
                <Col>
                  <span>Ce créneau a déjà été réservé.</span>
                </Col>
              </Row>
            </div>
          } else {
            //show button reserver pour ce creneau
            return <div className={s.content__action__reservation}>
              <Row justify="space-between" align="middle" gutter={24}>
                <Col>
                  <AppButton onClick={() => handleClick(cren?.id)}>
                    Reserver pour {cren?.topic?.price} €
                  </AppButton>
                </Col>
                <Col>
                  <input
                    ref={(element: any) => setRef(cren?.id, element)}
                    type={"checkbox"}
                    id={`input-${cren?.id}`}
                    onChange={() => {
                      handleClick(index);
                    }}
                  />
                </Col>
              </Row>
            </div>
          }
        }
      }
    }

    //conditions on individuel cren
    if (cren?.creneauTypes === 'individuel') {
      if (cren?.reservations?.length !== 0) {
        //check if this reservation is mine
        if (cren?.reservations[0]?.studId === user?._id) {
          //show the joint meet button
          return <div style={{ width: "100%" }}>
            <Row justify="end">
              <Col>
                <a
                  href={cren?.reservations[0]?.studentLink}
                  target="_blank"
                >
                  <AppButton
                    style={{
                      maxWidth: "200px",
                      minWidth: "200px",
                    }}
                  >
                    Rejoindre le meet
                  </AppButton>
                </a>
              </Col>
            </Row>
          </div>
        } else {
          return <div style={{ width: "100%" }}>
            <Row justify="end">
              <Col>
                <span>Ce créneau a déjà été réservé.</span>
              </Col>
            </Row>
          </div>
        }
      } else {
        //here creneau individuel + no reservations => show button Reservation
        return <div className={s.content__action__reservation}>
          <Row justify="space-between" align="middle" gutter={24}>
            <Col>
              <AppButton onClick={() => handleClick(cren?.id)}>
                Reserver pour {cren?.topic?.price} €
              </AppButton>
            </Col>
            <Col>
              <input
                ref={(element: any) => setRef(cren?.id, element)}
                type={"checkbox"}
                id={`input-${cren?.id}`}
                onChange={() => {
                  handleClick(index);
                }}
              />
            </Col>
          </Row>
        </div>
      }
    }
  }

  return (
    <div className={s.creneaux} key={index}>
      <div className={s.creneau}>
        <div className={s.card}>
          <div>
            <Row justify="space-between" align="middle" gutter={20}>
              <Col>
                <Avatar.Group
                  maxCount={2}
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  <Avatar
                    size={44}
                    style={{ backgroundColor: "#7fb1b2" }}
                    src={currentTeacher?.teacher?.profile || TEACHER}
                  />

                  {cren?.reservations?._id && (
                    <Avatar
                      size={44}
                      src={user?.profile}
                      style={{ backgroundColor: "#7fb1b2" }}
                    />
                  )}
                </Avatar.Group>
              </Col>
              <Col>
                {cren?.timeRanges?.length > 0 && (
                  <div className={s.range}>
                    <span>{cren?.timeRanges[0]}</span>
                    <span>-</span>
                    <span>{cren?.timeRanges[1]}</span>
                  </div>
                )}
              </Col>

              <Col>
                <Row align="middle">
                  <Space>
                    {console.log(cren, "mokoumbou")}
                    <div>
                      <span>50 min</span>
                    </div>
                    <div>
                      <span>
                        {typeof cren?.creneauTypes === "string"
                          ? cren?.creneauTypes
                          : cren?.creneauTypes.creneauTypes[0]}
                      </span>
                    </div>
                  </Space>
                  <div className={s.matiere}>
                    <Matiers
                      cours={cren?.level?.title}
                      level={cren?.topic?.title}
                    />
                  </div>
                </Row>
              </Col>
            </Row>
          </div>

          <div>
            <Fragment>
              {renderElement(cren)}
            </Fragment>
          </div>
        </div>
      </div>
      <input
        ref={(element: any) => setRef(index, element)}
        type="checkbox"
        id={`input-${index}`}
        value={index}
        hidden
        checked={confirmReservations.includes(index)}
        onChange={(e) => handleChecked(e, cren)}
      />
      <Divider />
    </div>
  );
};

export default CardCreneau;
