import React, { FC, useEffect } from "react";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import Stylo from "components/stylo";
import AppScreen from "../ScreenWithSidebar";

import { updateCoins } from "store/paiement/paiementSlice";

//!STYLE
import s from "./assets/success.module.css";

import AnimatedPage from "utils/animatedPage";
import { useDispatch } from "react-redux";

const Success: FC = () => {
  const dispatch = useDispatch();
  const updateCoinsFunction = () => {
    const sessionId = localStorage.getItem("sessionId");
    dispatch(updateCoins({ sessionId }));
    // localStorage.removeItem("sessionId");
  };

  useEffect(() => {
    updateCoinsFunction();
  }, []);

  return (
    <AnimatedPage>
      <Stylo />
      <Breadcrumbs />
      <AppScreen>
        <div className={s.container}>
          <div className={s.card}>
            <div
              style={{
                borderRadius: "200px",
                height: "200px",
                width: "200px",
                background: " #F8FAF5",
                margin: "0 auto",
              }}
            >
              <i className={s.italic}>✓</i>
            </div>
            <h1 className={s.title}>Succès</h1>
            <p className={s.paragraph}>
              Nous avons reçu votre demande d'achat
              <br />
              Mon ami Albert vous remercie et à bientôt
            </p>
          </div>
        </div>
      </AppScreen>
    </AnimatedPage>
  );
};

export default Success;
