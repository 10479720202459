import { Alert, Button, Col, Form, Image, Input, Row } from "antd";
import { loginUserDto } from "api/services/auth/dto/login-user.dto";
import { AuthMethods, UserRoles } from "common/enums";
import Container from "components/container";
import H1 from "components/libs/title";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import {
  loginWithEmailAndPassword,
  loginWithGoogle,
} from "store/auth/authSlice";
import s from "./assets/login.module.css";

//!IMAGES
import studentIcon from "assets/images/students/student.svg";
import TeacherIcon from "assets/images/teacher/teacher.svg";
import SendUpdatePasswordRequestDrawer from "components/updatePasswordDrawer/sendUpdateLink";
import AnimatedPage from "utils/animatedPage";

const LoginTeacher: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.appChat);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<"id">();

  //!CONSTANTS
  const validateMessages = {
    required: t("auth.error.required"),
    types: {
      email: t("auth.error.emailInvalid"),
    },
  };

  //!FUNCTIONS
  const loginUser = async (user: loginUserDto) => {
    await dispatch(loginWithEmailAndPassword(user));
  };

  const loginUserWithGoogle = () => dispatch(loginWithGoogle());

  const [passwordDrawerVisible, setPasswordDrawerVisible] = useState(false);
  const onClosePasswordDrawer = () => {
    setPasswordDrawerVisible(false);
  };

  //!EFFECTS
  useEffect(() => {
    const isLoggedIn = auth.isLoggedIn;
    const isTeacher = auth.user?.roles?.includes(UserRoles.TEACHER);
    const isStudent = auth.user?.roles?.includes(UserRoles.STUDENT);
    const isOrganism = auth.user?.roles?.includes(UserRoles.ORGANISME);
    const isCompleted = auth.user?.step === "completed";
    if (isLoggedIn && auth.lastRouteActionAuth) {
      navigate(auth.lastRouteActionAuth);
    } else {
      if (isLoggedIn)
        return isTeacher && isCompleted ? navigate(appRoutes.TEACHER_ACTIVITIES) :
          isTeacher && !isCompleted ? navigate(appRoutes.COMPLETE_PROFILE) :
            isStudent ? navigate(appRoutes.STUDENT_DASHBOARD) :
              isOrganism ? navigate(appRoutes.ORGANISME_DASHBOARD) :
                navigate(appRoutes.PARENT_DASHBOARD);
    }
  }, [auth.isLoggedIn, navigate, auth.user?.roles]);

  //!COMPONENT RETURN
  return (
    <AnimatedPage>
      <AppScreen className={s.login}>
        <Container>
          <Row justify="center" gutter={12}>
            {params.id?.includes("teacher") ? (
              <Image src={TeacherIcon} preview={false} width={65} />
            ) : (
              <Image src={studentIcon} preview={false} width={65} />
            )}
          </Row>

          <p className={s.info}>
            Je suis un{" "}
            <strong>
              {params.id?.includes("teacher") ? "Professoeur" : "Élève"}
            </strong>
            .<Link to={appRoutes.LOGINS}>Modifier</Link>
          </p>

          <Row justify="center" gutter={12}>
            <Col>
              <H1 className={`${s.title} ${s.titleLogin}`}>Se connecter</H1>
            </Col>
            <Col>
              <span className={s.separator}></span>
            </Col>
            <Col>
              <Link
                to={
                  params.id?.includes("teacher")
                    ? appRoutes.REGISTER_TEACHER_BY_EMAIL
                    : appRoutes.REGISTER_STUDENT
                }
              >
                <H1 className={s.title}>Créer mon compte</H1>
              </Link>
            </Col>
          </Row>

          <Form
            validateTrigger={["onFinish"]}
            validateMessages={validateMessages}
            name="loginUser"
            autoComplete="off"
            onFinish={loginUser}
            className={s.form__container}
          >
            <Form.Item
              name="email"
              rules={[{ required: true }, { type: "email" }]}
              style={{ marginBottom: "20px" }}
            >
              <Input
                type="email"
                className={s.field}
                placeholder={t("auth.email")}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true },
                {
                  pattern: /^.{6,}$/,
                  message:
                    "Le mot de passe doit contenir au moins 6 caractères !",
                },
              ]}
            >
              <Input.Password
                className={s.field}
                placeholder={t("auth.password")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                ghost
                loading={
                  auth.isLogging && auth.authMethod === AuthMethods.EMAIL
                }
                block
                type="primary"
                htmlType="submit"
                className={s.submit}
              >
                {t("auth.login.loginWithEmailAndPassword")}
              </Button>
            </Form.Item>
          </Form>

          <div style={{ margin: "20px auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <a className={s.forgotPasswordButton} onClick={() => setPasswordDrawerVisible(true)}>Mot de passe oublié ?</a>
          </div>

          <div className={s.form__container}>
            {auth.loginErrors.length > 0 &&
              auth.loginErrors.map((error, index) => (
                <Alert
                  key={index}
                  message={error}
                  type="error"
                  showIcon
                  closable
                />
              ))}

            {/* <Divider>Ou</Divider> */}
            {/* <Row justify="center">
              <Button
                loading={
                  auth.isLogging && auth.authMethod === AuthMethods.GOOGLE
                }
                block
                type="primary"
                htmlType="button"
                icon={<IconGoogle />}
                onClick={loginUserWithGoogle}
                className={`${s.button} ${s.gmail}`}
              >
                {t("auth.login.loginWithGoogle")}
              </Button>

              <Button
                block
                type="primary"
                htmlType="button"
                className={`${s.button} ${s.button__fb}`}
                icon={<IconFb strokeWidth={20} />}
              >
                {t("auth.login.loginWithFacebook")}
              </Button>
            </Row> */}

          </div>
        </Container>
        <SendUpdatePasswordRequestDrawer visible={passwordDrawerVisible} onClose={onClosePasswordDrawer} />
      </AppScreen>
    </AnimatedPage>
  );
};

export default LoginTeacher;
