import { ArrowLeftOutlined } from "@ant-design/icons";
import { Image } from "antd";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";
import s from "./assets/tabs.module.css";

//!IMAGES
import closeIcon from "assets/images/app/delete.svg";
import Container from "components/container";
import AppButton from "components/libs/button";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setFullscreen } from "store/app/appSlice";
import Fullscreen from "../../../../assets/images/app/fullscreen.svg";
import Cours from "../../../../assets/images/teacher/cours.svg";
import Exo from "../../../../assets/images/teacher/exo.svg";
import Quiz from "../../../../assets/images/teacher/quiz.svg";
import CustomButton from "../CustomButton";

interface TabsStudent {
  idChapter?: string;
  level?: string;
  textBack?: string;
  linkTo?: string;
  isPageStudentQuiz?: boolean;
  onClick?: () => void;
  isFullscreen?: boolean;
  onRequest: () => void;
  onExit: () => void;
}

const TeacherHeaderstabs: FC<TabsStudent> = ({
  idChapter,
  level,
  textBack,
  isPageStudentQuiz,
  onClick,
  isFullscreen,
  onRequest,
  onExit,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const params = useParams<"id">();
  const [tempIdCours, setTempIdCours] = useState<any>("");
  const [tempIdChapter, setTempIdChapter] = useState<any>("");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    let coursId = localStorage.getItem("idCours");
    let chapterId = localStorage.getItem("chapterId");
    setTempIdCours(coursId);
    setTempIdChapter(chapterId);
  }, [tempIdCours, tempIdChapter]);

  return (
    <Container>
      <div className={s.__content__tabs} onClick={onClick}>
        {!isMobile && <div className={!isFullscreen ? s.header : s.fullHeader}>
          {isFullscreen && <Link
            to={
              isPageStudentQuiz ? "#" : `${appRoutes.GETCHAPTERS}/${tempIdCours}`
            }
          >
            <div className={s.__back}>
              {textBack && (
                <span>
                  <ArrowLeftOutlined
                    style={{
                      position: "relative",
                      top: "2px",
                    }}
                  />{" "}
                  {textBack}
                </span>
              )}
            </div>
          </Link>}

          {!isFullscreen && location.pathname === `${appRoutes.GETCHAPTER}/${params.id!}` ?
            <AppButton
              onClick={() => {
                onRequest();
                dispatch(setFullscreen(true));
              }}
              className={s.fullscreenButton}
            >
              <Image preview={false} src={Fullscreen} width={15} />
              <span> Mode plein écran</span>
            </AppButton> : <div style={{ width: "34%" }}></div>
          }

          <div className={s.tabButtons}>
            <Link to={`${appRoutes.GETCHAPTER}/${tempIdChapter}`}>
              <div
                className={isFullscreen ? s.fullTabs : s.tabs}
                style={{
                  background: `${location.pathname ===
                    `${appRoutes.GETCHAPTER}/${params.id!}` &&
                    "var(--first-color)"
                    }`,
                }}
              >
                <Image
                  preview={false}
                  width={65}
                  src={Cours}
                  alt="teacher avatar"
                />
              </div>
            </Link>
            <Link to={`${appRoutes.FINDQUIZZFORCHAPTER}/${params.id}`}>
              <div
                className={isFullscreen ? s.fullTabs : s.tabs}
                style={{
                  background: `${(location.pathname ===
                    `${appRoutes.FINDQUIZZFORCHAPTER}/${params.id!}` &&
                    "var(--first-color)") ||
                    (location.pathname ===
                      `/student/search/quizz/${params.id!}` &&
                      "var(--first-color)") ||
                    (location.pathname === `/student/quizz/${params.id!}` &&
                      "var(--first-color)")
                    }`,
                }}
              >
                <Image
                  preview={false}
                  width={65}
                  src={Quiz}
                  alt="teacher avatar"
                />
              </div>
            </Link>
            <Link to={`${appRoutes.FINDEXOFORCHAPTER}/${tempIdChapter}`}>
              <div
                className={isFullscreen ? s.fullTabs : s.tabs}
                style={{
                  background: `${(location.pathname ===
                    `${appRoutes.FINDEXOFORCHAPTER}/${params.id!}` &&
                    "var(--first-color)") ||
                    (location.pathname ===
                      `${appRoutes.FINDEXOFORCHAPTER}/${params.id!}` &&
                      "var(--first-color)")
                    }`,
                }}
              >
                <Image
                  preview={false}
                  width={30}
                  src={Exo}
                  alt="teacher avatar"
                />
              </div>
            </Link>
          </div>

        </div>}
        {!isFullscreen && !isMobile && <Link
          to={
            isPageStudentQuiz ? "#" : `${appRoutes.GETCHAPTERS}/${tempIdCours}`
          }
        >
          <div className={s.__back}>
            {textBack && (
              <span>
                <ArrowLeftOutlined
                  style={{
                    position: "relative",
                    top: "2px",
                  }}
                />{" "}
                {textBack}
              </span>
            )}
          </div>
        </Link>}

        {isMobile && <div className={isFullscreen ? s.mobileMenuFullscreen : s.mobileMenu}>
          <div style={{ width: "50%" }}>
            {!isFullscreen && location.pathname === `${appRoutes.GETCHAPTER}/${params.id!}` &&
              <CustomButton
                onClick={() => {
                  onRequest();
                  dispatch(setFullscreen(true));
                }}
                className=""
                icon={Fullscreen}
                iconSize={12}
                children={" Mode plein écran"}
                style={{ fontSize: "10px", padding: "0.5em", marginBottom: "7px" }}
              />}
            {isFullscreen &&
              <CustomButton
                onClick={() => {
                  onExit();
                  dispatch(setFullscreen(false));
                }}
                className=""
                icon={closeIcon}
                iconSize={12}
                children={" Quitter mode immersive"}
                style={{ fontSize: "10px", padding: "0.5em", marginBottom: "7px" }}
              />}
            <Link
              to={
                isPageStudentQuiz ? "#" : `${appRoutes.GETCHAPTERS}/${tempIdCours}`
              }
            >
              <div style={{ fontSize: "12px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", fontFamily: "CartoonFont", color: "#333", fontWeight: "500" }}>
                {textBack && (
                  <span>
                    <ArrowLeftOutlined />{" "}
                    {textBack}
                  </span>
                )}
              </div>
            </Link>
          </div>
          <div style={{ width: "50%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
            <Link to={`${appRoutes.GETCHAPTER}/${tempIdChapter}`}>
              <div
                className={s.mobileTabs}
                style={{
                  background: `${location.pathname ===
                    `${appRoutes.GETCHAPTER}/${params.id!}` &&
                    "var(--first-color)"
                    }`,
                }}
              >
                <Image
                  preview={false}
                  width={35}
                  src={Cours}
                  alt="teacher avatar"
                />
              </div>
            </Link>
            <Link to={`${appRoutes.FINDQUIZZFORCHAPTER}/${params.id}`}>
              <div
                className={s.mobileTabs}
                style={{
                  background: `${(location.pathname ===
                    `${appRoutes.FINDQUIZZFORCHAPTER}/${params.id!}` &&
                    "var(--first-color)") ||
                    (location.pathname ===
                      `/student/search/quizz/${params.id!}` &&
                      "var(--first-color)") ||
                    (location.pathname === `/student/quizz/${params.id!}` &&
                      "var(--first-color)")
                    }`,
                }}
              >
                <Image
                  preview={false}
                  width={35}
                  src={Quiz}
                  alt="teacher avatar"
                />
              </div>
            </Link>
            <Link to={`${appRoutes.FINDEXOFORCHAPTER}/${tempIdChapter}`}>
              <div
                className={s.mobileTabs}
                style={{
                  background: `${(location.pathname ===
                    `${appRoutes.FINDEXOFORCHAPTER}/${params.id!}` &&
                    "var(--first-color)") ||
                    (location.pathname ===
                      `${appRoutes.FINDEXOFORCHAPTER}/${params.id!}` &&
                      "var(--first-color)")
                    }`,
                }}
              >
                <Image
                  preview={false}
                  width={15}
                  src={Exo}
                  alt="teacher avatar"
                />
              </div>
            </Link>
          </div>
        </div>}
      </div>
    </Container>
  );
};

export default TeacherHeaderstabs;
