import { Col, Row, Typography } from "antd";
import Container from "components/container";
import React, { FC, useState } from "react";

import H1 from "components/libs/title";
import s from "./assets/calendar.module.css";

//!IMAGES

import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import CalendarOfReservationTeacher from "./c";
import DrawerHistorique from "./components";

const { Title, Text } = Typography;

const AppCalendar: FC = () => {
  const [openDrawerHistorique, setOpenDrawerHistorique] =
    useState<boolean>(false);

  //!RETURN
  return (
    <React.Fragment>
      <Breadcrumbs isCalendar={true} />
      <div style={{ marginTop: "1em" }}>
        <Container>
          <H1>Planning des cours</H1>
          <Row justify="end">
            <Col>
              <AppButton onClick={() => setOpenDrawerHistorique(true)}>
                Mon historique
              </AppButton>
            </Col>
          </Row>

          <div className={s.calendrier}>
            <div className={s.calendar__content}>
              <CalendarOfReservationTeacher />
            </div>
          </div>
        </Container>
      </div>

      <DrawerHistorique
        open={openDrawerHistorique}
        setOpen={setOpenDrawerHistorique}
      />
    </React.Fragment>
  );
};

export default AppCalendar;
