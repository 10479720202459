import React from "react";
import style from "./style.module.css";
import {
  WhatsappShareButton,
  WhatsappIcon,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share";
interface monInterface {
  prefix: string;
  codeAcces: string;
}
const index: React.FC<monInterface> = (props) => {
  return (
    <div className={style.flexGap}>
      <div>
        {" "}
        <WhatsappShareButton title={props.prefix} url={props.codeAcces}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </div>
      <div>
        {" "}
        <TwitterShareButton title={props.prefix} url={props.codeAcces}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </div>{" "}
      <div>
        {" "}
        <EmailShareButton title={props.prefix} url={props.codeAcces}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </div>
    </div>
  );
};

export default index;
