export const API_BASE_URL = `${process.env.REACT_APP_API_URL}/exercises`;

export const API_BASE_URL_SECOND = `${process.env.REACT_APP_API_URL_RESSOURCES}/exercices`;

export const TEACHER_FILTER_EXERCICE = (value: any) =>
  `${process.env.REACT_APP_API_URL}/exercises/?title=${
    value?.title ? value?.title : ""
  }&levelId=${value?.levelId ? value?.levelId : ""}&course=${
    value?.topicId ? value?.topicId : ""
  }&status=${value?.status ? value?.status : ""}&chapterId=${
    value?.chapterId ? value?.chapterId : ""
  }&type=${value?.type ? value?.type : ""}&self=${
    value?.self ? value?.self : "false"
  }`;

export const PROPOSITION_EXO = (idExercice: string) =>
  `${process.env.REACT_APP_API_URL_RESSOURCES}/exercices/${idExercice}/proposition`;
