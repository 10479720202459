import {
    Form,
    Input,
    message,
    Progress,
    Row
} from "antd";
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

//!IMAGES
import checkPicture from '../../../assets/images/app/checkIcon.svg';
import closeDeleteIcon from '../../../assets/images/app/closeDeleteIcon.svg';
import dotPicture from '../../../assets/images/app/instructionDot.png';
import pauseIcon from '../../../assets/images/app/pause_icon.svg';
import playIcon from '../../../assets/images/app/play_icon.svg';
import trashIcon from '../../../assets/images/app/trash_icon.svg';
import warningPicture from '../../../assets/images/app/videoWarning.svg';

//!STYLES
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import { useDispatch, useSelector } from "react-redux";
import CustomCheckBox from "../components/Checkbox";
import st from "./assets/register.module.css";

import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { uploadToCloudinary } from "api/services/teacher/teacher.services";
import axios from "axios";
import { setRecording } from "store/app/appSlice";
import { updateUser } from "store/auth/authSlice";
import CustomButton from "../components/CustomButton";


type Props = {
    stepFiveData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepSix: FC<Props> = ({ stepFiveData, setCurrent }) => {
    //!HOOKS
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth);
    const [formInfos] = Form.useForm();

    //!STATES
    const [isModalVisible, setIsModalVisible] = useState(false);

    const [isPictureAdded, setIsPictureAdded] = useState<boolean>(false);

    const [imagePreview, setImagePreview] = useState(
        user?.vignette
            ? user.vignette
            : ''
    );

    const [videoPreview, setVideoPreview] = useState(
        user?.videoPresentation
            ? user.videoPresentation
            : ''
    );

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const videoRef = useRef<HTMLVideoElement>(null);
    const mediaRecorderRef = useRef<MediaRecorder | null>(null);
    const [isRecording, setIsRecording] = useState(false);
    const [isStartingRecording, setIsStartingRecording] = useState(false);
    const [videoBlob, setVideoBlob] = useState<Blob | null>(null);
    const [uploadProgress, setUploadProgress] = useState<number | null>(null);

    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const [remainingTime, setRemainingTime] = useState(120000);
    const [startTime, setStartTime] = useState<number | null>(null);

    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    // Format the remaining time as MM:SS
    const formatTime = (time: number) => {
        const minutes = Math.floor(time / 60000);
        const seconds = Math.floor((time % 60000) / 1000);
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const [isPaused, setIsPaused] = useState(false);


    const pauseRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "recording") {
            mediaRecorderRef.current.pause();
            setIsPaused(true);

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }

            const elapsedTime = Date.now() - (startTime || 0);
            setRemainingTime(remainingTime - elapsedTime);
        }
    };

    const resumeRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === "paused") {
            mediaRecorderRef.current.resume();
            setIsPaused(false);

            setStartTime(Date.now());

            timeoutRef.current = setTimeout(() => {
                stopRecording();
                dispatch(setRecording(false));
            }, remainingTime);

            // Resume the countdown interval
            intervalRef.current = setInterval(() => {
                setRemainingTime(prevTime => prevTime - 1000);
            }, 1000);
        }
    };


    const startRecording = async () => {
        setIsStartingRecording(true);
        setIsRecording(true);
        try {

            setTimeout(() => {
                setIsStartingRecording(false);
                setIsModalVisible(true);
                dispatch(setRecording(true));
            }, 2300);

            const stream = await navigator.mediaDevices.getUserMedia({
                video: {
                    facingMode: 'user',
                    aspectRatio: 16 / 9,
                }, audio: true
            });

            videoRef.current!.srcObject = stream;
            const mediaRecorder = new MediaRecorder(stream);
            const recordedChunks: BlobPart[] = [];

            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    recordedChunks.push(event.data);
                }
            };

            mediaRecorder.onstop = () => {
                const blob = new Blob(recordedChunks, { type: 'video/mp4' });
                setVideoBlob(blob);
                dispatch(setRecording(false));
            };

            mediaRecorder.start();
            mediaRecorderRef.current = mediaRecorder;
            setStartTime(Date.now());

            /* setTimeout(() => {
                stopRecording();
                dispatch(setRecording(false));
            }, 120000); */

            timeoutRef.current = setTimeout(() => {
                stopRecording();
                dispatch(setRecording(false));
            }, remainingTime);

            // Start the countdown interval
            intervalRef.current = setInterval(() => {
                setRemainingTime(prevTime => prevTime - 1000);
            }, 1000);
        } catch (error) {
            console.error('Error accessing camera:', error);
        }
    };

    const stopRecording = async () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== "inactive") {
            mediaRecorderRef.current.stop();
            const tracks = mediaRecorderRef.current.stream.getTracks();
            tracks.forEach((track) => track.stop());
            setIsRecording(false);
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        setRemainingTime(120000);
        setStartTime(null);
    };

    const clearVideo = async () => {
        setIsRecording(false);
        setVideoBlob(null);
        setVideoPreview("");
    }

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    const inputRef = useRef<HTMLInputElement>(null);

    //!STATES JOFREY
    const [stepSixData, setStepSixData] = useState<any>({});

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    //!FUNCTIONS JOFREY
    const moveToStepSeven = async (values: any) => {
        setIsLoading(true);
        if (videoBlob) {
            uploadVideo(videoBlob, values);
        }
        else {
            if (values.presentationLink !== undefined) {
                updateUserData(values, values.presentationLink);
            }
            else {
                updateUserData(values, values.videoLink);
            }
        }
    }

    const updateUserData = async (values: any, url: string) => {
        const finalData = {
            videoPresentation: url,
            vignette: values.thumbnail !== "" ? values.thumbnail : "",
            step: user && user?.step === "completed" ? "completed" : "video",
        };
        console.log(finalData);

        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                setStepSixData(user);
                message.success({
                    content: "Modification effectuée ",
                });
                dispatch(
                    updateUser({
                        ...user,
                        videoPresentation: url,
                        vignette: values.thumbnail || "",
                        step: user && user?.step === "completed" ? "completed" : "video",
                    })
                );
            }
            else {
                message.warning({
                    content: "Une erreur est survenue ",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            setIsLoading(false);
            setCurrent(7);
        }
    }

    //!Picture Advices
    const advicesList = [
        "Votre vidéo devrait durer entre 30 sec et 2 min",
        "Enregistrer au format paysage et à hauteur des yeux",
        "Utilise un fond neutre ainsi qu'une bonne lumière en te plaçant face à une fenêtre",
        "Poser la caméra sur une surface stable afin d’éviter que l’image tremble",
        "Veille à ce que ton visage et tes yeux soient entièrement visibles (exception faite de motifs religieux)",
        "Mettre l’accent sur votre expérience d’enseignement et vos diplômes ou certifications dans ce domaine",
        "Accueillir chaleureusement les élèves et les inviter à réserver un cours"
    ];

    const warningList = [
        "Donner votre nom de famille ou vos coordonnées",
        "Inclure des logos ou des liens",
        "Utiliser des diaporamas ou des présentations",
        "Avoir d’autres personnes apparaissant dans votre vidéo",
    ];

    ///Drag & Drop Functions
    function handleFile(files: any) {
        setIsLoading(true);
        setIsPictureAdded(true);

        const file = files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
            setImagePreview(reader.result);
        };

        if (file) {
            reader.onload = () => {
                uploadThumbnail(file);
            };
            reader.readAsDataURL(file);
        }
    }

    ///Upload Diploma
    const uploadThumbnail = async (file: any) => {
        try {
            const response = await uploadToCloudinary(file, "vignette");
            if (response?.statusCode === StatusCode.CREATED) {
                setThumbnailInputValue(response?.data);
            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (let i = 0; i < error?.response?.data?.message?.length; i++) {
                content = error?.response?.data?.message[i] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    // triggers when file is selected with click
    const handleChange = (e: any) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            handleFile(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        if (inputRef.current != null) {
            inputRef.current.click();
        }
    };

    ///Upload Video
    const uploadVideo = async (file: any, values: any) => {

        const formData = new FormData();
        formData.append("file", file);

        try {
            const response = await axios.post('https://api-ressources.monamialbert.com/api/v1/users/profil/update/upload?type=presentation', formData, {
                onUploadProgress: (progressEvent) => {
                    const percentage = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    setUploadProgress(percentage);
                },
            });
            if (response?.status === StatusCode.CREATED) {
                if (response?.data !== 'Face not detected') {
                    setVideoInputValue(response?.data['data']);
                    updateUserData(values, response?.data['data']);
                }
                else {
                    message.warning({
                        content: "Visage non detecté, merci de réessayer.",
                    });
                }
            }
            else {
                message.warning({
                    content: "Une erreur est survenue ",
                });
            }
        } catch (error: any) {
            let content = "";
            for (let i = 0; i < error?.response?.data?.message?.length; i++) {
                content = error?.response?.data?.message[i] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    const setThumbnailInputValue = (url: string) => {
        const thumbnailKey = "thumbnail";
        formInfos.setFieldsValue({
            [thumbnailKey]: url,
        });
        setIsLoading(false);
    }

    const setVideoInputValue = (url: string) => {
        const presentationKey = "presentationLink";
        formInfos.setFieldsValue({
            [presentationKey]: url,
        });
    }

    //!EFFECTS
    useEffect(() => {
        if (user) {
            if (user?.videoPresentation.includes("cloudinary")) {
                formInfos.setFieldsValue({
                    presentationLink: user?.videoPresentation || "",
                    thumbnail: user?.vignette || "",
                });
            }
            else {
                formInfos.setFieldsValue({
                    videoLink: user?.videoPresentation || "",
                    thumbnail: user?.vignette || "",
                });
            }
            if (user?.vignette !== "") {
                setIsPictureAdded(true);
            }
        }
    }, [user, formInfos]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // Clean up interval on component unmount
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);


    return (
        <div style={{ alignItems: "flex-start", margin: "0px auto", width: "100%", height: "100%" }}>
            {isRecording ? <div className={isStartingRecording ? st.videoRecorderBox : st.videoRecorderClearBox} style={{ zIndex: "10" }}>
                {isStartingRecording && <div className={st.videoLoading} style={{}}>Chargement...</div>}
                <video ref={videoRef} width="100%" height="100%" autoPlay muted playsInline style={{ background: "#000" }}></video>
                {isRecording && !isStartingRecording && <div style={{ color: 'white', fontSize: "16px", position: 'absolute', top: '10px', right: '20px', zIndex: 2 }}>{formatTime(remainingTime)}</div>}
                {isRecording && !isStartingRecording && <div className={st.stopButtonBox}><CustomButton onClick={stopRecording} className={st.stopButtonStyle} children={"Arreter l'enregistrement"} style={{ height: "35px" }}></CustomButton></div>}
                {isPaused && !isStartingRecording && <img src={playIcon} style={{ height: "40px", cursor: "pointer", position: "relative", bottom: "120px", right: "30%" }} onClick={resumeRecording}></img>}
                {!isPaused && !isStartingRecording && <img src={pauseIcon} style={{ height: "40px", cursor: "pointer", position: "relative", bottom: "120px", right: "30%" }} onClick={pauseRecording}></img>}
                {isPaused && !isStartingRecording && <a style={{ fontSize: "20px", color: "white", position: "relative", bottom: "60%" }}>Vidéo en pause</a>}
            </div> : <Form
                validateTrigger={["onFinish"]}
                name="registerTeacher"
                autoComplete="off"
                form={formInfos}
                validateMessages={validateMessages}
                onFinish={moveToStepSeven}
            >
                <Row justify="start">
                    <div className={st.uploadImageBox}>
                        <div className={st.uploadImageBoxTitle}>Ajoute une vidéo de 2 minutes maximum</div>
                        <div className={st.uploadImageBoxMessage}>
                            Présente-toi aux élèves dans la langue dans laquelle tu vas enseigner.<br />
                            Assure-toi de consulter les pré-requis de création de la vidéo avant<br /> de soumettre/ajouter la tienne.
                        </div>

                        <div style={{ display: "flex", flexDirection: "column", height: "auto", width: "100%" }}>
                            {videoBlob && !isRecording && videoPreview === '' ? <div className={st.videoPreviewBox}>
                                <video width="100%" height="auto" style={{ borderRadius: "5px" }} controls>
                                    <source src={URL.createObjectURL(videoBlob)} type="video/mp4" />
                                </video>
                            </div> : videoBlob && !isRecording && videoPreview !== '' ? <div className={st.videoPreviewBox}>
                                <video width="100%" height="auto" style={{ borderRadius: "5px" }} controls>
                                    <source src={URL.createObjectURL(videoBlob)} type="video/mp4" />
                                </video>
                            </div> : !videoBlob && !isRecording && videoPreview !== '' ? <div className={st.videoPreviewBox}>
                                <video width="100%" height="auto" style={{ borderRadius: "5px" }} controls>
                                    <source src={videoPreview} type="video/mp4" />
                                </video>
                            </div> : <div id={st.videoFileUpload}></div>}
                            <div className={st.addVideoButton}>
                                {!isRecording && !videoBlob && videoPreview === '' && <AppButton onClick={startRecording} className={st.startRecordingVideo}><a className={st.startRecordingVideo}>COMMENCER L’ENREGISTREMENT</a></AppButton>}
                                {!isRecording && (videoBlob || videoPreview !== '') && <AppButton onClick={startRecording} className={st.startRecordingVideo}><a className={st.startRecordingVideo}>REFAIRE L’ENREGISTREMENT</a></AppButton>}
                                {!isRecording && (videoBlob || videoPreview !== '') && <img src={trashIcon} className={st.trashIcon} onClick={clearVideo}></img>}
                            </div>
                        </div>

                        <div className={st.uploadVideoBoxTitle}>Ou colle un lien vers une vidéo déjà existante par ici!</div>
                        <div className={st.uploadVideoBoxMessage}>Conseils pour ajouter une vidéo à partir de Youtube ou Vimeo</div>

                        <div className={st.videoLinkInput}>
                            <Form.Item
                                name="videoLink"
                                rules={[{ required: false }]}
                            >
                                <Input
                                    className={st.field}
                                    placeholder="https://youtu.be/xSgT4ZtT5M0"
                                />
                            </Form.Item>
                        </div>

                        <div className={st.uploadVideoBoxSubtitle}>Ajoute une vignette (facultatif)</div>
                        <div className={st.uploadVideoBoxMessage}>Si tu n'en n'as pas à disposition, nous utiliserons l'aperçu affiché <br /> ci-dessus.</div>

                        <div className={st.vignetteBox}>
                            {isPictureAdded ? <img src={imagePreview} alt="Uploaded Preview" height="100%" width="100%" style={{ borderRadius: "5px" }} /> :
                                <img
                                    height={46}
                                    width={53}
                                    src={warningPicture}
                                    alt="Warning"
                                />}
                            {!isPictureAdded && <div className={st.thumbnailMessage}>
                                N'y inclue pas ton nom de famille, tes coordonnées, <br />tes tarifs, tes réductions, ni de photo sans rapport avec <br />ton profil.
                            </div>}
                        </div>

                        <div className={st.addVignetteButton}>
                            <AppButton onClick={onButtonClick}><a className={st.uploadButtonStyle}>{isPictureAdded ? "MODIFIER" : "IMPORTER"}</a></AppButton>
                            <input style={{ display: "none" }} ref={inputRef} type="file" id={st.inputFileUpload} multiple={true} onChange={handleChange} />
                        </div>
                        <p className={st.videoFormatTitle}>Format JPG ou PNG Taille maximale 5 Mo</p>
                        <Form.Item
                            name="presentationLink"
                            rules={[{ required: false }]}
                            style={{ visibility: "collapse" }}
                        >
                            <Input
                                className={st.field}
                            />
                        </Form.Item>
                        <Form.Item
                            style={{ height: "5px", margin: "5px", visibility: "collapse" }}
                            name="thumbnail"
                            rules={[{ required: false }]}
                        >
                            <Input className={st.field} />
                        </Form.Item>

                    </div>
                    <div className={st.exampleBoxLong}>
                        <div className={st.videoExampleBoxTitle}>Pré-requis à suivre pour la vidéo</div>
                        <div className={st.uploadVideoBoxSubMessage}>
                            Assure-toi que ta vidéo répond aux .<br />
                            exigences suivantes afin qu'elle soit approuvée<br />par notre équipe
                        </div>
                        <div className={st.cardInfoBoxToDo}>
                            <div className={st.instructionBoxLong}>
                                <div className={st.checkboxBox}>
                                    <CustomCheckBox type="check" icon={checkPicture} />
                                    <p className={st.checkboxBoxItem} style={{ fontWeight: "600", color: "#333" }}>À faire</p>
                                </div>
                                {advicesList.map((item, i) => (
                                    <div key={i} className={st.checkboxBoxLong}>
                                        <div style={{ height: "7px", width: "7px", borderRadius: "100%", marginRight: "7px" }}>
                                            <img
                                                height={6}
                                                width={6}
                                                src={dotPicture}
                                                alt="Dot"
                                            />
                                        </div>
                                        <div className={st.checkboxBoxItemLong}>{item}</div>
                                    </div>
                                ))}

                                <div className={st.checkboxBox} style={{ marginTop: "20px" }}>
                                    <CustomCheckBox type="close" icon={closeDeleteIcon} />
                                    <p className={st.checkboxBoxItem} style={{ fontWeight: "600", color: "#333" }}>À éviter</p>
                                </div>
                                {warningList.map((item, i) => (
                                    <div key={i} className={st.checkboxBoxLong}>
                                        <div style={{ height: "7px", width: "7px", borderRadius: "100%", marginRight: "7px" }}>
                                            <img
                                                height={6}
                                                width={6}
                                                src={dotPicture}
                                                alt="Docs"
                                            />
                                        </div>
                                        <div className={st.checkboxBoxItemLong}>{item}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Row>
                {uploadProgress !== null && (
                    <Progress percent={uploadProgress}>
                        {uploadProgress}%
                    </Progress>
                )}
                <div className={st.rowButtons}>
                    <div className={st.rowButtonChild}>
                        <AppButton onClick={() => setCurrent((n) => n - 1)}><a className={st.nextButton}>RETOUR</a></AppButton>
                    </div>
                    <div className={st.rowButtonChild}>
                        <Form.Item style={{ margin: 0, padding: 0 }}>
                            <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={st.nextButton}>SUIVANT</a></AppButton>
                        </Form.Item>
                    </div>
                </div>
            </Form>}
        </div>
    );
};

export default StepSix;
