import { Col, Drawer, Form, Space, message } from 'antd';
import { sendIndiceProposal, sendSolutionProposal } from 'api/services/studentQuizz/studentQuizz.services';
import { CKEditor } from 'ckeditor4-react';
import { HandlerInputError, StatusCode } from 'common/enums';
import AppButton from 'components/libs/button';
import MathScan from 'components/maths';
import UploadCloudinary from 'components/uploadCloudinary';
import React, { useState } from 'react';
import Latex from 'react-latex-next';
import s from "./style.module.css";

interface AddSolutionIndiceDrawerProps {
    visible: boolean;
    onClose: () => void;
    type: string;
    idTeacher: string;
    idExo: string;
    idContent: string;
}

const AddSolutionIndiceDrawer: React.FC<AddSolutionIndiceDrawerProps> = ({ visible, onClose, type, idTeacher, idExo, idContent }) => {

    const [editedContent, setEditedContent] = useState<any>('');
    const [isTyping, setIsTyping] = useState<boolean>(false);
    const [form] = Form.useForm();

    const handleSave = async (values: any) => {
        if (type === "solution") {
            try {
                const response = await sendSolutionProposal(
                    idTeacher,
                    idExo,
                    idContent,
                    editedContent,
                );

                if (response?.statusCode === StatusCode.CREATED) {
                    const successMessage = "Modifications envoyées pour vérification";
                    message.success(successMessage);
                }
                else {
                    message.warning("Une erreur est survenue lors de l'enregistrement");
                }
            } catch (error) {
                console.error(error);
                message.error("Une erreur est survenue lors de l'enregistrement");
            } finally {
                setIsTyping(false);
                onClose();
            }
        }
        else {
            try {
                const response = await sendIndiceProposal(
                    idTeacher,
                    idExo,
                    idContent,
                    editedContent,
                );

                if (response?.statusCode === StatusCode.CREATED) {
                    const successMessage = "Modifications envoyées pour vérification";
                    message.success(successMessage);
                }
                else {
                    message.warning("Une erreur est survenue lors de l'enregistrement");
                }
            } catch (error) {
                console.error(error);
                message.error("Une erreur est survenue lors de l'enregistrement");
            } finally {
                setIsTyping(false);
                onClose();
            }
        }
    };


    return (
        <Drawer
            title={type === "solution" ? "Proposition de solution" : "Proposition d'indice"}
            visible={visible}
            onClose={onClose}
            width="70%"
            destroyOnClose
        >
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: "50%" }}>
                    <Form autoComplete="off" form={form} onFinish={handleSave}>
                        <div className={s.__custom__ck_editor}>
                            <Space>
                                <UploadCloudinary />
                                <MathScan />
                            </Space>

                            <Form.Item
                                name="content"
                                valuePropName="data"
                                getValueFromEvent={(event) => { const data = event?.editor?.getData(); return data; }}
                                rules={[{ required: true, message: HandlerInputError.content }]}
                            >
                                <CKEditor
                                    data={editedContent}
                                    onChange={(changes: any) => { const content = changes.editor.getData(); setEditedContent(content); }}
                                    style={{ border: "none" }}
                                />
                            </Form.Item>
                        </div>

                        <Col style={{ textAlign: "center" }}>
                            <AppButton
                                loading={isTyping}
                                htmlTypeSubmit={true}
                            >
                                Soumettre pour validation
                            </AppButton>
                        </Col>
                    </Form>
                </div>
                <div style={{ width: "50%" }}>
                    <div className={s.appercue__continute}>
                        <h3>Aperçu : </h3>
                        <div className={s.__latext__content}>
                            <Latex>
                                {editedContent
                                    ? editedContent
                                        ?.replaceAll("amp;", "")
                                        ?.replaceAll("<br />", "")
                                    : " "}
                            </Latex>
                        </div>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default AddSolutionIndiceDrawer;