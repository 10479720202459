import React, { FC, useState, useCallback, useEffect } from "react";
import { Col, Collapse, message, Row, Select } from "antd";
import { SettingOutlined } from "@ant-design/icons";
import { findAllFaq } from "api/services/faq/faq.services";
import { Button, Drawer } from "antd";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import H1 from "components/libs/title";
import Stylo from "components/stylo";
import AppScreen from "../ScreenWithSidebar";

//!STYLE
import s from "./assets/faq.module.css";

//!IMAGES
import { ReactComponent as Plus } from "./assets/images/plus.svg";
import AnimatedPage from "utils/animatedPage";
import { StatusCode } from "common/enums";
import { badRequest } from "messages";
import AppButton from "components/libs/button";
import { useSelector } from "react-redux";
// import TestMathType from "./test";

const { Panel } = Collapse;
const { Option } = Select;

function callback(key: any) {
  console.log(key);
}

const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

const genExtra = () => (
  <Plus
    style={{
      width: "25px",
      position: "relative",
      right: "-15px",
    }}
    onClick={callback}
  />
);

const FAQ: FC = () => {
  //!STATE
  const [loading, setLoading] = useState<boolean>(true);
  const [faqs, setFaqs] = useState<any>([]);
  const [error, setError] = useState<any>(null);
  const { user } = useSelector((s) => s.auth);
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const getFaqs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await findAllFaq(
        user?.roles?.includes("teacher") ? "teacher" : "student"
      );
      if (response.statusCode === StatusCode.OK) {
        setFaqs(response.data);
      }
    } catch (error) {
      message.error(badRequest.error);
      setError(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    getFaqs();
  }, []);

  return (
    <AnimatedPage>
      <Stylo />
      <Breadcrumbs />
      <AppScreen>
        <H1 className={s.title}>FAQ</H1>

        <div className={s.main}>
          <div className={s.collaps}>
            <Collapse
              // defaultActiveKey={["1"]}
              onChange={callback}
              expandIconPosition={"right"}
              ghost
            >
              {faqs &&
                faqs?.map((e: any) => (
                  <Panel
                    header={<h3>{e?.title}</h3>}
                    key={e?._id}
                    extra={genExtra()}
                    className={s.__header__panel}
                  >
                    <div className={s.panel}>
                      <p>{e?.description}</p>
                    </div>
                  </Panel>
                ))}
            </Collapse>
          </div>
        </div>
      </AppScreen>
    </AnimatedPage>
  );
};

export default FAQ;
