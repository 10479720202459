import { FC } from "react";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

//!STYLE
import s from "./assets/cardResult.module.css";

interface Props {
  res: any;
}
const CardResult: FC<Props> = ({ res }) => {
  return (
    <div className={s.body}>
      <h4 className={s.question}>
        <Latex>
          {res?.question?.title
            ? res?.question?.title
                ?.replaceAll("amp;", "")
                ?.replaceAll("<br />", "")
            : ""}
        </Latex>
      </h4>
      <div
        className={`${s.cardResult} ${
          res?.answer?.isCorrect ? `${s.isCorrect}` : `${s.isNoCorrect}`
        }`}
      >
        <span>
          <Latex>
            {res?.answer?.title
              ? res?.answer?.title
                  ?.replaceAll("amp;", "")
                  ?.replaceAll("<br />", "")
              : ""}
          </Latex>
        </span>
      </div>
    </div>
  );
};

export default CardResult;
