import * as appRoutes from "config/routes.config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ellipse_slide from "../../../../assets/images/app/organisme/ellipse_materials.svg";
import fusee from "../../../../assets/images/app/organisme/fusee.png";
import green_arrow from "../../../../assets/images/app/organisme/green_arrow.svg";
import students from "../../../../assets/images/app/organisme/students_slide.png";
import trace_instaprof from "../../../../assets/images/app/teachers/trace_instaprof.svg";
import trace_teachers_button from "../../../../assets/images/app/teachers/trace_teachers_button.svg";
import trace_teachers_title from "../../../../assets/images/app/teachers/trace_teachers_title.svg";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const SlideOrganisme = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(appRoutes.LOGINS);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.mainBox}>
                <div className={s.leftBox}>
                    <img src={trace_teachers_title} className={s.ellipseTitle}></img>
                    <h1 className={s.title}>Notre Approche Pédagogique :<br />Éduquer avec Innovation</h1>
                    {isMobile ? <p className={s.message}>Notre soutien scolaire innovant combine des<br />techniques d’apprentissage modernes et un<br />accompagnement personnalisé pour assurer<br />le succès académique de vos élèves.</p> : <p className={s.message}>Notre soutien scolaire innovant combine des techniques<br />d’apprentissage modernes et un accompagnement personnalisé<br />pour assurer le succès académique de vos élèves.</p>}
                    <button className={st.button} style={{ marginTop: isMobile ? "10px" : "40px" }} onClick={handleButtonClick}>
                        S’inscrire
                    </button>
                    {!isMobile && <img src={trace_teachers_button} style={{ height: "170px", position: "relative", left: "12%", bottom: "18px" }}></img>}
                    {isMobile && <img src={fusee} style={{ height: "47px", position: "relative", left: "62%", bottom: "60px" }}></img>}
                </div>
                <div style={{ width: "40%", display: "flex", flexDirection: "row" }}>
                    <img src={students} style={{ height: isMobile ? "340px" : "460px" }}></img>
                    <img src={trace_instaprof} className={st.floatAnimationOne} style={{ height: isMobile ? "70px" : "100px", position: "relative", left: isMobile ? "-47%" : "-8%", bottom: "0px" }}></img>
                    {!isMobile && <img src={fusee} style={{ height: "120px", position: "relative", left: "-160%", bottom: "-75%" }}></img>}
                    {!isMobile && <img src={ellipse_slide} style={{ height: "30px", position: "relative", left: "-125%", bottom: "-90%" }}></img>}
                    {!isMobile && <img src={green_arrow} style={{ height: "70px", position: "relative", left: "-80%", bottom: "-73%" }}></img>}
                </div>
            </div>
            {isMobile && <img src={trace_teachers_button} style={{ height: "80px", position: "relative", left: "49%", bottom: "0px" }}></img>}
        </div>
    );
};

export default SlideOrganisme;