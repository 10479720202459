import { Alert, Button, Collapse, Form, Input, Row, Select, Steps, message } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import * as appRoutes from "config/routes.config";
import { Dispatch, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, batch, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";

//!STYLES
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import AppSelectGroupInscription from "components/libs/selectGroupInscription";
import React from "react";
import { setIsRegistering } from "store/app/appSlice";
import { updateUser } from "store/auth/authSlice";
import s from "./assets/register.module.css";

const { Step } = Steps;
const { Panel } = Collapse;

type Props = {
    stepOneData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepTwo: FC<Props> = ({ stepOneData, setCurrent }) => {
    //!HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const [formInfos] = Form.useForm();
    const { user } = useSelector((state) => state.auth);

    const [dataList] = useState<any>([]);
    const [currentPanel, setCurrentPanel] = useState<any>(["1", "2"]);
    const [educationSystemList, setEducationSystem] = useState<any>([]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    //!STATES
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [studentCount, setStudentCount] = useState<number>(1);
    const [studentRegistrationCount, setStudentRegistrationCount] = useState<number>(1);
    const [students, setStudents] = useState<any>([]);
    const [registredStudents, setRegistredStudents] = useState<any>([]);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    ///Student Div
    const [divs, setDivs] = useState([{ index: studentCount }]);
    const [registrationDivs, setRegistrationDivs] = useState([{ index: studentRegistrationCount }]);

    ///Add New Student
    const handleAddDiv = (index: number) => {
        const newDivs = [...divs, { index: index }];
        setDivs(newDivs);
    };

    const handleAddRegistrationDiv = (index: number) => {
        const newRegistrationDivs = [...registrationDivs, { index: index }];
        setRegistrationDivs(newRegistrationDivs);
    };

    ///Remove Student
    const handleRemoveStudentDiv = (index: number) => {
        const newDivs = [...divs];
        newDivs.splice(index, 1);
        setDivs(newDivs);
        setStudentCount((studentCount) => studentCount - 1);
    };

    const handleRemoveStudentRegistrationDiv = (index: number) => {
        const newRegistrationDivs = [...registrationDivs];
        newRegistrationDivs.splice(index, 1);
        setRegistrationDivs(newRegistrationDivs);
        setStudentRegistrationCount((studentRegistrationCount) => studentRegistrationCount - 1);
    };

    const addNewStudent = (index: number) => {
        setStudentCount((studentCount: number) => studentCount + 1);
        handleAddDiv(index);
    }

    const registerNewStudent = (index: number) => {
        setStudentRegistrationCount((studentRegistrationCount: number) => studentRegistrationCount + 1);
        handleAddRegistrationDiv(index);
    }

    const inviteStudent = async (values: any) => {
        if (studentCount !== 0) {
            for (let i = 0; i < studentCount; i++) {

                setIsLoading(true);

                var student = {};

                for (let i = 0; i < studentCount; i++) {

                    const firstname = `firstname${i}`;
                    const lastname = `lastname${i}`;
                    const email = `email${i}`;

                    student = {
                        "firstname": values[firstname],
                        "lastname": values[lastname],
                        "email": values[email],
                    }
                    students.push(student);

                }
            }
            addStudent(students, values);
        }
        else {
            registerStudent(values);
        }
    }

    const registerStudent = async (values: any) => {
        setIsLoading(true);
        var student = {};
        for (let i = 0; i < studentRegistrationCount; i++) {

            const firstname = `registrationFirstname${i}`;
            const lastname = `registrationLastname${i}`;
            const educSystem = `educSystem${i}`;
            const levelId = `level${i}`;

            if (values[firstname] !== undefined && values[lastname] !== undefined && values[educSystem] !== undefined && values[levelId] !== undefined) {
                student = {
                    "firstname": values[firstname],
                    "lastname": values[lastname],
                    "email": values[firstname] + "." + values[lastname] + "." + stepOneData.lastname + "@monamialbert.com",
                    "pseudo": values[firstname] + "." + values[lastname] + "." + stepOneData.lastname,
                    "password": "123456",
                    "pays": stepOneData.countries,
                    "educSystem": values[educSystem],
                    "levelId": values[levelId],
                    "accountManaged": user?._id,
                    "organisationName": stepOneData.lastname + " " + stepOneData.firstname,
                };
                registredStudents.push(student);
            }
        }
        addRegisteredStudent();
    }

    const addStudent = async (students: any, values: any) => {
        try {
            const response = await AUTH_SERVICES.inviteStudents(user?._id, students);
            if (response?.statusCode === StatusCode.CREATED) {
                message.success({
                    content: "Invitations envoyées ",
                });
                registerStudent(values);
            }
            else {
                message.warning({
                    content: response?.data?.message,
                });
                setIsLoading(false);
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    const addRegisteredStudent = async () => {
        if (registredStudents.length >= 1) {
            for (let i = 0; i < registredStudents.length; i++) {
                try {
                    const response = await AUTH_SERVICES.registerSponsoredStudentWithEmailPassword(registredStudents[i]);
                    if (response?.statusCode === StatusCode.CREATED) {
                        message.success({
                            content: "Inscriptions effectuées ",
                        });
                    }
                    else {
                        message.warning({
                            content: response?.data?.message,
                        });
                        setIsLoading(false);
                    }
                } catch {
                    message.warning({
                        content: "Erreur lors de l'inscription ",
                    });
                    setIsLoading(false);
                }
            }
            setIsLoading(false);
            await dispatch(setIsRegistering(false));
            await AUTH_SERVICES.reauthenticateParentOrganisation(user?.email, stepOneData.password);
            const finalUser = await AUTH_SERVICES.getUserByUid(user.uid);
            if (finalUser?.data) {
                batch(() => {
                    dispatch(updateUser(finalUser.data));
                });
            }
            navigate(appRoutes.PARENT_DASHBOARD);
        }
        else {
            setIsLoading(false);
            await dispatch(setIsRegistering(false));
            await AUTH_SERVICES.reauthenticateParentOrganisation(user?.email, stepOneData.password);
            const finalUser = await AUTH_SERVICES.getUserByUid(user.uid);
            if (finalUser?.data) {
                batch(() => {
                    dispatch(updateUser(finalUser.data));
                });
            }
            navigate(appRoutes.PARENT_DASHBOARD);
        }
    }

    const inviteLater = async () => {
        dispatch(setIsRegistering(false));
    }

    const getStaticLists = useCallback(
        async (type: string, dataList: any, state: Dispatch<any>) => {
            const response = await TEACHER_SERVICES.getDataList(type);
            if (response?.statusCode === StatusCode.OK) {
                state(response?.data);
            }
        },
        [dataList]
    );

    //!EFFECTS
    useEffect(() => {
        getStaticLists('educSystem', educationSystemList, setEducationSystem);
    }, [stepOneData]);

    return (
        <div style={{ alignItems: "flex-start", margin: "0px auto" }}>
            <Form
                validateTrigger={["onFinish"]}
                validateMessages={validateMessages}
                name="registerParent"
                autoComplete="off"
                form={formInfos}
                onFinish={inviteStudent}
            >
                <div>
                    <Collapse defaultActiveKey={['1', '2']} activeKey={currentPanel} onChange={(activeKey) => setCurrentPanel(activeKey)} style={{ borderRadius: "0px" }} expandIconPosition="end">
                        <Panel header={<div style={{ padding: "10px", fontWeight: "600" }}>Inviter mes élèves</div>} key="1">
                            {divs.map((div, index) => (
                                <div key={div.index} className={isMobile ? s.invitationFormMobile : s.invitationForm}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Form.Item
                                            name={`lastname${index}`}
                                            rules={[{ required: false }]}
                                            style={{ marginRight: isMobile ? "5px" : "20px" }}
                                        >
                                            <Input
                                                className={s.field}
                                                placeholder={t("auth.lastname")}
                                                maxLength={30}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name={`firstname${index}`}
                                            rules={[{ required: false }]}
                                            style={{ marginRight: isMobile ? "0px" : "20px" }}
                                        >
                                            <Input
                                                className={s.field}
                                                placeholder={t("auth.firstname")}
                                                maxLength={30}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div>
                                        <Form.Item
                                            name={`email${index}`}
                                            rules={[{ required: false }, { type: "email" }]}
                                            style={{ marginRight: isMobile ? "0px" : "20px" }}
                                        >
                                            <Input
                                                type="email"
                                                className={s.field}
                                                placeholder={t("auth.email")}
                                            />
                                        </Form.Item>
                                    </div>

                                    <div>
                                        {studentCount !== 0 && studentCount !== 1 && <Button style={{ position: "relative", bottom: "12.5px", borderRadius: "50px", color: "white", backgroundColor: "red" }} onClick={() => handleRemoveStudentDiv(index)}>Retirer</Button>}
                                        {index === studentCount - 1 && <Button style={{ position: "relative", bottom: "12.5px", borderRadius: "50px", color: "white", backgroundColor: "#80B1B2" }} onClick={() => addNewStudent(studentCount)}>Ajouter</Button>}
                                    </div>

                                </div>
                            ))}
                        </Panel>
                        <Panel header={<div style={{ padding: "10px", fontWeight: "600" }}>Inscire mes élèves</div>} key="2">
                            {registrationDivs.map((div, index) => (
                                <div key={div.index} className={isMobile ? s.invitationFormMobile : s.invitationForm}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <Form.Item
                                            name={`registrationLastname${index}`}
                                            rules={[{ required: false }]}
                                            style={{ marginRight: isMobile ? "5px" : "10px" }}
                                        >
                                            <Input
                                                className={s.field}
                                                placeholder={t("auth.lastname")}
                                                maxLength={30}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name={`registrationFirstname${index}`}
                                            rules={[{ required: false }]}
                                            style={{ marginRight: isMobile ? "0px" : "10px" }}
                                        >
                                            <Input
                                                className={s.field}
                                                placeholder={t("auth.firstname")}
                                                maxLength={30}
                                            />
                                        </Form.Item>

                                        <Form.Item
                                            name={`level${index}`}
                                            rules={[{ required: false }]}
                                            style={{ marginRight: isMobile ? "0px" : "10px", paddingTop: "5px" }}
                                            className={s.field}
                                        >
                                            <AppSelectGroupInscription
                                                placeholder="Classe"
                                            />
                                        </Form.Item>
                                    </div>

                                    <div style={{ width: "24%" }}>
                                        <Form.Item
                                            name={`educSystem${index}`}
                                            rules={[{ required: false }]}
                                            style={{ marginRight: isMobile ? "0px" : "10px" }}
                                        >
                                            <Select
                                                style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                                className={s.field}
                                                placeholder={"Système d'éducation"}
                                                bordered={false}
                                                allowClear
                                                dropdownStyle={{ padding: "3px" }}
                                                showArrow={true}
                                            >
                                                {educationSystemList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                    </div>

                                    <div>
                                        {studentRegistrationCount !== 0 && studentRegistrationCount !== 1 && <Button loading={isLoading} style={{ position: "relative", bottom: "12.5px", borderRadius: "50px", color: "white", backgroundColor: "red", marginRight: "5px" }} onClick={() => handleRemoveStudentRegistrationDiv(index)}>Retirer</Button>}
                                        {index === studentRegistrationCount - 1 && <Button loading={isLoading} style={{ position: "relative", bottom: "12.5px", borderRadius: "50px", color: "white", backgroundColor: "#80B1B2" }} onClick={() => registerNewStudent(studentRegistrationCount)}>Ajouter</Button>}
                                    </div>

                                </div>
                            ))}
                        </Panel>
                    </Collapse>
                </div>

                <Row justify="center" style={{ marginTop: "30px" }}>
                    <Form.Item>
                        <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={s.nextButton}>Confirmer</a></AppButton>
                    </Form.Item>
                </Row>
            </Form>

            {auth.registerErrors.length > 0 &&
                auth.registerErrors.map((error: any, index: any) => (
                    <Alert key={index} message={error} type="error" showIcon />
                ))}

            <div
                className={s.text}
                style={{ marginTop: "1em", textAlign: "center" }}
            >
                <p style={{ fontSize: "14px" }}>
                    <Link to={appRoutes.PARENT_DASHBOARD} onClick={() => inviteLater()}>Passer cette étape</Link>
                </p>
            </div>
        </div>
    );
};

export default StepTwo;
