import { Avatar, Col, Row, Space } from "antd";
import ConversationIcon from "assets/images/icons/conversation.svg";
import DisLikeIcon from "assets/images/icons/dislike.svg";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";
import LikeIcon from "assets/images/icons/like.svg";
import ShareIcon from "assets/images/icons/share.svg";
import AvatarTeacher from "assets/images/teacher/teacher.svg";
import AppLatex from "components/appLatex";
import moment from "moment";
import { FC, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useGetPostsQuery,
  usePostDisLikeMutation,
  usePostLikeMutation,
} from "store/services/teacher/activities.services";
import Comments from "./comments";
import s from "./style.module.css";
import ArticleComments from "./articleComment";
import SharingModule from "../sharing/sharing";
import { useLocation } from "react-router-dom";
import SharedArticleComments from "./sharedArticleComment";

interface Props {
  info: any;
}

const CardActivities: FC<Props> = ({ info }) => {
  const { socket } = useSelector((state) => state.appChat);
  const [postLike, { isSuccess }] = usePostLikeMutation();
  const [postDisLike, { data }] = usePostDisLikeMutation();
  const { user } = useSelector((state) => state.auth);
  const { refetch } = useGetPostsQuery(user?._id);
  const [like, setLike] = useState<number>(0);
  const [articleId, setArticleId] = useState<string>();
  const [sharedPostId, setSharedPostId] = useState<string>();

  const [open, setOpen] = useState<boolean>(false);
  const [openArticle, setOpenArticle] = useState<boolean>(false);
  const [openSharedArticle, setOpenSharedArticle] = useState<boolean>(false);
  const location = useLocation();

  const [action, setAction] = useState<"get" | "post">("get");
  const [actionArticle, setActionArticle] = useState<"get" | "post">("get");
  const [actionSharedArticle, setActionSharedArticle] = useState<"get" | "post">("get");

  const showDrawer = () => {
    setOpen(true);
  };

  const showArticleDrawer = () => {
    setOpenArticle(true);
  };

  function convertToKiloOctets(bytes: number): number {
    const KILO: number = 1024;
    const kiloOctets: number = bytes / KILO;
    return Math.round(kiloOctets);
  }

  const handleShare = (article: any) => {
    setArticleId(article?._id);
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (id) {
      setSharedPostId(id);
      setActionSharedArticle("get");
      setOpenSharedArticle(true);
    }
  }, [location.search]);

  return (
    <>
      <div className={s.card}>
        <div className={s.container}>
          <div className={s.header}>
            <div className={s.user__infos}>
              <Space>
                <div className="">
                  <Avatar
                    src={info?.profId?.profile || AvatarTeacher}
                    size={44}
                    style={{
                      background: "#fff",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                    }}
                  />
                </div>
                <div>
                  <h3>
                    {info?.profId?.firstname?.split(" ")[0]}{" "}
                    {info?.profId?.lastname?.split(" ")[0]}
                  </h3>
                  <span>{moment(info?.createdAt).fromNow()}</span>
                </div>
              </Space>
            </div>

            {/* <div className="right">
            <Matiers />
          </div> */}
          </div>

          <div className={s.body} style={{}} onClick={() => {
            setActionArticle("get");
            showArticleDrawer();
            console.log(info);
          }}>

            <AppLatex content={info?.content?.length > 500 ? info?.content?.substring(0, 500) + "..." : info?.content} />

            {info?.links?.find((img: any) => img.type?.includes("image"))
              ?.url && (
                <div>
                  <img
                    src={
                      info?.links?.find((img: any) => img.type?.includes("image"))
                        ?.url
                    }
                    style={{ maxHeight: "370px", objectFit: "contain" }}
                    alt=""
                  />
                </div>
              )}

            {info?.links?.find((img: any) => img.type?.includes("video"))
              ?.url && (
                <div>
                  <video key={info._id} className={s.body} controls style={{ maxHeight: "370px", objectFit: "contain" }}>
                    <source src={info?.links?.find((img: any) => img.type?.includes("video"))
                      ?.url}
                    />
                  </video>
                </div>
              )}

            {info?.links
              ?.reduce((acc: any, curr: any) => {
                if (curr?.type === "application/pdf") {
                  acc?.push(curr);
                }

                return acc;
              }, [])
              .map((acc: any) => (
                <Row gutter={24} style={{ marginBottom: "1em" }}>
                  <Col>
                    <Space>
                      <div>
                        <a href={acc?.url} target="_blank">
                          <DownloadIcon width={28} />
                        </a>
                      </div>
                      <div>
                        <a href={acc?.url} target="_blank">
                          <h4>Télécharger le PDF</h4>
                        </a>
                        <span>
                          Format PDF | {convertToKiloOctets(acc?.size)}ko
                        </span>
                      </div>
                    </Space>
                  </Col>
                </Row>
              ))}
          </div>

          <div className={s.footer} style={{ width: "100%" }}>
            <Row gutter={12} className={s.buttonRow}>
              <Col>
                <button
                  className={s.button}
                  onClick={async () => {
                    const body = {
                      postId: info?._id,
                      userId: user?._id,
                    };
                    if (info?.liked) {
                      await postDisLike(body);
                    } else {
                      await postLike(body);
                      // socket.emit("newPostLike", body);
                    }
                    refetch();
                  }}
                >
                  <img
                    src={info?.liked ? LikeIcon : DisLikeIcon}
                    alt=""
                    width={13}
                    style={{ position: "relative", top: "-2px" }}
                  />{" "}
                  {info?.likesCount} | J’aime
                </button>
              </Col>
              <Col>
                <button className={s.button} onClick={() => {
                  setAction("get");
                  showDrawer();
                }}>
                  {" "}
                  <img
                    src={ConversationIcon}
                    alt=""
                    width={13}
                    style={{ position: "relative", top: "-1px" }}
                    onClick={() => {
                      setAction("get");
                      showDrawer();
                    }}
                  />{" "}
                  <span
                    onClick={() => {
                      setAction("get");
                      showDrawer();
                    }}
                  >
                    {info?.commentsCount}
                  </span>{" "}
                  |{" "}
                  <span
                    onClick={() => {
                      setAction("post");
                      showDrawer();
                    }}
                  >
                    Commenter
                  </span>
                </button>
              </Col>
              <Col>
                <button className={s.button} onClick={() => {
                  handleShare(info);
                }}>
                  {" "}
                  <img
                    src={ShareIcon}
                    alt=""
                    width={13}
                    style={{ position: "relative", top: "-2px" }}
                  />{" "}
                  Partager
                </button>
              </Col>
            </Row>
            {articleId == info?._id ? <SharingModule info={info} /> : <div></div>}
          </div>
        </div>
      </div>
      <Comments
        open={open}
        setOpen={setOpen}
        action={action}
        postId={info?._id}
      />
      <ArticleComments
        action={actionArticle}
        openArticle={openArticle}
        postId={info?._id}
        setOpenArticle={setOpenArticle}
        info={info}
      />
      {sharedPostId === info?._id && <SharedArticleComments
        actionShared={actionSharedArticle}
        openSharedArticle={openSharedArticle}
        sharedPostId={sharedPostId}
        setOpenSharedArticle={setOpenSharedArticle}
        info={info}
      />}
    </>
  );
};

export default CardActivities;
