import React, { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './style.module.css';

interface FadeInOnScrollProps {
    children: ReactNode;
}

const FadeInOnScroll: React.FC<FadeInOnScrollProps> = ({ children }) => {

    const [isVisible, setIsVisible] = useState(false);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const topShown = rect.top >= 0 && rect.top <= window.innerHeight;
                const bottomShown = rect.bottom >= 0 && rect.bottom <= window.innerHeight;

                if (topShown || bottomShown) {
                    setIsVisible(true);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div
            ref={elementRef}
            className={isVisible ? styles.fadeIn : ''}
            style={{ textAlign: 'center' }}
        >
            {children}
        </div>
    );
};

export default FadeInOnScroll;
