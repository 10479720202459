import { Button, Col, Drawer, Form, Row, Select, message } from "antd";
import React, { useCallback, useRef, useState } from "react";

//!STYLE
import { AppNotification } from "components/appNotification";
import AppButton from "components/libs/button";
import { useSelector } from "react-redux";
import {
  filterHoursByCurrentTime,
  handleClearNiveauMatUser,
  hours,
} from "utils/formats";
import s from "./style.module.css";

type Props = {
  setAllData: React.Dispatch<React.SetStateAction<any[]>>;
  setOpenDrawerAdsCreneau: React.Dispatch<React.SetStateAction<boolean>>;
  showDrawerDrawerAdsCreneau: (key?: string) => void;
  openDrawerAdsCreneau: boolean;
  keyCreneau: string | undefined;
  allData: any;
};

const format = "HH:mm";
const { Option } = Select;

const DrawerAdsCreneau: React.FC<Props> = ({
  showDrawerDrawerAdsCreneau,
  openDrawerAdsCreneau,
  setAllData,
  keyCreneau,
  allData,
  setOpenDrawerAdsCreneau,
}) => {
  //!HOOKS
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.auth);

  //!STATES
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [topicSelected, setTopicSelected] = useState<any>([]);
  const [tempLevel, setTempLevel] = useState<any>([]);
  const closeDrawerRef = useRef<any>();

  const onSelectLevel = useCallback(async (levelId: string) => {
    return user?.nivMat
      .filter((nivMat: any) => {
        return nivMat?.niveau?._id === levelId;
      })
      .forEach((matiere: any) => {
        return setTempTopic(matiere?.matieres);
      });
  }, []);

  const onFinish = (values: any) => {
    if (
      (values.one === "00:00" && values.two === "23:00") ||
      (values.one === "23:00" && values.two === "00:00")
    ) {
      // Créneau valide
    } else if (
      Number(values.one.split(":")[0]) >= Number(values.two.split(":")[0])
    ) {
      AppNotification("L'horaire du créneau n'est pas valide.", "AlbertSad");
      throw new Error("Invalid creneau");
    } else {
      // Créneau valide
    }

    // if (Number(values.one.split(":")[0]) >= Number(values.two.split(":")[0])) {
    //   AppNotification("L'horaire du créneau n'est pas valide.", "AlbertSad");
    //   throw new Error("Invalid creneau");
    // }

    const valuesData = {
      levels: tempLevel.slice(-1),
      topics: topicSelected.filter((t: any) => t.value === values.matiere),
      date: keyCreneau,
      range: [{ hour: values.one }, { hour: values.two }],
      type: values.type,
    };

    setAllData((prev: any) => {
      return [...prev, valuesData];
    });

    message.success("Créneau ajouté avec succès");
    form.resetFields();
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      title={
        <div className={s.__drawer__header}>
          <h3>Ajouter un créneau</h3>
          <Button type="primary">{keyCreneau}</Button>
        </div>
      }
      headerStyle={{ border: "none" }}
      placement="right"
      width={isMobile ? "100%" : "50%"}
      onClose={() => setOpenDrawerAdsCreneau(false)}
      visible={openDrawerAdsCreneau}
    >
      <Form onFinish={onFinish} form={form}>
        <div className={s.hoursBox}
        >
          <Col>
            <h3 className={s.horaire}>Horaire</h3>
          </Col>
          <Col>
            <div className={s.time__picker}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"one"}
              >
                <Select
                  placeholder="Heures"
                  style={{ width: 120 }}
                  bordered={false}
                  options={filterHoursByCurrentTime(hours).map((hour) => {
                    return {
                      value: hour.value,
                      label: hour.label,
                    };
                  })}
                />
              </Form.Item>
            </div>
          </Col>
          <Col>
            <h3 style={{ color: "var(--first-color)" }}>à</h3>
          </Col>
          <Col>
            <div className={s.time__picker}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                name={["two"]}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
              >
                <Select
                  placeholder="Heures"
                  style={{ width: 120 }}
                  bordered={false}
                  options={filterHoursByCurrentTime(hours).map((hour) => {
                    return {
                      value: hour.value,
                      label: hour.label,
                    };
                  })}
                />
              </Form.Item>
            </div>
          </Col>
        </div>

        <div className={s.full__content}>
          <div className={s.field__content}>
            <div className={s.field}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"levelIds"}
              >
                <Select
                  placeholder="Choisir un niveau"
                  style={{ width: "100%" }}
                  className={s.select}
                  bordered={false}
                  onChange={(value: string, fullValues: object) => {
                    onSelectLevel(value);
                    setTempLevel((prev: any) => {
                      return [
                        ...prev,
                        {
                          ...fullValues,
                        },
                      ];
                    });
                  }}
                  options={handleClearNiveauMatUser(user?.nivMat)?.map(
                    (item: any) => {
                      return {
                        label: item?.niveau?.levelGroup,
                        options: [
                          {
                            label: item?.niveau?.title,
                            value: item?.niveau?._id,
                          },
                        ],
                      };
                    }
                  )}
                />
              </Form.Item>
            </div>

            <div className={s.field}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"matiere"}
              >
                <Select
                  className={s.select}
                  placeholder="Choisir une matiere"
                  style={{ width: "100%" }}
                  bordered={false}
                  allowClear
                  onChange={(_: string, fullValues: object) => {
                    setTopicSelected((prev: any) => {
                      return [
                        ...prev,
                        {
                          ...fullValues,
                        },
                      ];
                    });
                  }}
                >
                  {tempTopic?.map((topic: any) => (
                    <Option
                      key={topic?.matiere?._id}
                      value={topic?.matiere?._id}
                    >
                      {topic?.matiere?.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={s.field}>
              <Form.Item
                style={{ margin: 0, padding: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Ce champ est requis",
                  },
                ]}
                name={"type"}
              >
                <Select
                  className={s.select}
                  placeholder="Choisir un type de créneau"
                  style={{ width: "100%" }}
                  bordered={false}
                  allowClear
                >
                  <Option value={"individuel"}>Individuel</Option>

                  <Option value={"collectif"}>Collectif</Option>
                </Select>
              </Form.Item>
            </div>
          </div>
        </div>

        <Row justify="center">
          <Form.Item style={{ margin: 0, padding: 0 }}>
            <AppButton htmlTypeSubmit={true}>Valider</AppButton>
          </Form.Item>
        </Row>
      </Form>

      <button
        hidden
        onClick={() => showDrawerDrawerAdsCreneau()}
        ref={closeDrawerRef}
      ></button>
    </Drawer>
  );
};

export default DrawerAdsCreneau;
