import FaqCollapse from "components/collapse";
import FadeInUpOnScroll from "components/FadeInUp";
import { useState } from "react";
import ellipse_mission from "../../assets/images/app/home/ellipse_mission.svg";
import ellipse_testimony from "../../assets/images/app/home/ellipse_testimony_one.svg";
import molecule_science from "../../assets/images/app/molecule_science.png";
import s from "./style.module.css";

const Faqs = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.faqContainer}>
                <div className={s.mainBox}>
                    <FadeInUpOnScroll>
                        <h1 className={s.title}>FAQs</h1>
                    </FadeInUpOnScroll>
                    <img src={ellipse_mission} className={s.ellipseTitle}></img>
                    {!isMobile && <img src={ellipse_testimony} style={{ position: "relative", left: "-30%", width: "15px", bottom: "25px", zIndex: "0" }}></img>}
                    <div className={s.faqMainBox}>
                        <FaqCollapse type="half" title={"Comment puis-je devenir enseignant sur<br />Mon Ami Albert ?"} content={"Rejoindre la communauté de ‘Mon Ami Albert’ a été l’une des meilleures décisions de ma carrière. Non seulement je bénéficie d’une grande autonomie dans la gestion de mes cours, mais je suis également récompensé pour ma contribution à la plateforme. Créer des exercices et des quiz et voir les élèves progresser grâce à cela est extrêmement satisfaisant. Je recommande vivement cette plateforme à tous les enseignants passionnés par l’éducation et l’innovation pédagogique."} background="#FFF8F2" />
                        <FaqCollapse type="half" title={"Quels sont les critères pour fixer<br />mes tarifs ?"} content={"Rejoindre la communauté de ‘Mon Ami Albert’ a été l’une des meilleures décisions de ma carrière. Non seulement je bénéficie d’une grande autonomie dans la gestion de mes cours, mais je suis également récompensé pour ma contribution à la plateforme. Créer des exercices et des quiz et voir les élèves progresser grâce à cela est extrêmement satisfaisant. Je recommande vivement cette plateforme à tous les enseignants passionnés par l’éducation et l’innovation pédagogique."} background="#FFD8CD" />
                    </div>
                    <div className={s.faqMainBox}>
                        <FaqCollapse type="half" title={"Comment fonctionne la fonctionnalité<br />InstaProf ?"} content={"Rejoindre la communauté de ‘Mon Ami Albert’ a été l’une des meilleures décisions de ma carrière. Non seulement je bénéficie d’une grande autonomie dans la gestion de mes cours, mais je suis également récompensé pour ma contribution à la plateforme. Créer des exercices et des quiz et voir les élèves progresser grâce à cela est extrêmement satisfaisant. Je recommande vivement cette plateforme à tous les enseignants passionnés par l’éducation et l’innovation pédagogique."} background="#DAE6E7" />
                        <FaqCollapse type="half" title={"Comment suis-je payé pour mes services ?"} content={"Rejoindre la communauté de ‘Mon Ami Albert’ a été l’une des meilleures décisions de ma carrière. Non seulement je bénéficie d’une grande autonomie dans la gestion de mes cours, mais je suis également récompensé pour ma contribution à la plateforme. Créer des exercices et des quiz et voir les élèves progresser grâce à cela est extrêmement satisfaisant. Je recommande vivement cette plateforme à tous les enseignants passionnés par l’éducation et l’innovation pédagogique."} background="#FAE7CD" />
                    </div>
                    <div className={s.faqMainBox}>
                        <FaqCollapse type="full" title={"Mon Ami Albert propose-t-il des ressources<br />pour les enseignants ?"} content={"Rejoindre la communauté de ‘Mon Ami Albert’ a été l’une des meilleures décisions de ma carrière. Non seulement je bénéficie d’une grande autonomie dans la gestion de mes cours, mais je suis également récompensé pour ma contribution à la plateforme. Créer des exercices et des quiz et voir les élèves progresser grâce à cela est extrêmement satisfaisant. Je recommande vivement cette plateforme à tous les enseignants passionnés par l’éducation et l’innovation pédagogique."} background="#F5DDFF" />
                        {!isMobile && <img src={molecule_science} style={{ height: "128px" }}></img>}
                    </div>
                </div>
                {!isMobile && <a className={s.quote}>“</a>}
            </div>
        </div>
    );
};

export default Faqs;