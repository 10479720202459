import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// !SERVICES
import * as USERS_SERVICES from "api/services/auth/auth.services";

// !STYLES
import s from "./custom.tab.module.css";

// !ANTD
import { message, Popconfirm, Table, Tabs } from "antd";
import AppButton from "components/libs/button";

// !ENUMS
import { converterMoneyCommission, StatusCode } from "common/enums";

// !STORE
import { setBookedReservations } from "store/auth/authSlice";

// !FORMATERS
import { dateFormatter } from "components/libs/readyToUse/challengerClasse/utils/Formatters";
import Accordion from "../accordion";
import {
  MESSAGE_CONFIRMATION,
  MESSAGE_CONFIRMATION_2,
  StyledTabs,
} from "./constants/StylizedCustomTab";

// !COMPONENTS
// import Accordion from "../accordion";

// CONSTS

const { TabPane } = Tabs;

// COLUMNS
const columns = [
  {
    title: "Cours",
    dataIndex: "title",
  },
  {
    title: "Élève",
    dataIndex: "studentId",
    render: (e: any) => (
      <div>
        {e?.firstname} {e?.lastname}
      </div>
    ),
  },
  {
    title: "Date",
    dataIndex: "createdAt",
    render: (e: string) => dateFormatter(new Date(e), "/"),
  },
  {
    title: "Tarifs",
    dataIndex: "price",
    render: (e: string) => (
      <div style={{ color: "#80B1B2", fontWeight: "600" }}>{e}€</div>
    ),
  },
];

interface CustomTabInterface {
  alreadyAsking4Money?: boolean;
}
export const CustomTab: React.FC<CustomTabInterface> = ({
  alreadyAsking4Money,
}) => {
  // !HOOKS
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [rib, setRIB] = useState<string>(auth.user?.rib || "");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const [first, setfirst] = useState(alreadyAsking4Money);
  const [westernUnion, setWesternUnion] = useState<string>(
    auth.user?.ribExt || ""
  );
  const [score, setScore] = useState(
    converterMoneyCommission(auth.user?.coins)
  );
  const { bookedReservations } = useSelector((state) => state.auth);
  const [lodaing, setLoading] = useState(false); // !FUNCTIONS
  const [lodaingRetrait, setLoadingRetrait] = useState(false); // !FUNCTIONS
  useEffect(() => {
    setfirst(alreadyAsking4Money);
  }, [alreadyAsking4Money]);

  const updateRIBHandler = (i: string) => setRIB(i);
  const updateWesternUnionHandler = (i: string) => setWesternUnion(i);
  const EnvoyerDemandeRetrait = useCallback(
    async (rib: string | undefined, westernUnion: string | undefined) => {
      try {
        setLoading(true);
        if (auth.user?._id) {
          const teacherId = auth.user?._id;
          await USERS_SERVICES.updateTeacher(teacherId, {
            rib: rib,
            ribExt: westernUnion,
          });
          setLoading(false);
          message.success({
            content: "Mise à jour avec succes ",
          });
        }
      } catch (error: any) {
        setLoading(false);
        console.log(error);
      }
    },
    [auth.user?._id]
  );
  const EnvoyerDemandeRetrait2 = useCallback(async (id: string) => {
    try {
      setLoadingRetrait(true);
      if (id) {
        const response = await USERS_SERVICES.createRetrait({
          teacherId: id,
        });
        setLoadingRetrait(false);
        if (response?.statusCode === StatusCode.CREATED) {
          message.success({
            content: "envoi avec succes",
          });
        }
      }
    } catch (error: any) {
      setLoadingRetrait(false);
      console.log(error);
    }
  }, []);

  function confirm() {
    if (rib !== "" && westernUnion === "")
      EnvoyerDemandeRetrait(rib, "");
    else if (rib === "" && westernUnion !== "")
      EnvoyerDemandeRetrait("", westernUnion);
    else {
      message.error({
        content: "Veuillez saisir votre RIB ou votre carte d’identité",
        style: {
          position: "absolute",
          right: "0",
        },
      });
    }
  }

  function confirm2() {
    setfirst(false);
    EnvoyerDemandeRetrait2(auth?.user?._id || "");
    /* if (rib === "" && westernUnion === "")
      message.error({
        content: "Veuillez saisir vos informations",
        style: {
          position: "absolute",
          right: "0",
        },
      }); */
    /* else {
      if (rib === "" && westernUnion !== "")
        message.error({
          content: "Veuillez saisir votre RIB",
          style: {
            position: "absolute",
            right: "0",
          },
        });
      else if (rib !== "" && westernUnion === "")
        message.error({
          content: "Veuillez saisir votre carte d’identité",
          style: {
            position: "absolute",
            right: "0",
          },
        });
      else {
        setfirst(false);
        EnvoyerDemandeRetrait2(auth?.user?._id || "");
      }
    } */
  }

  const getBookedReservations = useCallback(async () => {
    try {
      if (auth.user?._id && auth.user?.roles.includes("teacher")) {
        const teacherId = auth.user?._id;
        const response = await USERS_SERVICES.getBookedReservations(teacherId);
        if (response?.statusCode === StatusCode.OK) {
          dispatch(setBookedReservations(response?.data));
        }
      }
    } catch (error: any) {
      console.log(error);
    }
  }, [auth.user?._id, auth.user?.roles, dispatch]);
  useEffect(() => {
    getBookedReservations();
  }, [getBookedReservations]);

  return (
    <div className={s.cardContainer}>
      <StyledTabs>
        <Tabs type="card">
          <TabPane
            tab="INFORMATIONS DE PAIEMENT"
            key="1"
            className={isMobile ? s.main_arrangment_mibile : s.main_arrangment}
          >
            <div className={`${s.arrangment} ${s.monFormulaire}`}>
              <Accordion
                updateRIBHandler={updateRIBHandler}
                updateWesternUnionHandler={updateWesternUnionHandler}
                defaultValues={{ rib, westernUnion }}
              />
            </div>
            <div className={`${s.arrangment} ${isMobile ? s.retrait_mobile : s.retrait}`}>
              <div className={s.retraitLabel}>Solde du compte</div>
              <div className={s.retraitLabel} style={{ fontWeight: "700" }}>
                {score} EURO
              </div>
              {score !== null && score !== undefined && score > 0 && first ? (
                <Popconfirm
                  placement="topLeft"
                  title={MESSAGE_CONFIRMATION}
                  onConfirm={confirm2}
                  okText="Oui"
                  cancelText="Non"
                >
                  <AppButton
                    className={s.demandeRetraitButton}
                    loading={lodaingRetrait}
                  >
                    Envoyer une demande de retrait
                  </AppButton>
                </Popconfirm>
              ) : (
                <AppButton
                  className={s.demandeRetraitButton}
                  style={{
                    filter: "grayscale(1)",
                  }}
                >
                  Retrait en cours de traitement ...
                </AppButton>
              )}

              <Popconfirm
                placement="topLeft"
                title={MESSAGE_CONFIRMATION_2}
                onConfirm={confirm}
                okText="Oui"
                cancelText="Non"
              >
                <AppButton className={s.demandeRetraitButton} loading={lodaing}>
                  Mettre à jour tes informations
                </AppButton>
              </Popconfirm>
            </div>
          </TabPane>
          <TabPane tab="HISTORIQUE" key="2">
            <Table
              columns={columns}
              dataSource={bookedReservations}
              size="middle"
              pagination={{ pageSize: 15 }}
            />
          </TabPane>
        </Tabs>
      </StyledTabs>
    </div>
  );
};
