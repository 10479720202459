import { PlusOutlined, RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Skeleton,
  Tooltip,
  message,
} from "antd";
import { deleteCourse } from "api/services/chapters/chapters.services";
import { StatusCode, StatusEntity } from "common/enums";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import AppButton from "components/libs/button";
import Flex from "components/libs/flex";
import AppSelectGroup from "components/libs/selectGroup";
import H2 from "components/libs/subtitle";
import * as appRoutes from "config/routes.config";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useGetAllCoursesMutation } from "store/services/teacher/courses";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import { ReactComponent as SarchIcon } from "../../../assets/images/app/search.svg";
import AppScreen from "../ScreenWithSidebar";
import CardCourses from "../components/cardCourses";
import HeaderTabContent from "../components/headerTabContent";
import styles from "./assets/course.module.css";

const { Option } = Select;
function onChange(e: any) {
  console.log(`checked = ${e.target.checked}`);
}

const { Search } = Input;

const Courses: FC = () => {
  //!HOOKS
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth);
  const [values, setValues] = useState<any>({});
  const [currentPage, setcurrentPage] = useState<number>(1);
  const [getAllCourses, { data, isLoading, isSuccess, isError }] =
    useGetAllCoursesMutation();
  const [form] = Form.useForm();

  //!STATE
  const [tempTopic, setTempTopic] = useState<any>();
  const [isFiltering, setIsFiltering] = useState<boolean>(false);
  const [reload, setReload] = useState(false);
  const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);
  const submit = useRef<any>(null);

  const deleteCourseFunction = async (idCourse: string) => {
    try {
      const response = await deleteCourse(idCourse);

      if (response?.statusCode === StatusCode.OK) {
        message.success("Suppression a été bien effectuée");
      } else {
        message.warning("Suppression non effectuée");
      }
    } catch (e: any) {
      message.error("Une erreur a été survenue");
    }
  };

  const remove = (e: any) => {
    deleteCourseFunction(e);
    setReload(!reload);
  };

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setIsloadingTopic(true);
      try {
        const response = await TOPICS_SERVICES.findAll(levelId);
        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsloadingTopic(false);
      }
    },
    [tempTopic]
  );

  const filterCourses = useCallback(
    async (values: any) => {
      getAllCourses(values);
    },
    [values]
  );

  const onReset = () => {
    form.resetFields();
    filterCourses({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
    });
  };

  useEffect(() => {
    getAllCourses({
      ...values,
      page: currentPage,
    });
  }, [currentPage]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <AppScreen>
        <HeaderTabContent />
        <div className={styles.header}>
          <div className={styles.left}>
            <H2 className={styles.subtitle}>Cours</H2>
          </div>

          <div className={styles.center} />

          {!isMobile && <div className={styles.right}>
            <AppButton
              onClick={() => navigate(appRoutes.TEACHER_CREATE__COURS)}
              icon={<PlusOutlined />}
            >
              Créer un cours
            </AppButton>
          </div>}
        </div>

        <Form
          layout="inline"
          name="search"
          autoComplete="off"
          onFinish={filterCourses}
          form={form}
        >
          {isMobile ?
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: "15px auto" }}>
                <div style={{ width: "auto" }}>
                  <Form.Item name="self" valuePropName="checked">
                    <Checkbox onChange={() => submit.current.click()}>
                      Mes cours
                    </Checkbox>
                  </Form.Item>
                </div>

                <div className={styles.filed__search}>
                  <Form.Item name="title">
                    <Search
                      onChange={() => submit.current.click()}
                      placeholder="Tapez ici votre recherche"
                      size="large"
                      bordered={false}
                      enterButton={
                        <button type="submit">
                          <SarchIcon
                            style={{
                              position: "relative",
                              top: "3px",
                              left: "10px",
                            }}
                          />
                        </button>
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <div className={`${styles.select}`}>
                  <Form.Item name="levelId">
                    <AppSelectGroup
                      placeholder="Choisir un niveau"
                      style={{ width: "150px" }}
                      onChange={(value: string) => (
                        OnSelectLevel(value), submit.current.click()
                      )}
                    />
                  </Form.Item>
                </div>
                <div className={`${styles.select}`}>
                  <Form.Item name="topicId">
                    <Select
                      placeholder="Choisir une matiere"
                      allowClear
                      loading={isloadingTopic}
                      bordered={false}
                      onChange={() => submit.current.click()}
                    >
                      {tempTopic?.map((topic: any) => (
                        <Option key={topic._id} value={topic._id}>
                          {topic.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div style={{ height: "30px", width: "30px", margin: "0px auto" }}>
                <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
                  <Button
                    className={styles.__reset__btn}
                    onClick={onReset}
                    shape="circle"
                    htmlType="button"
                  >
                    <RedoOutlined />
                  </Button>
                </Tooltip>
              </div>

              <Button
                ref={submit}
                htmlType="submit"
                className={styles.__submit__hidden}
              ></Button>
            </div>
            :
            <div className={styles.filter}>
              <div className={styles.filter__left}>
                <Form.Item name="self" valuePropName="checked">
                  <Checkbox onChange={() => submit.current.click()}>
                    Mes cours
                  </Checkbox>
                </Form.Item>
              </div>

              <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
                <Button
                  className={styles.__reset__btn}
                  onClick={onReset}
                  shape="circle"
                  htmlType="button"
                >
                  <RedoOutlined />
                </Button>
              </Tooltip>

              <Row gutter={12}>
                <Col>
                  <div className={styles.filed__search}>
                    <Form.Item name="title">
                      <Search
                        onChange={() => submit.current.click()}
                        placeholder="Tapez ici votre recherche"
                        size="large"
                        bordered={false}
                        enterButton={
                          <button type="submit">
                            <SarchIcon
                              style={{
                                position: "relative",
                                top: "3px",
                                left: "10px",
                              }}
                            />
                          </button>
                        }
                      />
                    </Form.Item>
                  </div>
                </Col>
                <Col>
                  <div className={`${styles.select}`}>
                    <Form.Item name="levelId">
                      <AppSelectGroup
                        placeholder="Choisir un niveau"
                        style={{ width: "150px" }}
                        onChange={(value: string) => (
                          OnSelectLevel(value), submit.current.click()
                        )}
                      />
                    </Form.Item>
                  </div>
                </Col>

                <Col>
                  <div className={`${styles.select}`}>
                    <Form.Item name="topicId">
                      <Select
                        placeholder="Choisir une matière"
                        allowClear
                        loading={isloadingTopic}
                        bordered={false}
                        onChange={() => submit.current.click()}
                      >
                        {tempTopic?.map((topic: any) => (
                          <Option key={topic._id} value={topic._id}>
                            {topic.title}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Button
                ref={submit}
                htmlType="submit"
                className={styles.__submit__hidden}
              ></Button>
            </div>
          }

        </Form>

        <div>
          {isLoading || isFiltering ? (
            <>
              <Skeleton active />
              <Skeleton active />
              <Skeleton active />
            </>
          ) : (
            <>
              {data?.data?.result.length > 0 ? (
                <Fragment>
                  <Flex>
                    {data?.data?.result.map((course: any) => {
                      if (course?.status !== StatusEntity.REJECTED)
                        return (
                          <React.Fragment key={course?._id}>
                            <CardCourses
                              course={course}
                              user={user}
                              remove={remove}
                            />
                          </React.Fragment>
                        );
                    })}
                  </Flex>
                  <Row justify="center" style={{ marginTop: "2em" }}>
                    <Pagination
                      showSizeChanger={false}
                      onChange={(curr: number) => {
                        setcurrentPage(curr);
                      }}
                      defaultCurrent={currentPage}
                      total={data?.data?.count * 10}
                    />
                  </Row>
                </Fragment>
              ) : (
                <Fragment>
                  {!isMobile && <Row justify="center">
                    <Link to={appRoutes.TEACHER_CREATE__COURS}>
                      <AppButton icon={<PlusOutlined />}>
                        Créer un cours
                      </AppButton>
                    </Link>
                  </Row>}
                  <AppResult
                    status="info"
                    title={!isMobile ? "Ajoute ton premier cours depuis ton ordinateur portable !" : "Ajoute ton premier cours !"}
                  />
                </Fragment>
              )}
            </>
          )}
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default Courses;
