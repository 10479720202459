import { Avatar, Col, Row, Tag } from "antd";
import TEACHER from "assets/images/teacher/teacher.svg";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import Matiers from "components/matiers";
import { FC } from "react";
import { useSelector } from "react-redux";
import s from "../assets/calendar.module.css";

type Props = {
  cren: any;
  isHistorique: boolean;
};

const CardReservations: FC<Props> = ({ cren, isHistorique }) => {
  const { user } = useSelector((state) => state.auth);
  return (
    <div className={s.creneaux}>
      <div className={s.creneau}>
        <div className={s.card}>
          <div>
            <Row justify="space-between" align="middle" gutter={24}>
              <Col>
                <Avatar.Group
                  maxCount={2}
                  maxStyle={{
                    color: "#f56a00",
                    backgroundColor: "#fde3cf",
                  }}
                >
                  <Avatar
                    size={44}
                    style={{ backgroundColor: "#7fb1b2" }}
                    src={cren?.profId?.profile || TEACHER}
                  />
                </Avatar.Group>
              </Col>
              <Col>
                <H2>
                  {cren?.profId?.firstname} {cren?.profId?.lastname}
                </H2>
              </Col>
              <Col>
                <div className={s.range}>
                  <span>{cren?.range[0]}</span>
                </div>
              </Col>

              <Col>
                <span>50 min</span> <span className={s.bar}>|</span>{" "}
                <Tag color="default">{cren?.price} Euro</Tag>
              </Col>

              <Col>
                <div className={s.matiere}>
                  <Matiers
                    style={{ margin: 0 }}
                    cours={cren?.topic?.title}
                    level={cren?.level?.title}
                  />
                </div>
              </Col>
            </Row>
          </div>

          <div
            style={{
              marginLeft: "auto",
            }}
          >
            <div>
              <Row justify="end">
                <Col>
                  {/* {isHistorique ? (
                    <Tag icon={<CheckCircleOutlined />} color="success">
                      success
                    </Tag>
                  ) : (
                    <a href={cren?.studentLink} target="_blank">
                      <AppButton
                        style={{
                          maxWidth: "200px",
                          minWidth: "200px",
                        }}
                      >
                        Rejoindre le meet
                      </AppButton>
                    </a>
                  )} */}
                  {!isHistorique && <a href={cren?.studentLink} target="_blank">
                    <AppButton
                      style={{
                        maxWidth: "200px",
                        minWidth: "200px",
                      }}
                    >
                      Rejoindre le meet
                    </AppButton>
                  </a>}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardReservations;
