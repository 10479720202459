import { Avatar, Image } from "antd";
import React, { useState } from 'react';
import st from "./style.module.css";

import dollarCoins from "assets/images/app/dollar-coins.png";
import schoolPic from "assets/images/app/school.svg";
import starCoins from "assets/images/app/star-coins.png";

import belgiumFlag from "assets/images/app/belgium-flag.png";
import frenchFlag from "assets/images/app/french-flag.png";
import moroccoFlag from "assets/images/app/morocco-flag.png";

type Props = {
    userData: any,
    handleOpenPasswordDrawer?: () => void,
}

const StudentNavBar: React.FC<Props> = ({ userData, handleOpenPasswordDrawer }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={st.navbar}>
            {!isMobile && <div style={{ height: "auto", display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div className={st.avatar}>
                    <Avatar
                        style={{ backgroundColor: "#FFF" }}
                        size={35}
                        src={
                            <Image
                                fallback={`http://gravatar.com/avatar${userData?.uid}?d=identicon`}
                                preview={false}
                                src={
                                    !!userData?.profile
                                        ? userData?.profile
                                        : `https://gravatar.com/avatar/${userData?._id}?d=identicon`
                                }
                            />
                        }
                    >
                        {""}
                    </Avatar>
                </div>
                <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center", width: "132px" }}>
                    <span>{userData?.firstname + " " + userData?.lastname}</span>
                    <span onClick={handleOpenPasswordDrawer} style={{ fontSize: "12px", fontWeight: "600", cursor: "pointer" }}>Modifier mot de passe</span>
                </div>
            </div>}
            <div className={st.userNavInfoBox}>
                <div className={st.userNavBox}>
                    <div className={st.infoBox}>
                        <div className={st.info}>
                            <div className={st.left}><img src={dollarCoins} height={isMobile ? 10 : 15} className={st.leftText}></img> Solde</div>
                            <div className={st.right}>{userData?.coins}<span className={st.coinsInfo}>EUR</span></div>
                        </div>
                    </div>
                    <div className={st.infoBox}>
                        <div className={st.info}>
                            <div className={st.left}><img src={starCoins} height={isMobile ? 10 : 15} className={st.leftText}></img> Points</div>
                            <div className={st.right}>{userData?.points}<span className={st.coinsInfo}>PTS</span></div>
                        </div>
                    </div>
                </div>
                <div className={st.userNavBox}>
                    {userData?.rankEtab !== undefined && <div className={st.infoBoxHoverSchool}>
                        <div className={st.info}>
                            <div className={st.left}><img src={schoolPic} height={isMobile ? 10 : 15} className={st.leftText}></img> Ets</div>
                            <div className={st.right}>{userData?.rankEtab}<span className={st.classementInfo}>{userData?.rankEtab === 1 ? "er" : "e"}</span></div>
                        </div>
                    </div>}
                    {userData?.rankSystem !== undefined && <div className={st.infoBoxHoverNat}>
                        <div className={st.info}>
                            <div className={st.left}><img src={userData?.educSystem === "Système Belge" ? belgiumFlag : userData?.educSystem === "Système Français" ? frenchFlag : moroccoFlag} height={isMobile ? 10 : 15} className={st.leftText}></img></div>
                            <div className={st.right}>{userData?.rankSystem}<span className={st.classementInfo}>{userData?.rankSystem === 1 ? "er" : "e"}</span></div>
                        </div>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default StudentNavBar;