import {
    Form,
    message
} from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { FC, useEffect, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { updateUser } from "store/auth/authSlice";

//!STYLES
import AppButton from "components/libs/button";
import st from "./assets/register.module.css";

//!IMAGES
import { uploadToCloudinary } from "api/services/teacher/teacher.services";
import { StatusCode } from "common/enums";
import { useDispatch, useSelector } from "react-redux";
import checkPicture from '../../../assets/images/app/checkIcon.svg';
import CustomCheckBox from "../components/Checkbox";


type Props = {
    stepOneData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepTwo: FC<Props> = ({ stepOneData, setCurrent }) => {
    //!HOOKS
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [isPictureAdded, setIsPictureAdded] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<any>();
    const [imagePreview, setImagePreview] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dragActive, setDragActive] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [errorImage, setErrorImage] = useState<any>('');
    const [formInfos] = Form.useForm();

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    //!STATES JOFREY
    const [stepTwoData, setStepTwoData] = useState<any>({});

    //!Picture Advices
    const advicesList = [
        "Souriez et regardez l’objectif",
        "Assurez-vous que votre tête et vos épaules sont bien cadrées",
        "Votre photo doit être centrée et droite",
        "Posez la caméra sur une surface stable pour éviter que votre vidéo ne bouge",
        "Votre visage et vos yeux doivent être entièrement visibles (sauf pour des motifs religieux)",
        "Évitez les logos ou les coordonnées",
        "Vous devez être la seule personne sur la photo"
    ];


    ///Drag & Drop Functions
    function handleFile(files: any) {
        const file = files[0];
        const maxSize = 5 * 1024 * 1024;
        const reader = new FileReader();

        if (file && file.size <= maxSize && (file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png')) {
            setIsPictureAdded(true);
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };

            reader.onload = () => {
                updateTeacherPicture(file);
            };
            reader.readAsDataURL(file);
        }
        else {
            setIsLoading(false);
            setErrorImage('Fichier invalide. Veuillez ajouter une image JPEG ou PNG de taille maximum 5MB.');
        }
    }

    const updateTeacherPicture = async (file: any) => {
        try {
            const response = await uploadToCloudinary(file, "photoProfil");

            if (response?.statusCode === StatusCode.CREATED) {
                if (response?.data !== 'Face not detected') {
                    setImageUrl(response?.data);
                    setIsLoading(false);
                }
                else {
                    message.warning({
                        content: "Visage non detecté, merci de réessayer.",
                    });
                    setImagePreview('');
                    setIsPictureAdded(false);
                    faceDetection();
                }
            }
            else {
                message.warning({
                    content: "Une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    // handle drag events
    const handleDrag = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true);
        } else if (event.type === "dragleave") {
            setDragActive(false);
        }
    };

    // triggers when file is dropped
    const handleDrop = (event: any) => {
        event.preventDefault();
        event.stopPropagation();
        setDragActive(false);
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            handleFile(event.dataTransfer.files);
        }
    };

    // triggers when file is selected with click
    const handleChange = (e: any) => {
        e.preventDefault();
        if (e.target.files && e.target.files[0]) {
            setIsLoading(true);
            handleFile(e.target.files);
        }
    };

    // triggers the input when the button is clicked
    const onButtonClick = () => {
        if (inputRef.current != null) {
            inputRef.current.click();
        }
    };

    const updateUserInfos = async () => {
        setIsLoading(true);
        setStepTwoData(user);

        const finalData = {
            profile: imageUrl,
            step: user && user?.step === "completed" ? "completed" : "photo",
        };

        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                message.success({
                    content: "Modification effectuée ",
                });
                setImagePreview(response?.data);
            }
            else {
                message.warning({
                    content: "Une erreur est survenue ",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            dispatch(
                updateUser({
                    ...user,
                    profile: imageUrl,
                    step: user && user?.step === "completed" ? "completed" : "photo",
                })
            );
            setIsLoading(false);
            setCurrent(3);
        }
    };

    const moveToStepThree = async () => {
        window.scrollTo(0, 0);
        if (imagePreview === '') {
            setIsPictureAdded(false);
            setErrorImage("Veuillez ajouter une image de profil.");
        }
        else {
            updateUserInfos();
        }
    }

    const faceDetection = async () => {
        window.scrollTo(0, 0);
        if (imagePreview === '') {
            setIsPictureAdded(false);
            setErrorImage("Visage non detecté, merci de réessayer.");
        }
    }

    //!EFFECTS
    useEffect(() => {
        if (user) {
            console.log(user?.profile);
            if (user?.profile === undefined) {
                setImagePreview('');
                setIsPictureAdded(false);
            }
            else {
                setImagePreview(user?.profile);
                setIsPictureAdded(true);
                setImageUrl(user?.profile);
            }
        }
    }, [user]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div style={{ alignItems: "flex-start", margin: "0px auto" }}>
            <Form

                validateTrigger={["onFinish"]}
                name="registerTeacher"
                autoComplete="off"
                form={formInfos}
                validateMessages={validateMessages}
                onDragEnter={handleDrag}
                onFinish={moveToStepThree}
            >
                <div className={st.avatarContainer}>
                    <div className={st.uploadImageBox}>
                        <div className={st.uploadImageBoxTitle}>Fais tout de suite bonne impression!</div>
                        <div className={st.uploadImageBoxMessage}>Pour attirer plus d’élèves, choisis une photo sympathique et professionnelle.</div>
                        <div className={st.addPictureButton}>
                            <AppButton onClick={onButtonClick}><a className={st.uploadButtonStyle}>{imagePreview !== "" ? "CHANGER LA PHOTO" : "AJOUTER UNE PHOTO"}</a></AppButton>
                            <input name="userPicture" ref={inputRef} type="file" id={st.inputFileUpload} accept=".jpg,.jpeg,.png" multiple={false} onChange={handleChange} required={false} />
                        </div>
                        {isPictureAdded ? <div className={st.previewBox}><img src={imagePreview} alt="Uploaded Preview" className={st.previewBoxImage} /></div> :
                            <div id={st.formFileUpload} className={st.uploadBoxBorder} onClick={onButtonClick}>
                                <label id={st.labelFileUpload} htmlFor="inputFileUpload" className={dragActive ? st.dragActive : ""}>
                                    <div className={st.uploadBox}>
                                        <a className={st.uploadBoxTitle} style={{ color: errorImage ? "red" : "#333" }}>{errorImage ? errorImage : "Faites glisser votre photo ici"}</a>
                                        <a className={st.uploadBoxMessage}>Format JPG ou PNG Taille maximale 5 Mo</a>
                                    </div>
                                </label>
                                {dragActive && <div id={st.dragFileElement} onDragEnter={handleDrag} onDragLeave={handleDrag} onDragOver={handleDrag} onDrop={handleDrop}></div>}
                            </div>}
                    </div>

                    <div className={st.exampleBox}>
                        <div className={st.exampleBoxTitle}>Conseils pour une photo réussie</div>
                        <div className={st.avatarExampleBox}></div>
                        <div className={st.cardInfoBox}>
                            <div className={st.instructionBox}>
                                {advicesList.map((item, i) => (
                                    <div key={i} className={st.checkboxBox}>
                                        <CustomCheckBox type="check" icon={checkPicture} />
                                        <p className={st.checkboxBoxItem}>{item}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className={st.rowButtons}>
                    <div className={st.rowButtonChild}>
                        <AppButton onClick={() => setCurrent((n) => n - 1)}><a className={st.nextButton}>RETOUR</a></AppButton>
                    </div>
                    <div className={st.rowButtonChild}>
                        <Form.Item style={{ margin: 0, padding: 0 }}>
                            <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={st.nextButton}>SUIVANT</a></AppButton>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </div>
    );
};

export default StepTwo;
