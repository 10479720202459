import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input, message, Row,
  Select,
  Upload,
  UploadProps
} from "antd";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import axios from "axios";
import { HandlerInputError, StatusCode } from "common/enums";
import AppDrawer from "components/appDrawer";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import { badRequest } from "messages";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useGetContributionsQuery } from "store/services/teacher/contributions.services";
import s from "./style.module.css";

type DrawerPublicationsProps = {
  open: boolean;
  currRoom?: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currLevel?: any;
};

const DrawerPublications: FC<DrawerPublicationsProps> = ({
  open,
  setOpen,
  currRoom,
  currLevel,
}) => {
  const { user } = useSelector((state) => state.auth);
  const [roomsLoading, setRoomsLoading] = useState(false);
  const [levelsLoading, setLevelsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rooms, setRooms] = useState<any>([]);
  const [levels, setLevels] = useState<any>([]);
  const [form] = Form.useForm();
  const { refetch } = useGetContributionsQuery({
    profId: user?._id,
    self: true,
  });

  const onFinish = async (values: any) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("content", values?.content);
    formData.append("title", values?.title);
    formData.append("profId", user?._id);
    formData.append("display", values?.display || false);
    values?.links?.fileList.forEach((document: any) => {
      formData.append("links", document?.originFileObj);
    });

    if(values?.classe !== 'tous') {
      formData.append("classe", values?.classe || currRoom?._id);
    }

    if(values?.level) {
      formData.append("level", values?.level);
    } else {
      formData.append("level", currRoom?.level?._id);
    }

    try {
      const response = await axios.post(
        "https://api-ressources.monamialbert.com/api/v1/contributions",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.data) {
        refetch();
        form.resetFields();
        setOpen(false);
        message.success("Publication ajoutée avec succès");
      }
    } catch (error) {
      message.error(badRequest.error);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const props: UploadProps = {
    name: "file",
    maxCount: 5,

    onChange(info) {
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        info.file.status = "done";
      }

      return info.fileList.map((item) => {
        const { error, response, ...rest } = item;
        return rest;
      });
    },
  };

  const fetchRooms = useCallback(async (idTeacher: string | undefined) => {
    if (!currRoom) {
      try {
        setRoomsLoading(true);
        const response = await ROOMS_SERVICES.findAll(idTeacher);
        if (response?.statusCode === StatusCode.OK) {
          setRooms(response.data);
        } else {
          console.log(response?.message || "Erreur serveur");
        }
      } catch (error: any) {
        console.log(error);
      } finally {
        setRoomsLoading(false);
      }
    }
  }, []);

  const fetchLevels = useCallback(async () => {
    if (!currLevel) {
      try {
        setLevelsLoading(true);
        let tempLevels: any[] = []
        for (let i = 0; i < user.nivMat.length; i++) {
          tempLevels.push(user.nivMat[i].niveau)
        }
        setLevels(tempLevels)
      } catch (error: any) {
        console.log(error);
      } finally {
        setLevelsLoading(false);
      }
    }
  }, []);

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      setRoomsLoading(true);
      try {
        let tempClasses: any[] = []
        const response = await ROOMS_SERVICES.findByLevel(levelId, user._id);

        if (response?.statusCode === StatusCode.OK) {
          if(response?.data.length !== 0){
            tempClasses.push({ _id: 'tous', title: 'toutes les classes' , level: {title: ''}})
            tempClasses = tempClasses.concat(response?.data);
          }
          setRooms(tempClasses);
        }
      } catch (error) {
      } finally {
        setRoomsLoading(false);
      }
    },
    [roomsLoading]
  );

  useEffect(() => {
    //fetchRooms(user?._id);
    fetchLevels();
  }, [open === true ? open : null]);

  return (
    <AppDrawer title={false} visible={open} onClose={() => setOpen(false)}>
      <div className={s.intro}>
        <H1>Ajouter une publication</H1>
      </div>

      <div className={s.form}>
        <Form
          name="basic"
          form={form}
          onFinish={onFinish}
          //   onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className={s.field}>
            <Form.Item
              style={{ margin: 0, padding: 0 }}
              name="level"
              rules={[
                {
                  required: currLevel ? false : true,
                  message: HandlerInputError.default,
                },
              ]}
            >
              <Select
                placeholder={
                  currLevel
                    ? `${currLevel?.title || "Niveau"} | ${currLevel?.levelGroup
                    }`
                    : "Choisir un niveau"
                }
                bordered={false}
                style={{ width: "100%" }}
                onChange={(levelId: string) => OnSelectLevel(levelId)}
                loading={levelsLoading}
                options={
                  currLevel
                    ? [
                      {
                        label: `${currLevel?.title || "Niveau"} | ${currLevel?.levelGroup
                          }`,
                        value: currLevel?._id,
                      },
                    ]
                    : levels.map((level: any) => {
                      return {
                        label: `${level?.title || "Niveau"} | ${level?.levelGroup
                          }`,
                        value: level._id,
                      };
                    })
                }
              />
            </Form.Item>
          </div>
          <div className={s.field}>
            <Form.Item
              style={{ margin: 0, padding: 0 }}
              name="classe"
              rules={[
                {
                  required: currRoom ? false : true,
                  message: HandlerInputError.default,
                },
              ]}
            >
              <Select
                placeholder={
                  currRoom
                    ? `${currRoom?.title || "Ma classe"} | ${currRoom?.level?.title
                    }`
                    : "Choisir une classe"
                }
                bordered={false}
                style={{ width: "100%" }}
                loading={roomsLoading}
                options={
                  currRoom
                    ? [
                      {
                        label: `${currRoom?.title || "Ma classe"} | ${currRoom?.level?.title
                          }`,
                        value: currRoom?._id,
                      },
                    ]
                    : rooms.map((room: any) => {
                      console.log(rooms, 'rooms')
                      return {
                        label: `${room?.title || "Ma classe"} | ${room?.level?.title
                          }`,
                        value: room._id,
                      };
                    })
                }
              />
            </Form.Item>
          </div>
          <div className={s.field}>
            <Form.Item
              style={{ margin: 0, padding: 0 }}
              name="title"
              rules={[
                {
                  required: true,
                  message: HandlerInputError.default,
                },
                { max: 100, message: "Max 100 char" },
              ]}
            >
              <Input bordered={false} placeholder="Titre de la publication" />
            </Form.Item>
          </div>

          <div className={s.field}>
            <Form.Item
              style={{ margin: 0, padding: 0 }}
              name="content"
              rules={[
                {
                  required: true,
                  message: HandlerInputError.default,
                },
                { max: 255, message: "Max 255 char" },
              ]}
            >
              <Input.TextArea
                rows={10}
                bordered={false}
                placeholder="Description de la publication"
              />
            </Form.Item>
          </div>

          <div className={s.field}>
            <Form.Item
              style={{ margin: 0, padding: 0 }}
              name="links"
              rules={[{ required: true, message: HandlerInputError.default }]}
            >
              <Upload {...props} accept="application/pdf">
                <Button icon={<UploadOutlined />} type="text">
                  Upload de document
                </Button>
              </Upload>
            </Form.Item>
          </div>

          <div className={s.checkbox}>
            <Form.Item name="display" valuePropName="checked">
              <Checkbox>Rendre publique</Checkbox>
            </Form.Item>
          </div>

          <Row justify="center">
            <Form.Item>
              <AppButton htmlTypeSubmit={true} loading={loading}>
                Publier
              </AppButton>
            </Form.Item>
          </Row>
        </Form>
      </div>
    </AppDrawer>
  );
};

export default DrawerPublications;
