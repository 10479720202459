import { getToken } from "api";
import axios from "axios";
import { CHOICE } from "common/enums";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL, PROPOSITION } from "./chapters.api.routes";
import { CreateChapter } from "./chapters.interface";

export const findAll = async (): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findOne = async (id: string): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_BASE_URL}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

// export const findOne = async (id: string): Promise<ApiResponse | undefined> => {
//   try {
//     const response = await axios.get(`${API_BASE_URL}/${id}`);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//     throw error;
//   }
// };

export const create = async (
  chapter: CreateChapter
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(API_BASE_URL, chapter, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const proposition = async (
  idProposition: any,
  propositions: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(
      `${PROPOSITION}/${idProposition}/propositions`,
      propositions,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const update = async (
  id: any,
  chapter: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.patch(`${API_BASE_URL}/${id}`, chapter, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const updateChapterFunction = async (
  flag: string,
  id: any,
  chapter: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.patch(
      `${PROPOSITION}/${id}/${
        flag === CHOICE.SAVE_AS_DRAFT ? "newUpdate" : "submit"
      }`,
      chapter,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const deleteCourse = async (
  id: any
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.delete(`${PROPOSITION}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
