import * as appRoutes from "config/routes.config";
import { FC, useState } from "react";

//!IMAGES
import instaBadges from "assets/images/app/InstaProf_Icon.png";
import leVieuxAlbert from "assets/images/app/levieuxalbert.svg";
import instaBadgesRed from "assets/images/students/instaProfBadgeFalse.svg";
import ActivitiesIcon from "./assets/images/ads.svg";
import Content from "./assets/images/content.svg";
import classes from "./assets/images/mesclasse.svg";
import Msg from "./assets/images/msg.svg";
import Prof from "./assets/images/prof.svg";

//!STYLES
import { Image } from "antd";
import { UserRoles } from "common/enums";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toggleAppChat } from "store/AppChat/appChat";
import {
  closePorteFeuilleDrawer__,
  closeProfileDrawer
} from "store/auth/authSlice";
import {
  closeChatDrawerStudent,
  closePaiementDrawer,
} from "store/chat/mainSlice";
import {
  ACTIVE_DRAWER_ALERT_INSTAPROF,
  ACTIVE_MODE_INSTAPROF,
  INIT_MODAL,
} from "store/notification";
import style from "./assets/user.header.module.css";

type Props = {
  openMessagerieDrawer: () => void;
  visible: boolean;
  setVisible: any;
};

const NavUser: FC<Props> = ({
  openMessagerieDrawer,
  visible,
  setVisible,
}) => {
  //STATE
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const showModalInfoInstaProf = () => {
    dispatch(ACTIVE_MODE_INSTAPROF(true));
    dispatch(INIT_MODAL());
  };

  const closeModalInfoInstaProf = () => {
    dispatch(ACTIVE_MODE_INSTAPROF(false));
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const isStudent = user?.roles?.includes(UserRoles.STUDENT);

  const STUDENT_MOBILE = [
    {
      id: 0,
      name: "cours",
      icon: Content,
      link: appRoutes.STUDENT_MY_CONTENT,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
      },
      size: "30px",
    },
    {
      id: 1,
      name: "profs",
      icon: Prof,
      link: appRoutes.STUDENT_SEARCH_BEST_TEACHER,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
      },
      size: "30px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: leVieuxAlbert,
      link: appRoutes.STUDENT_DASHBOARD,
      size: "29px",
      demoModal: () => {
        dispatch(toggleAppChat(false));
      },
    },

    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(toggleAppChat(true));
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
      },
      link: "#",
      size: "25px",
    },
    {
      id: 3,
      name: "classes",
      icon: classes,
      link: appRoutes.STUDENT_MY_CLASS,
      size: "20px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
      },
    },
  ];

  const TEACHER_MOBILE = isMobile ? [
    {
      id: 0,
      name: "mon contenu",
      icon: Content,
      link: /* user?.step !== "completed" ?  */appRoutes.COURSESBYLEVELS/*  : appRoutes.TEACHER_COURSES */,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "26px",
    },
    {
      id: 1,
      name: "Accueil",
      icon: ActivitiesIcon,
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : appRoutes.TEACHER_ACTIVITIES,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "25px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: leVieuxAlbert,
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : appRoutes.TEACHER_DASHBOARD,
      size: "26px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
    },
    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(user?.step !== "completed" ? false : true));
        closeModalInfoInstaProf();
      },
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : "#",
      size: "25px",
    },
  ] : [
    {
      id: 0,
      name: "mon contenu",
      icon: Content,
      link: /* user?.step !== "completed" ?  */appRoutes.COURSESBYLEVELS/*  : appRoutes.TEACHER_COURSES */,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "26px",
    },
    {
      id: 1,
      name: "Accueil",
      icon: ActivitiesIcon,
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : appRoutes.TEACHER_ACTIVITIES,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "25px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: leVieuxAlbert,
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : appRoutes.TEACHER_DASHBOARD,
      size: "26px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
    },
    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(user?.step !== "completed" ? false : true));
        closeModalInfoInstaProf();
      },
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : "#",
      size: "25px",
    },
    {
      id: 3,
      name: "insta prof",
      icon: user?.instaProf === true ? instaBadges : instaBadgesRed,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        user?.instaProf === true ? dispatch(ACTIVE_DRAWER_ALERT_INSTAPROF()) : showModalInfoInstaProf();
      },
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : "#",
      size: "25px",
    },
  ];

  const INACTIVE_TEACHER_MOBILE = [
    {
      id: 0,
      name: "mon contenu",
      icon: Content,
      link: appRoutes.COURSESBYLEVELS,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "26px",
    },
    {
      id: 1,
      name: "Accueil",
      icon: ActivitiesIcon,
      link: appRoutes.TEACHER_ACTIVITIES,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
      size: "25px",
    },
    {
      id: 2,
      name: "Dashboard",
      icon: leVieuxAlbert,
      link: appRoutes.TEACHER_DASHBOARD,
      size: "26px",
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(false));
        closeModalInfoInstaProf();
      },
    },

    {
      id: 3,
      name: "message",
      icon: Msg,
      demoModal: () => {
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closeProfileDrawer());
        dispatch(closePaiementDrawer());
        setVisible(false);
        dispatch(toggleAppChat(user?.step !== "completed" ? false : true));
        closeModalInfoInstaProf();
      },
      link: user?.step !== "completed" ? appRoutes.COMPLETE_PROFILE : "#",
      size: "25px",
    },
  ];

  return (
    <nav className={`${style.nav__bar_mobile} `} id="mobile-menu">
      <ul className={style.nav__item}>
        {isStudent ? STUDENT_MOBILE.map((item) => {
          return (
            <li>
              <ul>
                <li>
                  <Link to={item?.link} onClick={item.demoModal}>
                    <Image
                      preview={false}
                      src={item.icon}
                      style={{
                        width: item.size,
                      }}
                    />
                  </Link>
                </li>
                <li onClick={item.demoModal}>
                  <Link to={item?.link}>{item.name}</Link>
                </li>
              </ul>
            </li>
          );
        }) : user?.isActive ? TEACHER_MOBILE.map((item) => {
          return (
            <li>
              <ul>
                <li>
                  <Link to={item?.link} onClick={item.demoModal}>
                    <Image
                      preview={false}
                      src={item.icon}
                      style={{
                        width: item.size,
                      }}
                    />
                  </Link>
                </li>
                <li onClick={item.demoModal}>
                  <Link to={item?.link}>{item.name}</Link>
                </li>
              </ul>
            </li>
          );
        }) : !user?.isActive && INACTIVE_TEACHER_MOBILE.map((item) => {
          return (
            <li>
              <ul>
                <li>
                  <Link to={item?.link} onClick={item.demoModal}>
                    <Image
                      preview={false}
                      src={item.icon}
                      style={{
                        width: item.size,
                      }}
                    />
                  </Link>
                </li>
                <li onClick={item.demoModal}>
                  <Link to={item?.link}>{item.name}</Link>
                </li>
              </ul>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default NavUser;
