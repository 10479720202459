import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import {
  API_BASE_URL,
  FIND_FILTER_QUIZ_FOR_COURS,
  FIND_FINISH_QUIZ,
  FIND_QUIZ_FOR_COURS,
} from "./studentQuizz.routes";

export const findQuizzDifficulty = async (
  idCours: string,
  difficulty: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_BASE_URL(idCours, difficulty)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const findFilterQuizzOfCours = async (
  idCours: string,
  difficulty: string,
  duration: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(
      `${FIND_FILTER_QUIZ_FOR_COURS(idCours, difficulty, duration)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const findQuizzForCours = async (
  idCours: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${FIND_QUIZ_FOR_COURS(idCours)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const findFinalQuiz = async (
  idUser: string,
  idQuiz: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`${FIND_FINISH_QUIZ(idUser, idQuiz)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const checkQuiz = async (
  id: string,
  quizz: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/quizz/instance/answred/check`, {id, quizz}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const giveQuizMark = async (
  id: string,
  quizz: string,
  etab: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/quizz/instance/give/points`, {id, quizz, etab}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStudentRanking = async (
  id: string,
  page: number,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/quizz/classement/get/etablissement`, {id, page}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getSchoolRanking = async (
  level: string,
  system: string,
  etablissement: string,
  page: number,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/quizz/classement/get/etablissements`, {level, system, etablissement, page}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getNationalRanking = async (
  id: string,
  page: number,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/quizz/classement/get/system`, {id, page}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getStatsData = async (
  id: string,
  idLevel: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/quizz/student/dashboard/stats/${id}/${idLevel}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const sendCourseProposal = async (
  idTeacher: string,
  idChapter: string,
  idContent: string,
  content: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/chapters/content/section/update`, {idTeacher, idChapter, idContent, content}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const validateProposal = async (
  idTeacher: string,
  idContent: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/chapters/content/section/update/validate`, {idTeacher, idContent}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const setPropositionAction = async (
  id: string,
  action: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/chapters/content/section/update/validate/action`, {id, action}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getSystemList = async (
  country: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/users/inscription/static/liste/system/educ?pays=${country}`, {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendSolutionProposal = async (
  idTeacher: string,
  idExo: string,
  idContent: string,
  content: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/exercices/solution/propose/new`, {idTeacher, idExo, idContent, content}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const validateSolutionProposal = async (
  idTeacher: string,
  idContent: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/exercices/solution/propose/new/validate`, {idTeacher, idContent}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchSolutionPropositionOld = async (id: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/exercices/solution/get/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSolutionPropositionNew = async (id: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/exercices/solution/get/new/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setSolutionPropositionAction = async (
  id: string,
  action: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/exercices/solution/propose/new/validate/action`, {id, action}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendIndiceProposal = async (
  idTeacher: string,
  idExo: string,
  idContent: string,
  content: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/exercices/indice/propose/new`, {idTeacher, idExo, idContent, content}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const validateIndiceProposal = async (
  idTeacher: string,
  idContent: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/exercices/indice/propose/new/validate`, {idTeacher, idContent}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const fetchIndicePropositionOld = async (id: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/exercices/indice/get/old/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchIndicePropositionNew = async (id: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/exercices/indice/get/new/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const setIndicePropositionAction = async (
  id: string,
  action: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/exercices/indice/propose/new/validate/action`, {id, action}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchSolutionIndiceExercice = async (id: string) => {
  const token = await getToken();
  try {
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/exercices/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
