import { PlusOutlined } from "@ant-design/icons";
import { Image, Skeleton, message } from "antd";
import {
  StudentJoinClass,
  findAll,
} from "api/services/studentJoinClass/studentJoinClass.services";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import H1 from "components/libs/title";
import { Field, Form, Formik } from "formik";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import { SudentJoinClassSchema } from "validations";

//!STYLE
import Breadcrumbs from "components/breadcrumbs";
import s from "./assets/myClasses.module.css";

//!IMAGE
import { StatusCode } from "common/enums";
import Flex from "components/libs/flex";
import CardTeacher from "../components/cardTeacher";
import Key from "./assets/key.svg";

import AppDrawer from "components/appDrawer";
import { AppNotification } from "components/appNotification";
import AppResult from "components/appResults";
import { useGetHistoriqueStudentQuery } from "store/services/teacher/classes.services";
import CardHistoryStudent from "./components/cardHistoryCours";

const MyClasses: FC = () => {
  //!HOOK
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading, isError, refetch } = useGetHistoriqueStudentQuery(
    user?._id
  );

  // const {
  //   data: calendar,
  //   isLoading,
  //   isSuccess,
  //   refetch,
  // } = useGetCalendarTeacherQuery(currentTeacher?.teacher?._id);

  //!STATE
  const [drawer, setDrawer] = useState<boolean>(false);
  const [teachers, setTeachers] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  //!FUNCTION
  const showDrawer = () => setDrawer(true);
  const onClose = () => setDrawer(false);

  const getTeacher = useCallback(async () => {
    setLoading(true);
    try {
      const response = await findAll();
      if (response) {
        setTeachers(response.data);
      }
    } catch (error: any) {
      message.error("Error");
    } finally {
      setLoading(false);
    }
  }, []);

  const joinClass = useCallback(async (values: any) => {
    values?.code.toLowerCase();
    try {
      if (values?.code && user?._id) {
        const response = await StudentJoinClass(values?.code, user?._id);
        if (response.statusCode === StatusCode.CREATED) {
          getTeacher();
          setDrawer(false);
          AppNotification(
            "Vous avez rejoint la classe avec succes",
            "AlbertVeryHappy"
          );
        }
      }
    } catch (error: any) {
      console.log(error.response.status, "er");
      if (error.response.status === 409) {
        message.warning("Vous avez déjà rejoint cette classe !");
      } else if (error.response.status === 404) {
        AppNotification("Le code entrer n'est pas valide", "AlbertSad");
      } else {
        message.error("Une erreur inconnue a été survenue");
      }
    }
  }, []);

  useEffect(() => {
    getTeacher();
  }, [getTeacher]);

  useEffect(() => {
    refetch();
  }, []);

  console.log(data, "datarcia");

  return (
    <React.Fragment>
      <Breadcrumbs isRooms={true} />
      <AppScreen>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>Mes cours particuliers</H2>
          </div>
          <div className={s.center} />
          <div className={s.right}></div>
        </div>

        <div className={s.main}>
          {isLoading && (
            <React.Fragment>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </React.Fragment>
          )}

          {!isLoading && data?.data?.length > 0 && (
            <Flex>
              <React.Fragment>
                {data?.data?.map((teacher: any) => (
                  <CardHistoryStudent item={teacher} />
                ))}
              </React.Fragment>
            </Flex>
          )}

          {!isLoading && !data?.data?.length && (
            <div className={s.__result}>
              <AppResult
                status="info"
                title="Aucun cours particulier planifié"
              />
            </div>
          )}
        </div>
      </AppScreen>

      <AppScreen>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>Mes classes</H2>
          </div>

          <div className={s.center} />

          <div className={s.right}>
            <AppButton icon={<PlusOutlined />} onClick={showDrawer}>
              Rejoindre une classe
            </AppButton>
          </div>
        </div>

        <div className={s.main}>
          {loading && (
            <React.Fragment>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </React.Fragment>
          )}

          {!loading && teachers.length >= 1 && (
            <Flex>
              <React.Fragment>
                {teachers?.map((teacher: any) => (
                  <CardTeacher teacher={teacher} />
                ))}
              </React.Fragment>
            </Flex>
          )}

          {!loading && teachers.length <= 0 && (
            <div className={s.__result}>
              <AppResult
                status="info"
                title="Aucune classe"
              // extra={
              //   <Button
              //     onClick={() => setShowAddExerciseModal(true)}
              //     icon={<PlusOutlined />}
              //     type="primary"
              //   >
              //     Créer un cours{" "}
              //   </Button>
              // }
              />
            </div>
          )}
        </div>

        <AppDrawer
          title={false}
          width={"60%"}
          onClose={onClose}
          visible={drawer}
        >
          <div className={s.__drawer__body}>
            <H1>Rejoindre une classe</H1>

            <p>
              Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
              nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam
              erat, sed diam voluptua.{" "}
            </p>

            <Formik
              initialValues={{
                code: "",
              }}
              validationSchema={SudentJoinClassSchema}
              onSubmit={joinClass}
            >
              {({ errors, touched }) => (
                <Form style={{ position: "relative" }}>
                  <div className={s.__join__class}>
                    <Field
                      name="code"
                      type="text"
                      placeholder="Insérer le code de la classe "
                    />
                    <Image
                      src={Key}
                      preview={false}
                      width={15}
                      onClick={onClose}
                      className={s.__key}
                    />
                  </div>
                  {errors.code && touched.code ? (
                    <div className={s.__error}>
                      <span>{errors.code}</span>
                    </div>
                  ) : null}
                  <AppButton
                    loading={loading}
                    htmlTypeSubmit={true}
                    className={s.__drawer__btn}
                  >
                    Rejoindre la classe
                  </AppButton>
                </Form>
              )}
            </Formik>
          </div>
        </AppDrawer>
      </AppScreen>
    </React.Fragment>
  );
};

export default MyClasses;
