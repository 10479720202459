import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import * as API_ROUTES from "./rooms.api.routes";
import * as API_CHALLENGES from "../challenges/challenge.api.routes";

export const findAll = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    if (!id) throw new Error("Merci de passer un id");
    const token = await getToken();
    const response = await axios.get(API_ROUTES.findAll(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findByLevel = async (
  id: any | undefined,
  teacher: any | undefined
): Promise<ApiResponse | undefined> => {
  try {
    if (!id) throw new Error("Merci de passer un niveau");
    const token = await getToken();
    const response = await axios.get(API_ROUTES.findByLevel(id, teacher), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findByUser = async (
  teacher: any | undefined
): Promise<ApiResponse | undefined> => {
  try {
    if (!teacher) throw new Error("Merci de passer un id professeur");
    const token = await getToken();
    const response = await axios.get(API_ROUTES.findByUser(teacher), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findOne = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    if (!id) throw new Error("Merci de passer un id");
    const token = await getToken();
    const response = await axios.get(API_ROUTES.getRoomById(id), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createRoom = async (
  createRoomDto: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(API_ROUTES.CREATE_ROOM, createRoomDto, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findRegistredStudents = async (
  id: string | undefined,
  search?: string | undefined
): Promise<ApiResponse | undefined> => {
  console.log("sqdqsd", search);
  try {
    if (!id) throw new Error("Merci de passer un id");
    const token = await getToken();
    const response = await axios.get(
      API_ROUTES.getRegistredStudents(id, search),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const challengerUneClasse = async (
  challenderUneClasseDto: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(
      API_CHALLENGES.CHALLENGE_CLASS,
      challenderUneClasseDto,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
