import { FC } from "react";
import Card from "components/card";
import * as appRoutes from "config/routes.config";

//!IMAGES
import Check from "./assets/images/check.svg";
import MATH from "../../../../assets/images/teacher/iconMath.svg";
import Avatar from "./assets/images/avatar.svg";

//!STYLES
import s from "./assets/cardTeacher.module.css";
import { Col, Image, Row, Select } from "antd";
import AppButton from "components/libs/button";
import { Link } from "react-router-dom";
import { Option } from "antd/lib/mentions";
import Matiers from "components/matiers";

interface CardCourses {
  teacher?: any;
}

const CardTeacher: FC<CardCourses> = ({ teacher }) => {
  return (
    <Card>
      <div className={s.content}>
        <div className={s.__top}>
          <div className={s.__avatar}>
            <Image src={Avatar} preview={false} width={80} />
          </div>

          <h3 className={s.__name__teacher}>
            {teacher?.teacher?.firstname || "Aucun Nom"}
          </h3>
        </div>

        <div className={s.__bottom}>
          <Matiers
            level={teacher?.level?.title}
            cours={teacher?.course?.title}
          />

          <Select
            defaultValue="Action"
            style={{ width: 170, marginLeft: "10px", marginTop: "5px" }}
            className={s.select}
            clearIcon={true}
          >
            <Option value="null">
              <Link to={`${appRoutes.STUDENT_MY_CLASS}/${teacher?._id}`}>
                {" "}
                Dashboard
              </Link>
            </Option>
          </Select>
        </div>
      </div>
    </Card>
  );
};

export default CardTeacher;
