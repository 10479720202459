import { PlusOutlined } from "@ant-design/icons";
import { FC } from "react";

//!REDUX
import { useDispatch } from "react-redux";
import { displayDrawer } from "store/adventissements";

//!COMPONENTS
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";

//!IMAGES

//!STYLES
import s from "./assets/calendar.module.css";
// import { CustomTab } from "components/InformationsPaiement/customTab/CustomTab";
import TeacherAvailability from "../availability";

const TeacherCalendar: FC = () => {
  //!HOOK
  const dispatch = useDispatch();

  //!FUNCTIONS
  return (
    <>
      <div className={s.calendar}>
        <div className={s.header}>
          <div className={s.left}>
            <H2 className={s.subtitle}>mon calendrier</H2>
          </div>

          <div className={s.center} />

          <div className={s.right}>
            <AppButton
              icon={<PlusOutlined />}
              onClick={() => dispatch(displayDrawer(true))}
            >
              Ajouter mes disponibilités
            </AppButton>
          </div>
        </div>

        <div className={s.main}>
          <div className={s.calendrier}>
            <TeacherAvailability />
          </div>
        </div>
      </div>
    </>
  );
};

export default TeacherCalendar;
