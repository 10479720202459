/* eslint-disable @typescript-eslint/no-redeclare */
import Card from "components/card";
import * as appRoutes from "config/routes.config";
import React, { FC, useState } from "react";

//!IMAGES
import { ReactComponent as IconDropDown } from "../../../../assets/images/app/dropDownIcon.svg";
import MATH from "../../../../assets/images/teacher/iconMath.svg";

//!STYLES
import {
  Button,
  Col,
  Dropdown,
  Image,
  Menu,
  Popconfirm,
  Row,
  Space,
  Tooltip,
} from "antd";
import AppDrawer from "components/appDrawer";
import H2 from "components/libs/subtitle";
import Latex from "react-latex-next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { formatDates } from "utils/formats";
import AvatarGroup from "../avatarGroup";
import s from "./assets/cardCours.module.css";

interface CardCourses {
  course?: any;
  user?: any;
  remove?: any;
}

const CardCourses: FC<CardCourses> = ({ course, user, remove }) => {
  const auth = useSelector((state: any) => state.auth);
  const [visible, setVisible] = useState<any>(false);
  // const [visibleDelete, setVisibleDelete] = useState<any>(false);

  const currentUser = auth.user;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const menu = (
    <React.Fragment>
      <Menu className={s.__down}>
        {course?.status === "DRAFT" && (
          <React.Fragment>
            {course?.teacherId === currentUser?._id ? (
              <React.Fragment>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_COURSES}/${course._id}/editCourse`}
                    key={"modifier"}
                  >
                    Modifier mon cours
                  </Link>
                </Menu.Item>}
                {!isMobile && <Menu.Item>
                  <Link to={"#"} key={"supprimer"}>
                    <Popconfirm
                      title="Voulez-vous vraiment supprimer ce cours? "
                      onConfirm={() => {
                        remove(course?._id);
                      }}
                    >
                      Supprimer
                    </Popconfirm>
                  </Link>
                </Menu.Item>}
                <Menu.Item>
                  <Link
                    to={"#"}
                    onClick={() => {
                      // setContent(course);
                      setVisible(true);
                    }}
                    key={"Aperçu"}
                  >
                    Aperçu
                  </Link>
                </Menu.Item>
              </React.Fragment>
            ) : (
              <>
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_GET_EXO}/${course._id}`}
                    key={course._id}
                  >
                    Aperçu
                  </Link>
                </Menu.Item>

                {currentUser.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_COURSES}/${course._id}/editCourse`}
                      key={"modifier"}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </React.Fragment>
        )}

        {course?.status === "PROPOSITION" && (
          <>
            <Menu.Item>
              <Link
                to={"#"}
                key={course._id}
                onClick={() => {
                  setVisible(true);
                }}
              >
                Aperçu
              </Link>
            </Menu.Item>

            {currentUser.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_COURSES}/${course._id}/editCourse`}
                  key={"modifier"}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </>
        )}

        {course?.status === "PENDING" && (
          <>
            <Menu.Item>
              <Link
                to={`#`}
                key={course._id}
                onClick={() => {
                  setVisible(true);
                }}
              >
                Aperçu
              </Link>
            </Menu.Item>

            {currentUser.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_COURSES}/${course._id}/editCourse`}
                  key={"modifier"}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </>
        )}

        {course?.status === "PUBLISHED" && (
          <React.Fragment>
            {course?.teacherId === user?.user?._id ? (
              <React.Fragment>
                <Menu.Item>
                  <Link
                    to={`#`}
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    Aperçu
                  </Link>
                </Menu.Item>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_PROPOSITION_COURS}/${course._id}/proposition`}
                  >
                    Améliorer
                  </Link>
                </Menu.Item>}

                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_COURSES}/${course._id}/duplicate`}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}
                {currentUser.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_COURSES}/${course._id}/editCourse`}
                      key={"modifier"}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Menu.Item>
                  <Link
                    to={`#`}
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    Aperçu
                  </Link>
                </Menu.Item>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_PROPOSITION_COURS}/${course._id}/proposition`}
                  >
                    Proposition
                  </Link>
                </Menu.Item>}
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_COURSES}/${course._id}/duplicate`}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}
                {currentUser.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_COURSES}/${course._id}/editCourse`}
                      key={"modifier"}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Menu>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Card>
        <div className={s.content}>
          <div className={s.user__group}>
            <AvatarGroup user={course?.teacher} />
          </div>
          <div
            className={s.__badge}
            style={{
              background: `

              ${course?.status === "PUBLISHED"
                  ? "#80B1B1"
                  : course?.status === "DRAFT"
                    ? "#064205"
                    : course?.status === "PENDING"
                      ? "#ea9610"
                      : course?.status === "PROPOSITION"
                        ? "#E3856F"
                        : ""
                } 
              `,
            }}
          >
            <span>
              {course?.status === "DRAFT" && "Brouillon"}
              {course?.status === "PENDING" && "En cours de validation"}
              {course?.status === "PUBLISHED" && "Publié"}
              {course?.status === "PROPOSITION" && "Proposition"}
            </span>
          </div>
          <div className={s.__top}>
            <div className={s.__date}>
              <span>Update : {formatDates(course?.updatedAt)}</span>
            </div>
            <div className={s.matiers}>
              <div className={s.left}>
                <Row style={{ alignItems: "center" }}>
                  <Col>
                    <Image
                      preview={false}
                      src={MATH}
                      className={s.__img}
                      width={18}
                    />
                  </Col>
                  <Col>
                    <span>{course?.course?.title.substring(0, 4)}</span>
                  </Col>
                </Row>
              </div>
              <div className={s.center}>
                <span>|</span>
              </div>
              <div className={s.right}>
                <span>{course?.course?.level?.title || "level"}</span>
              </div>
            </div>

            <Tooltip title={course?.title}>
              <h3>{course?.title}</h3>
            </Tooltip>
          </div>
          <div className={s.__bottom}>
            {/* <div className={s.__row}>
              <div>
                <Image preview={false} src={Check} className={s.__check} />
                <span>Quiz</span>
              </div>
              <div>
                <Image preview={false} src={Check} className={s.__check} />
                <span>Exercices</span>
              </div>
            </div> */}

            <Dropdown
              className={`${s.dropdown}`}
              overlay={menu}
              placement="bottomCenter"
            >
              <Button>
                <Space>
                  Action
                  <IconDropDown
                    style={{
                      position: "relative",
                      left: "15px",
                    }}
                  />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </div>
      </Card>

      {/*  */}
      <AppDrawer
        title={<H2>{course?.title}</H2>}
        onClose={() => {
          setVisible(false);
        }}
        headerStyle={{ border: "unset" }}
        visible={visible}
      >
        <div style={{ overflow: "hidden" }}>
          <div className={s.appercue__continute}>
            <h3>Aperçu : {course?.contents?.title}</h3>
            {course?.contents?.map((field: any, i: number) => (
              <div key={i}>
                <div className={s.__big__title__content}>
                  <h3 className={s.__big__title}>
                    {i + 1} - {field?.title}
                  </h3>
                </div>

                <div className={s.appercue__continute__content}>
                  {field?.subtitles?.map((x: any) => (
                    <>
                      <h4>{x?.title}</h4>
                      {x?.subtitles?.map((item: any) => (
                        <>
                          <div className={s.section__content}>
                            <h4>{item?.title}</h4>
                          </div>
                          <Latex>
                            {item?.content
                              ? item?.content
                                ?.replaceAll("amp;", "")
                                ?.replaceAll("<br />", "")
                              : ""}
                          </Latex>
                        </>
                      ))}
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </AppDrawer>
    </React.Fragment>
  );
};

export default CardCourses;
