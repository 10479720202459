import { Alert, Button, Col, Form, Image, Input, Row, message } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { loginUserDto } from "api/services/auth/dto/login-user.dto";
import { AuthMethods, StatusCode, UserRoles } from "common/enums";
import Container from "components/container";
import H1 from "components/libs/title";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import {
  loginWithEmailAndPassword
} from "store/auth/authSlice";
import s from "./assets/login.module.css";

//!IMAGES
import SendUpdatePasswordRequestDrawer from "components/updatePasswordDrawer/sendUpdateLink";
import AnimatedPage from "utils/animatedPage";
import organisation from "../../../assets/images/app/home/organisation.svg";

const LoginOrganisme: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.appChat);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<"id">();

  //!CONSTANTS
  const validateMessages = {
    required: t("auth.error.required"),
    types: {
      email: t("auth.error.emailInvalid"),
    },
  };

  //!FUNCTIONS
  const loginUser = async (user: loginUserDto) => {
    await dispatch(loginWithEmailAndPassword(user));
  };

  const [passwordDrawerVisible, setPasswordDrawerVisible] = useState(false);
  const onClosePasswordDrawer = () => {
    setPasswordDrawerVisible(false);
  };

  const checkUserCredentials = async (values: any) => {
    if (values.email.includes("@")) {
      loginUser(values);
    }
    else {
      getUserCredentials(values.email, values.password);
    }
  }

  const getUserCredentials = async (pseudo: any, password: any) => {
    try {
      const response = await AUTH_SERVICES.returnUserEmail(pseudo);
      if (response?.statusCode === StatusCode.CREATED) {
        const email = response?.data?.email;
        const userData = { email, password };
        loginUser(userData);
      }
      else {
        message.warning({
          content: response?.data?.message,
        });
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
    }
  }

  //!EFFECTS
  useEffect(() => {
    const isLoggedIn = auth.isLoggedIn;
    const isTeacher = auth.user?.roles?.includes(UserRoles.TEACHER);
    const isStudent = auth.user?.roles?.includes(UserRoles.STUDENT);
    const isParent = auth.user?.roles?.includes(UserRoles.PARENT);
    const isOrganism = auth.user?.roles?.includes(UserRoles.ORGANISME);
    const isCompleted = auth.user?.step === "completed";
    if (isLoggedIn && auth.lastRouteActionAuth) {
      navigate(auth.lastRouteActionAuth);
    } else {
      if (isLoggedIn)
        return isTeacher && isCompleted ? navigate(appRoutes.TEACHER_ACTIVITIES) :
          isTeacher && !isCompleted ? navigate(appRoutes.COMPLETE_PROFILE) :
            isStudent ? navigate(appRoutes.STUDENT_DASHBOARD) :
              isOrganism ? navigate(appRoutes.ORGANISME_DASHBOARD) :
                navigate(appRoutes.PARENT_DASHBOARD);
    }
  }, [auth.isLoggedIn, navigate, auth.user?.roles]);

  //!COMPONENT RETURN
  return (
    <AnimatedPage>
      <AppScreen className={s.login}>
        <Container>
          <Row justify="center" gutter={12}>
            <Image src={organisation} preview={false} width={65} />
          </Row>

          <p className={s.info}>
            Je suis un{" "}
            <strong>
              "Organisme"
            </strong>
            .<Link to={appRoutes.LOGINS}>Modifier</Link>
          </p>

          <Row justify="center" gutter={12}>
            <Col>
              <H1 className={`${s.title} ${s.titleLogin}`}>Se connecter</H1>
            </Col>
            <Col>
              <span className={s.separator}></span>
            </Col>
            <Col>
              <Link
                to={appRoutes.REGISTER_ORGANISME}
              >
                <H1 className={s.title}>Créer mon compte</H1>
              </Link>
            </Col>
          </Row>

          <Form
            validateTrigger={["onFinish"]}
            validateMessages={validateMessages}
            name="loginUser"
            autoComplete="off"
            onFinish={loginUser}
            className={s.form__container}
          >
            <Form.Item
              name="email"
              rules={[{ required: true }, { type: "email" }]}
              style={{ marginBottom: "20px" }}
            >
              <Input
                /* type="email" */
                className={s.field}
                /* placeholder={t("auth.email")} */
                placeholder={"Adresse email ou pseudo"}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                { required: true },
                {
                  pattern: /^.{6,}$/,
                  message:
                    "Le mot de passe doit contenir au moins 6 caractères !",
                },
              ]}
            >
              <Input.Password
                className={s.field}
                placeholder={t("auth.password")}
              />
            </Form.Item>
            <Form.Item>
              <Button
                ghost
                loading={
                  auth.isLogging && auth.authMethod === AuthMethods.EMAIL
                }
                block
                type="primary"
                htmlType="submit"
                className={s.submit}
              >
                {t("auth.login.loginWithEmailAndPassword")}
              </Button>
            </Form.Item>
          </Form>

          <div style={{ margin: "20px auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
            <a className={s.forgotPasswordButton} onClick={() => setPasswordDrawerVisible(true)}>Mot de passe oublié ?</a>
          </div>

          <div className={s.form__container}>
            {auth.loginErrors.length > 0 &&
              auth.loginErrors.map((error, index) => (
                <Alert
                  key={index}
                  message={error}
                  type="error"
                  showIcon
                  closable
                />
              ))}
          </div>
        </Container>
        <SendUpdatePasswordRequestDrawer visible={passwordDrawerVisible} onClose={onClosePasswordDrawer} />
      </AppScreen>
    </AnimatedPage>
  );
};

export default LoginOrganisme;
