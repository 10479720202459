import Card from "components/card";
import * as appRoutes from "config/routes.config";
import React, { FC, useState } from "react";
import { useSelector } from "react-redux";

//!IMAGES
import { ReactComponent as IconDropDown } from "../../../../assets/images/app/dropDownIcon.svg";
import MATH from "../../../../assets/images/teacher/iconMath.svg";
import CoursICons from "./assets/images/cours.png";

//!STYLES
import { Button, Col, Dropdown, Image, Menu, Row, Space, Tooltip } from "antd";
import AppButton from "components/libs/button";
import { Link } from "react-router-dom";
import { formatDates } from "utils/formats";
import s from "./assets/cardExo.module.css";

interface CardExo {
  exercise?: any;
  isStudent?: boolean;
  className?: string;
}

const CardExercice: FC<CardExo> = ({ exercise, isStudent, className }) => {

  const { user } = useSelector((state) => state.auth);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const menu = (
    <Menu className={s.__down}>
      {exercise?.status === "DRAFT" && (
        <React.Fragment>
          {exercise?.teacherId === user?._id ? (
            <React.Fragment>
              {!isMobile && <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                  key={exercise._id}
                >
                  Modifier mon exercice
                </Link>
              </Menu.Item>}

              {/* <Menu.Item>
                <Link
                  to={"#"}
                  // onClick={() => setIsDrawerChallenge(!isDrawerChallenge)}
                >
                  Supprimer
                </Link>
              </Menu.Item> */}

              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                  key={exercise._id}
                >
                  Vu d'ensemble
                </Link>
              </Menu.Item>

              {user.roles.includes("admin") && (
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Modifier
                  </Link>
                </Menu.Item>
              )}
            </React.Fragment>
          ) : (
            <>
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                  key={exercise._id}
                >
                  Vu d'ensemble
                </Link>
              </Menu.Item>

              {user.roles.includes("admin") && (
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Modifier
                  </Link>
                </Menu.Item>
              )}
            </>
          )}
        </React.Fragment>
      )}

      <React.Fragment>
        {exercise?.status === "PENDING" && (
          <>
            <Menu.Item>
              <Link
                to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                key={exercise._id}
              >
                Vu d'ensemble
              </Link>
            </Menu.Item>

            {user.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                  key={exercise._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </>
        )}

        {exercise?.status === "PUBLISHED" && (
          <React.Fragment>
            {exercise?.teacherId === user?._id ? (
              <>
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Modifier mon exercice
                  </Link>
                </Menu.Item>

                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}/duplicate`}
                    key={exercise._id}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}

                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                      key={exercise._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            ) : (
              <>
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Vu d'ensemble
                  </Link>
                </Menu.Item>
                {!isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_PROPOSITION_EXO}/${exercise._id}/proposition`}
                    key={exercise._id}
                  >
                    Améliorer
                  </Link>
                </Menu.Item>}
                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}/duplicate`}
                    key={exercise._id}
                  >
                    Dupliquer
                  </Link>
                </Menu.Item>}
                {user.roles.includes("admin") && (
                  <Menu.Item>
                    <Link
                      to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                      key={exercise._id}
                    >
                      Modifier
                    </Link>
                  </Menu.Item>
                )}
              </>
            )}
          </React.Fragment>
        )}

        {exercise?.status === "PROPOSITION" && (
          <>
            <Menu.Item>
              <Link
                to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                key={exercise._id}
              >
                Vu d'ensemble
              </Link>
            </Menu.Item>

            {user.roles.includes("admin") && (
              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                  key={exercise._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            )}
          </>
        )}
      </React.Fragment>
    </Menu>
  );

  return (
    <Card>
      <div className={`${s.content} ${className}`}>
        <div
          className={s.__badge}
          style={{
            background: `

              ${exercise?.status === "PUBLISHED"
                ? "#80B1B1"
                : exercise?.status === "DRAFT"
                  ? "#064205"
                  : exercise?.status === "PENDING"
                    ? "#ea9610"
                    : exercise?.status === "PROPOSITION"
                      ? "#E3856F"
                      : ""
              }
              `,
          }}
        >
          <span>
            {exercise?.status === "DRAFT" && "Brouillon"}
            {exercise?.status === "PENDING" && "En cours de validation"}
            {exercise?.status === "PUBLISHED" && "Publié"}
            {exercise?.status === "PROPOSITION" && "Proposition"}
          </span>
        </div>
        <div className={s.__top}>
          <div>
            <span>Update : {formatDates(exercise?.updatedAt)}</span>
          </div>
          {/* <div className={s.__matieres}></div> */}

          <div className={s.matiers}>
            <div className={s.left}>
              <Row style={{ alignItems: "center" }}>
                <Col>
                  <Image
                    preview={false}
                    src={MATH}
                    className={s.__img}
                    width={20}
                  />
                </Col>
                <Col>
                  <span>{exercise?.course?.title.substring(0, 4)}</span>
                </Col>
              </Row>
            </div>
            <div className={s.center}>
              <span>|</span>
            </div>
            <div className={s.right}>
              <span>{exercise?.course?.level?.title}</span>
            </div>
          </div>

          <div
          // style={{
          //   marginTop: "var(--mb-2)",
          // }}
          >
            {/* {!isStudent && <span className={s.__exo__length}>3 Exercices</span>} */}

            <Tooltip placement="top" title={exercise?.title}>
              <h3>{exercise?.title}</h3>
            </Tooltip>
            <h4 className={s.__chapter}>
              <img
                src={CoursICons}
                alt=""
                width={20}
                style={{
                  position: "relative",
                  top: "-1px",
                }}
              />
              {exercise?.chapterId?.title}
            </h4>
          </div>
        </div>

        {isStudent ? (
          <Link to={`${appRoutes.STUDENT_EXO}/${exercise?._id}`}>
            <AppButton className={s.__exo}>Lancer</AppButton>
          </Link>
        ) : (
          <div className={s.__bottom}>
            {user?.isActive && user?.step === "completed" && !isMobile && <Dropdown
              className={`${s.dropdown}`}
              overlay={menu}
              placement="bottomCenter"
            >
              <Button>
                <Space>
                  Contribuer
                  <IconDropDown
                    style={{
                      position: "relative",
                      left: "15px",
                    }}
                  />
                </Space>
              </Button>
            </Dropdown>}
          </div>
        )}
      </div>
    </Card>
  );
};

export default CardExercice;
