import {
  DeleteOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Popconfirm } from "antd";
import Matiers from "components/matiers";
import { FC } from "react";
import s from "./style.module.css";

type Props = {
  item: any;
  handleDelete: (day: string, id: number) => void;
};

const CardAvailability: FC<Props> = ({ item, handleDelete }) => {
  // const dispatch = useDispatch();
  // const [deleteAd, { isSuccess, isError, isLoading }] = useDeleteAdMutation();

  // useEffect(() => {
  //   if (isSuccess) {
  //     message.success("Créneau supprimé avec succès");
  //   }
  //   if (isError) {
  //     message.error(badRequest.error);
  //   }
  // }, [isSuccess, isLoading, isError]);

  return (
    <div className={s.card}>
      {item?.level?.map((l: any, index: number) => (
        <div key={index} className={s.box}>
          <div>
            {item?.timeRanges[index].range[0].hour} {">"}{" "}
            {
              item?.timeRanges[index].range[
                item?.timeRanges[index].range.length - 1
              ].hour
            }
          </div>

          <div>
            {item?.timeRanges[0]?.type === "collectif" ? (
              <UsergroupAddOutlined />
            ) : (
              <UserOutlined />
            )}
          </div>

          <Matiers
            className={s.matiers}
            cours={item?.topic[index]?.title}
            level={l?.title}
          />
          <div className={s.action}>
            <div className={`${s.multi_button} `}>
              <Popconfirm
                title="Veux-tu supprimer le créneau ?"
                onConfirm={() => handleDelete(item?.date, index)}
                okText="Oui"
                cancelText="Non"
              >
                <button className="fas fa-clipboard">
                  <DeleteOutlined />
                </button>
              </Popconfirm>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CardAvailability;
