import { PlusOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetContributionsQuery } from "store/services/teacher/contributions.services";
import CardContribution from "../cardContribution";
import DrawerPublications from "../drawerPublications";
import s from "./style.module.css";

type Props = {};

const PublicationsDashboard = (props: Props) => {
  const dispatch = useDispatch();
  const [openDrawerPublications, setOpenDrawerPublications] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading, isError } = useGetContributionsQuery({
    profId: user?._id,
    self: true,
  });

  return (
    <div className={s.publications}>
      <div className={s.header}>
        <div className={s.left}>
          <H2 className={s.subtitle}>Mes publications</H2>
        </div>

        <div className={s.center} />

        <div className={s.right}>
          <AppButton
            icon={<PlusOutlined />}
            onClick={() => setOpenDrawerPublications(true)}
          >
            Ajouter une publication
          </AppButton>
        </div>
      </div>

      <div className={s.main}>
        {isLoading ? (
          <>
            <Fragment>
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Fragment>
          </>
        ) : (
          <>
            {!isLoading && data?.data?.results.length > 0 ? (
              <div className={s.grid}>
                {data?.data?.results?.map((contribution: any, i: number) => (
                  <CardContribution key={i} contribution={contribution} />
                ))}
              </div>
            ) : (
              <AppResult status="info" title="Aucune publication" />
            )}
          </>
        )}
      </div>
      <DrawerPublications
        open={openDrawerPublications}
        setOpen={setOpenDrawerPublications}
      />
    </div>
  );
};

export default PublicationsDashboard;
