export const FIND_ALL = (self: string) =>
  `${process.env.REACT_APP_API_URL}/chapters?self=${self}`;

export const API_BASE_URL = `${process.env.REACT_APP_API_URL_RESSOURCES}/chapters`;

export const TEACHER_FILTER_COURSES = (value: any) => {
  const {
    title = "",
    levelId = "",
    topicId = "",
    status = "",
    self = false,
  } = value;
  const apiUrl = process.env.REACT_APP_API_URL;
  return `${apiUrl}/chapters?title=${title}&levelId=${levelId}&course=${topicId}&status=${status}&self=${self}`;
};

export const findCoursForTopic = (idTopic: string) =>
  `https://api-auth.monamialbert.com/api/v1/chapters?title=&levelId=&course=${idTopic}&status=&self=false`;
