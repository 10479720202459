import {
  Button,
  Dropdown,
  Image,
  Menu,
  Space,
  message
} from "antd";
import * as ADS_SERVICES from "api/services/advertissements/advertissements.services";
import { Variants } from "framer-motion";
import React, { FC, useEffect, useState } from "react";

import {
  BulbOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";

import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

//!COMPONENTS

//!IMAGES
import CourIcon from "../../../../assets/images/app/cours.png";
import Validate from "../../../../assets/images/teacher/validate.svg";
// import { ReactComponent as IconDropDown } from "../../../assets/images/app/dropDownIcon.svg";
import * as appRoutes from "config/routes.config";
import { ReactComponent as IconDropDown } from "../../../../assets/images/app/dropDownIcon.svg";

//!STYLES
import { StatusCode, UserRoles } from "common/enums";
import AppDrawer from "components/appDrawer";
import AppResult from "components/appResults";
import AddSolutionIndiceDrawer from "components/articleEditor/addSolutionIndiceDrawer";
import AppButton from "components/libs/button";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MatiersAds from "screens/teacher/components/matiersAds";
import { setUserConnect } from "store/auth/authSlice";
import s from "./assets/exoV2.module.css";

type ExoCardProps = {
  fetchAds?: any;
  title?: string;
  id?: string;
  date?: any;
  dates?: any;
  description?: string;
  topic?: any;
  price?: number;
  index?: number;
  status?: string;
  level?: any;
  onFetchAd?: any;
  language?: any;
  nameChapter?: string;
  cours: string;
  clues: any;
  solutions?: any;
  loading?: boolean;
  exercise?: any;
};

const cardVariants: Variants = {
  offscreen: {
    y: 300,
  },
  onscreen: {
    y: 50,
    rotate: -10,
    transition: {
      type: "spring",
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const CardExoV2: FC<ExoCardProps> = ({
  id,
  date,
  description,
  topic,
  title,
  fetchAds,
  level,
  language,
  nameChapter,
  cours,
  clues,
  solutions,
  loading,
  exercise,
}) => {
  const text = "Êtes-vous sûre de vouloir supprimer cette annonce ?";
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);

  //!STATE
  const [drawerIndice, setDrawerIndice] = useState<boolean>(false);
  const [drawerCorrige, setDrawerCorrige] = useState<boolean>(false);
  const [isSolutionIndiceDrawerVisible, setIsSolutionIndiceDrawerVisible] = useState(false);
  const [drawerType, setDrawerType] = useState<string>('');

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  //!FUNCTION
  const showDrawerIndice = () => {
    if (isLoggedIn) {
      setDrawerIndice(!drawerIndice);
    } else {
      dispatch(setUserConnect(true));
    }
  };
  const showDrawerCorrige = () => {
    if (isLoggedIn) {
      setDrawerCorrige(!drawerCorrige);
    } else {
      dispatch(setUserConnect(true));
    }
  };

  const onConfirme = async (idItem: any) => {
    try {
      const response = await ADS_SERVICES.deleteAds(idItem);

      if (response?.statusCode === StatusCode.OK) {
        message.info("supprimer avec succes");
        fetchAds();
      }

      return response;
    } catch (error) {
      message.error("Une erreur inconnue a été survenue.");
    }
  };

  const handleOpenSolutionIndiceDrawer = (type: string) => {
    setDrawerType(type);
  }

  const handleSolutionIndiceDrawerClose = () => {
    setDrawerType('');
    setIsSolutionIndiceDrawerVisible(false);
  };

  useEffect(() => {
    if (drawerType !== '') {
      setDrawerIndice(false);
      setDrawerCorrige(false);
      setIsSolutionIndiceDrawerVisible(true);
    }
  }, [drawerType]);

  const menu = (
    <Menu className={s.__down}>
      {exercise?.status === "DRAFT" && (
        <React.Fragment>
          {exercise?.teacherId === user?._id ? (
            <React.Fragment>
              {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                  key={exercise._id}
                >
                  Modifier
                </Link>
              </Menu.Item>}

              <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                  key={exercise._id}
                >
                  Vu d'ensemble
                </Link>
              </Menu.Item>
            </React.Fragment>
          ) : (
            <Menu.Item>
              <Link
                to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                key={exercise._id}
              >
                Vu d'ensemble
              </Link>
            </Menu.Item>
          )}
        </React.Fragment>
      )}

      <React.Fragment>
        {exercise?.status === "PENDING" && (
          <Menu.Item>
            <Link
              to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
              key={exercise._id}
            >
              Vu d'ensemble
            </Link>
          </Menu.Item>
        )}

        {exercise?.status === "PUBLISHED" && (
          <React.Fragment>
            {exercise?.teacherId === user?._id && user?.isActive && user?.step === "completed" ? (
              !isMobile && <Menu.Item>
                <Link
                  to={`${appRoutes.TEACHER_EXERCICES}/${exercise._id}`}
                  key={exercise._id}
                >
                  Modifier
                </Link>
              </Menu.Item>
            ) : (
              <React.Fragment>
                <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                    key={exercise._id}
                  >
                    Vu d'ensemble
                  </Link>
                </Menu.Item>
                {user?.isActive && user?.step === "completed" && !isMobile && <Menu.Item>
                  <Link
                    to={`${appRoutes.TEACHER_PROPOSITION_EXO}/${exercise._id}/proposition`}
                    key={exercise._id}
                  >
                    Améliorer
                  </Link>
                </Menu.Item>}
              </React.Fragment>
            )}
          </React.Fragment>
        )}

        {exercise?.status === "PROPOSITION" && (
          <>
            <Menu.Item>
              <Link
                to={`${appRoutes.TEACHER_GET_EXO}/${exercise._id}`}
                key={exercise._id}
              >
                Vu d'ensemble
              </Link>
            </Menu.Item>
          </>
        )}
      </React.Fragment>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className={s.card}>
        <div className={s.container}>
          <div className={s.header}>
            <div className={s.left}>
              {/* <Matiers level={level} cours={topic} /> */}
              <MatiersAds
                cours={cours}
                level={level}
              // lang={language?.language?.map((c: any) => (
              //   <span>
              //     {c}
              //     <span>, </span>
              //   </span>
              // ))}
              />
              <div className={s.date}>
                <Image preview={false} width={26} src={CourIcon} alt="logo" />{" "}
                <span>{nameChapter}</span>
              </div>
            </div>

            <div className={s.right}>
              {/* <div className={s.code}>
              <span>{price}€</span>
            </div> */}
              <div className={s.date}>
                <Image preview={false} width={16} src={Validate} alt="logo" />{" "}
                <span>
                  <strong>Update</strong> le {moment(date).format("LLLL")}
                </span>
              </div>
            </div>
          </div>

          <div className={s.text}>
            <h3 className={s.title__ads}>{title}</h3>
            <p>
              <Latex>
                {description ? description.replaceAll("amp;", "") : ""}
              </Latex>
            </p>
          </div>

          <div className={s.footer}>
            <div></div>
            <div className={s.action}>
              {user?.roles.includes(UserRoles.TEACHER) && !isMobile && (
                <Dropdown
                  className={`${s.dropdown}`}
                  overlay={menu}
                  placement="bottomCenter"
                >
                  <Button>
                    <Space>
                      Contribuer
                      <IconDropDown
                        style={{
                          position: "relative",
                          left: "15px",
                        }}
                      />
                    </Space>
                  </Button>
                </Dropdown>
              )}

              {!isMobile && <div className={s.footerAction}>
                <AppButton
                  className={s.__btn__footer}
                  icon={<BulbOutlined />}
                  onClick={() => showDrawerIndice()}
                >
                  Indice
                </AppButton>
                <AppButton
                  className={s.__btn__footer}
                  onClick={() => showDrawerCorrige()}
                  icon={<CheckCircleOutlined />}
                >
                  Corrigé
                </AppButton>
              </div>}
            </div>
          </div>
        </div>
      </div>

      <AppDrawer
        width={"75%"}
        onClose={showDrawerIndice}
        visible={drawerIndice}
      >
        {clues?.length > 0 ? (
          <div className={s.letter}>
            <h3 className={s.title__drawer}>Indice de l'exercice</h3>
            {user?.roles.includes(UserRoles.TEACHER) && <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
              <AppButton
                onClick={() => handleOpenSolutionIndiceDrawer("indice")}
                style={{ padding: "0px" }}
              >
                Proposer un indice
              </AppButton>
            </div>}
            <div className={s.__icon_drawer}>
              {/* <Image preview={false} src={Check} /> */}
              <ExclamationCircleOutlined
                style={{
                  color: "#EA9610",
                  fontSize: "20px",
                }}
              />
            </div>

            {!loading && (
              <Latex>
                {clues
                  ? clues?.replaceAll("amp;", "")?.replaceAll("<br />", "")
                  : ""}
              </Latex>
            )}
          </div>
        ) : (
          <AppResult status="info" title="Aucun indice renseigné" />
        )}
      </AppDrawer>

      {/* CORRIGE */}
      <AppDrawer
        title={false}
        onClose={showDrawerCorrige}
        visible={drawerCorrige}
      >
        {solutions?.length > 0 ? (
          <div className={s.letter}>
            <h3 className={s.title__drawer}>Corriger de l'exercice</h3>
            {user?.roles.includes(UserRoles.TEACHER) && <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "center" }}>
              <AppButton
                onClick={() => handleOpenSolutionIndiceDrawer("solution")}
                style={{ padding: "0px" }}
              >
                Proposer un corrigé
              </AppButton>
            </div>}
            <div className={s.__icon_drawer}>
              {/* <Image preview={false} src={Check} /> */}
            </div>
            {!loading && (
              <Latex>
                {solutions
                  ? solutions?.replaceAll("amp;", "")?.replaceAll("<br />", "")
                  : ""}
              </Latex>
            )}
          </div>
        ) : (
          <AppResult status="info" title="Aucune solution renseigné" />
        )}
      </AppDrawer>
      {user?.roles.includes(UserRoles.TEACHER) && (
        <AddSolutionIndiceDrawer
          visible={isSolutionIndiceDrawerVisible}
          onClose={handleSolutionIndiceDrawerClose}
          idTeacher={user?._id}
          idExo={exercise?._id}
          idContent={drawerType === "solution" ? exercise['solutions'][0]['_id'] : exercise['clues'][0]['_id']}
          type={drawerType}
        />
      )}
    </React.Fragment>
  );
};

export default CardExoV2;
