import { FC } from "react";
import { PlusOutlined } from "@ant-design/icons";

//!REDUX
import { useDispatch } from "react-redux";
import { displayDrawer } from "store/adventissements";

//!COMPONENTS
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";

//!STYLES
import s from "./assets/calendarStyle.module.css";
import TeacherRegistrationAvailability from "./TeacherRegistrationAvailability";

const TeacherRegistrationCalendar: FC = () => {
    //!HOOK
    const dispatch = useDispatch();

    //!FUNCTIONS
    return (
        <>
            <div className={s.calendar}>
                <div className={s.header}>
                    <div className={s.left}>
                        <H2 className={s.subtitle}>mon calendrier</H2>
                    </div>

                    <div className={s.center} />

                    <div className={s.right}>
                        <AppButton
                            icon={<PlusOutlined />}
                            onClick={() => dispatch(displayDrawer(true))}
                        >
                            Ajouter mes disponibilités
                        </AppButton>
                    </div>
                </div>

                <div className={s.main}>
                    <div className={s.calendrier}>
                        <TeacherRegistrationAvailability />
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeacherRegistrationCalendar;
