import { CHOICE } from "common/enums";
import { StatusCode } from "./../../../common/enums";
// import { getToken } from "api";
import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import {
  API_BASE_URL,
  API_BASE_URL_SECOND,
  PROPOSITION_EXO,
  TEACHER_FILTER_EXERCICE,
} from "./exercises.api.routes";
import { AnyRecord } from "dns";

export const findAll = async (): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const findOne = async (id: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const teacherProposition = async (idExercice: string, values: any) => {
  const token = await getToken();
  try {
    const response = await axios.post(PROPOSITION_EXO(idExercice), values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findOne_Second = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL_SECOND}/${id}`);
    const response_solution = await axios.get(
      `${API_BASE_URL_SECOND}/${id}/solutions`
    );
    const response_clue = await axios.get(`${API_BASE_URL_SECOND}/${id}/clues`);

    if (
      response?.data?.statusCode !== 200 ||
      response_solution?.data?.statusCode !== 200 ||
      response_clue?.data?.statusCode !== 200
    )
      return {
        statusCode: 400,
        data: {},
        message:
          "an error has occured while getting one or many properties values",
        status: StatusCode.NO_CONTENT.toString(),
      };

    return {
      statusCode: 200,
      data: {
        ...response.data.data,
        solution: response_solution?.data?.data[0]?.title,
        idSolution: response_solution?.data?.data[0]?._id,
        clue: response_clue?.data?.data[0]?.title,
        idClue: response_clue?.data?.data[0]?._id,
      },
      message: "all good",
      status: StatusCode.OK.toString(),
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const create = async (
  flag: string,
  createExerciseDto: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();

    const response = await axios.post(
      `${API_BASE_URL_SECOND}${
        flag === CHOICE.SAVE_AS_DRAFT ? "/brouillon" : ""
      }`,
      createExerciseDto,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createBrouillon = async (
  createExerciseDto: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();

    const response = await axios.post(
      `https://api-ressources.monamialbert.com/api/v1/exercices/brouillon`,
      createExerciseDto,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const createChapters = async () => {
  try {
    const response = await axios.post(`${API_BASE_URL}/chapters`);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const findOneChapters = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_BASE_URL}/${id}/chapters`);
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// !UPDATE CLUE AND SOLUTION
export const deleteClue = async (
  idExo: string,
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL_SECOND}/${idExo}/clues/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
export const deleteSolution = async (
  idExo: string,
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.delete(
      `${API_BASE_URL_SECOND}/${idExo}/solutions/${id}`
    );
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
// !UPDATE EXO
export const updateExercice = async (
  flag: string,
  idExo: string,
  title: string,
  description: string,
  clue: string,
  tempClue: string,
  solution: string,
  tempSolution: string,
  typeExercice: string
): Promise<ApiResponse | undefined> => {
  try {
    const responseOne = await axios.patch(`${API_BASE_URL_SECOND}/${idExo}`, {
      title: title,
      type: typeExercice,
      description: description,
    });

    // !verify if there are any clues
    const isThereAnyClues = await axios.get(
      `${API_BASE_URL_SECOND}/${idExo}/clues`
    );
    let responseTwo;
    if (isThereAnyClues.data.data.length === 0) {
      responseTwo = await axios.post(
        `${API_BASE_URL_SECOND}/${idExo}/createClue`,
        {
          title: clue || tempClue,
        }
      );
    } else {
      responseTwo = await axios.patch(
        `${API_BASE_URL_SECOND}/${idExo}/clues/${isThereAnyClues?.data?.data[0]?._id}`,
        {
          title: clue || tempClue,
        }
      );
    }
    // !verify if there are any solutions
    const isThereAnySolutions = await axios.get(
      `${API_BASE_URL_SECOND}/${idExo}/solutions`
    );
    let responseThree;
    console.log("ss", isThereAnySolutions.data.data.length);
    if (isThereAnySolutions.data.data.length === 0) {
      responseThree = await axios.post(
        `${API_BASE_URL_SECOND}/${idExo}/createSolution`,
        {
          title: solution || tempSolution,
        }
      );
    } else {
      responseThree = await axios.patch(
        `${API_BASE_URL_SECOND}/${idExo}/solutions/${isThereAnySolutions?.data?.data[0]?._id}`,
        {
          title: solution || tempSolution,
        }
      );
    }

    await axios.patch(`${API_BASE_URL_SECOND}/${idExo}/submit`, {
      title: title,
      type: typeExercice,
      description: description,
    });

    return {
      statusCode: 200,
      data: {
        ...responseOne.data.data,

        solution: responseTwo?.data?.data[0]?.title,
        idSolution: responseTwo?.data?.data[0]?._id,
        clue: responseThree?.data?.data[0]?.title,
        idClue: responseThree?.data?.data[0]?._id,
      },
      message: "all good",
      status: StatusCode.OK.toString(),
    };
  } catch (error) {
    return {
      statusCode: 400,
      data: {},
      message:
        "an error has occured while updating one or many properties values",
      status: StatusCode.NO_CONTENT.toString(),
    };
  }
};
export const teacherFilterExercices = async (values: any) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${TEACHER_FILTER_EXERCICE(values)}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
