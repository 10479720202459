import { Col, Form, Input, Row, Select, Space, Spin } from "antd";
import React, { FC, useEffect, useState } from "react";

//API
import * as EXERCISES_API from "api/services/exercises/exercises.services";

//!REDUX
import { useSelector } from "react-redux";

//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import H2 from "components/libs/subtitle";
import AppScreen from "../ScreenWithSidebar";

import { CKEditor } from "ckeditor4-react";

//!CONFIGS
import * as appRoutes from "config/routes.config";

//STYLES
import s from "../createExercice/assets/cerateExo.module.css";

import { CHOICE, StatusCode, TypeExercices } from "common/enums";
import AppButton from "components/libs/button";

import MathScan from "components/maths";
import useStudentExercice from "hooks/useStudentExercice.hook";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BackIcon } from "../../../assets/images/app/back.svg";

import Container from "components/container";
import ExercicePreviewUpdate from "components/previewExo/previewUpdate";
import UploadCloudinary from "components/uploadCloudinary";

const ExerciceDuplicate: FC = () => {
  //!HOOKS
  const { id } = useParams();
  const { user } = useSelector((s) => s.auth);
  const navigate = useNavigate();
  const { exercice, loading, error } = useStudentExercice(id!);
  const [form] = Form.useForm();

  //!STATE
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>([]);

  //
  const [previewTitle, setPreviewTitle] = useState<string>("");
  const [previewType, setPreviewType] = useState<string>("");
  const [previewDescription, setPreviewDescription] = useState<string>("");
  const [previewIndice, setPreviewIdice] = useState<string>("");
  const [previewSolution, setPreviewSolution] = useState<string>("");

  const [firstStepData, setFirstStepData] = useState<any>({});
  const [isAddingExercise, setIsAddingExercise] = useState<boolean>(false);
  const [isAddingExerciseBrouillon, setIsAddingExerciseBrouillon] =
    useState<boolean>(false);
  const [first, setfirst] = useState<string>("1");

  const propositionExercise = async (values: any) => {
    setIsLoading(true);

    try {
      const finalData = {
        clues: [values?.clues],
        solutions: [values?.solutions],
        exercise: {
          chapterId: exercice?.chapterId?._id,
          courseId: values?.courseId,
          description: values?.exercise?.description,
          title: values?.exercise?.title,
          teacherId: user?._id,
          type: values?.exercice?.type,
        },
      };

      let response: any;
      if (first === "1") {
        setIsAddingExercise(true);
        response = await EXERCISES_API.create(
          CHOICE.SAVE_PERMANENTLY,
          finalData
        );
      } else if (first === "2") {
        setIsAddingExerciseBrouillon(true);
        response = await EXERCISES_API.create(CHOICE.SAVE_AS_DRAFT, finalData);
      }

      if (response?.statusCode === StatusCode.CREATED) {
        navigate(appRoutes.TEACHER_EXERCICES);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
      setIsAddingExercise(false);
      setIsAddingExerciseBrouillon(false);
    }
  };

  const { Option } = Select;

  const previewEdit = (_: any, allFields: any) => {
    let content = allFields.filter((x: any) => {
      return x?.touched === true;
    });

    setPreview(content);
  };

  useEffect(() => {}, [propositionExercise, exercice, preview]);

  //!RETURN
  if (loading)
    return (
      <Container>
        <div
          style={{
            width: "100%",
            height: "100vh",
          }}
        >
          <Row justify="center" align="middle">
            <Spin />
          </Row>
        </div>
      </Container>
    );

  return (
    <>
      {!loading && (
        <Form
          form={form}
          onFinish={propositionExercise}
          onFieldsChange={previewEdit}
          style={{ padding: 20 }}
          layout="vertical"
          initialValues={exercice}
        >
          <React.Fragment>
            <Breadcrumbs />

            <AppScreen>
              <div className={s.header}>
                <div className={s.left}>
                  <H2 className={s.subtitle}>Nouvel exercice</H2>
                </div>

                <div className={s.center} />

                <div className={s.right}>
                  <Link to={appRoutes.TEACHER_EXERCICES}>
                    <AppButton
                      icon={
                        <BackIcon
                          style={{
                            position: "relative",
                            top: "4px",
                            left: "-5px",
                            width: "17px",
                          }}
                        />
                      }
                      className={s.header__button}
                    >
                      Retour à la liste des exercices
                    </AppButton>
                  </Link>
                </div>
              </div>

              <div className={s.main__title}>
                <h3>Cours associé : {exercice?.title}</h3>
              </div>

              <div className={s.main}>
                <div className={s.form}>
                  <div>
                    <label htmlFor="">Titre de l’exercice </label>
                    <div
                      className={s.select}
                      style={{
                        width: "100%",
                        paddingTop: "3px",
                      }}
                    >
                      <Form.Item
                        name={["exercise", "title"]}
                        rules={[
                          {
                            required: true,
                            message: "Ce champ est requis",
                          },
                        ]}
                        initialValue={exercice?.title}
                      >
                        <Input
                          placeholder=""
                          onChange={(e) => setPreviewTitle(e.target.value)}
                          bordered={false}
                        />
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="">Type de l’exercice </label>
                    <div
                      className={s.select}
                      style={{
                        width: "100%",
                      }}
                    >
                      <Form.Item
                        name={["exercise", "type"]}
                        rules={[
                          {
                            required: true,
                            message: "Ce champ est requis",
                          },
                        ]}
                        initialValue={exercice?.type}
                        noStyle
                      >
                        <Select
                          defaultValue={exercice?.type}
                          bordered={false}
                          className={s.__input_text}
                          style={{
                            width: "100%",
                          }}
                          allowClear
                          onChange={(v) => setPreviewType(v)}
                        >
                          <Option value={TypeExercices.ApplicationsDeCours}>
                            {TypeExercices.ApplicationsDeCours}
                          </Option>
                          <Option value={TypeExercices.Classiques}>
                            {TypeExercices.Classiques}
                          </Option>
                          <Option value={TypeExercices.Annales}>
                            {TypeExercices.Annales}
                          </Option>
                          <Option value={TypeExercices.Entraînement}>
                            {TypeExercices.Entraînement}
                          </Option>
                        </Select>

                        {/* <Input placeholder="" className={s.__input_text} /> */}
                      </Form.Item>
                    </div>
                  </div>

                  <div>
                    <label htmlFor="">Contenu de l’énoncé</label>
                    <div className={s.__custom__ck_editor}>
                      <Space>
                        <UploadCloudinary />
                        <MathScan />
                      </Space>
                      <Form.Item
                        name={["exercise", "description"]}
                        valuePropName="data"
                        getValueFromEvent={(event) => {
                          const data = event.editor.getData();
                          setPreviewDescription(data);
                          return data;
                        }}
                        initialValue={exercice?.description}
                        rules={[
                          {
                            required: false,
                            message: "Ce champ est requis",
                          },
                        ]}
                      >
                        <CKEditor
                          initData={exercice?.description}
                          style={{ border: "none" }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                  {/* indice */}
                  <label htmlFor="">Intitulé indice</label>
                  <div className={s.__custom__ck_editor}>
                    <Space>
                      <UploadCloudinary />
                      <MathScan />
                    </Space>
                    <Form.Item
                      name={["clues", "title"]}
                      valuePropName="data"
                      getValueFromEvent={(event) => {
                        const data = event.editor.getData();
                        setPreviewIdice(data);
                        return data;
                      }}
                      rules={[
                        {
                          required: false,
                          message: "Ce champ est requis",
                        },
                      ]}
                    >
                      <CKEditor
                        initData={exercice?.clues[0]?.title}
                        style={{ border: "none" }}
                      />
                    </Form.Item>
                  </div>

                  {/* solution */}

                  <div>
                    <label htmlFor="">Intitulé solution</label>
                    <div className={s.__custom__ck_editor}>
                      <Form.Item
                        name={["solutions", "title"]}
                        valuePropName="data"
                        getValueFromEvent={(event) => {
                          const data = event.editor.getData();
                          setPreviewSolution(data);
                          return data;
                        }}
                        rules={[
                          {
                            required: false,
                            message: "Ce champ est requis",
                          },
                        ]}
                      >
                        <CKEditor
                          initData={exercice?.solutions[0]?.title}
                          style={{ border: "none" }}
                        />
                      </Form.Item>
                    </div>
                  </div>
                </div>

                <div className={s.preview}>
                  <ExercicePreviewUpdate
                    exercice={exercice}
                    previewTitle={previewTitle}
                    previewType={previewType}
                    previewDescription={previewDescription}
                    previewIndice={previewIndice}
                    previewSolution={previewSolution}
                    data={preview}
                  />
                </div>
              </div>
            </AppScreen>

            <Row justify="center" gutter={12}>
              <Col>
                <AppButton
                  className={s.__submit__validation}
                  loading={isAddingExerciseBrouillon}
                  htmlTypeSubmit={true}
                  onClick={() => setfirst("2")}
                >
                  enregistrer
                </AppButton>
              </Col>
              <Col>
                <AppButton
                  className={s.__submit__validation}
                  loading={isAddingExercise}
                  htmlTypeSubmit={true}
                  onClick={() => setfirst("1")}
                >
                  Soumettre pour validation
                </AppButton>
              </Col>
            </Row>
          </React.Fragment>

          <Form.Item name="courseId" initialValue={exercice?.course?._id}>
            <Input placeholder="sous titre" hidden />
          </Form.Item>
          <Form.Item name="chapterId" initialValue={exercice?.chapterId?._id}>
            <Input placeholder="sous titre" hidden />
          </Form.Item>
        </Form>
      )}
    </>
  );
};

export default ExerciceDuplicate;
