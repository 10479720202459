/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Drawer, Space } from "antd";
import QuizMenu from "./quiz/QuizMenu";
import HeaderDrawer from "components/headerDrawer/HeaderDrawer";
import H2 from "components/libs/subtitle";
import { ReactComponent as Back } from "../../../../assets/images/app/backBlack.svg";
interface ApercueQuizInterface {
  data: any;
  // we reveived the questions array from the previous component
}
export const ApercueQuiz: React.FC<ApercueQuizInterface> = (props) => {
  // !HOOKS
  const [visible, setVisible] = useState(false);
  //!FUNCTIONS
  // Partie Dave__START
  const showLargeDrawer = () => {
    setVisible(true);
  };
  const onClose = async () => {
    setVisible(false);
  };
  // Partie Dave__END

  const visibleHandler = () => {
    setVisible(false);
  };

  console.log(props, "props");
  return (
    <>
      <Space>
        <div
          role="button"
          onClick={showLargeDrawer}
          style={{
            color: "#80B1B2",

            bottom: 0,
            cursor: "pointer",
            left: "0",

            fontSize: "13px",
            textTransform: "uppercase",
            fontWeight: "500",
          }}
        >
          Aperçu du quiz
        </div>
      </Space>

      <Drawer
        title={<H2>APERCUE DU QUIZ</H2>}
        headerStyle={{ border: "none" }}
        placement="right"
        width={"75%"}
        onClose={onClose}
        visible={visible}
        closable={true}
        closeIcon={
          <Back
            style={{
              position: "relative",
              width: "30px",
            }}
          />
        }
      >
        <QuizMenu data={props.data}></QuizMenu>
      </Drawer>
    </>
  );
};
