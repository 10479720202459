import axios from "axios";
import { API_BASE_URL } from "./faq.routes";

export const findAllFaq = async (type: string) => {
  try {
    const response = await axios.get(API_BASE_URL(type));
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
