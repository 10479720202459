import React, { FC, useEffect } from "react";
import s from "./assets/calendarStyle.module.css";
import { EditOutlined, DeleteOutlined, UsergroupAddOutlined, UserOutlined } from "@ant-design/icons";
import Matiers from "components/matiers";
import { useDispatch } from "react-redux";
import {
    useDeleteAdMutation,
    useDeleteAdSpecificMutation,
} from "store/services/teacher/ads.services";
import { Popconfirm, message } from "antd";
import { badRequest } from "messages";

type Props = {
    item: any;
};

const AvailabilityCard: FC<Props> = ({ item }) => {

    const dispatch = useDispatch();
    const [deleteAd, { isSuccess, isError, isLoading }] = useDeleteAdMutation();

    useEffect(() => {
        if (isSuccess) {
            message.success("Créneau supprimé avec succès");
        }
        if (isError) {
            message.error(badRequest.error);
        }
    }, [isSuccess, isLoading, isError]);

    return (
        <div className={s.card}>
            {item?.level?.map((l: any, index: number) => (
                <div
                    key={index}
                    className={s.box}
                    onClick={() => console.log(item, "crenauxcrenaux")}
                >
                    <div>
                        {item?.timeRanges[index].range[0].hour} {">"}{" "}
                        {
                            item?.timeRanges[index].range[
                                item?.timeRanges[index].range.length - 1
                            ].hour
                        }
                    </div>
                    <Matiers
                        className={s.matiers}
                        cours={item?.topic[index]?.title}
                        level={l?.title}
                    />
                    <div>
                        {item?.timeRanges[index].type === "collectif" ? (
                            <UsergroupAddOutlined />
                        ) : (
                            <UserOutlined />
                        )}
                    </div>
                    <div className={s.action}>
                        <div className={`${s.multi_button} `}>
                            <Popconfirm
                                title="Are you sure？"
                                onConfirm={() => deleteAd(item?._id)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <button className="fas fa-clipboard">
                                    <DeleteOutlined />
                                </button>
                            </Popconfirm>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AvailabilityCard;
