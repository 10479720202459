import FadeInOnScroll from "components/FadeIn";
import * as appRoutes from "config/routes.config";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import join_community_lines from "../../../../assets/images/app/teachers/join_community_lines.svg";
import teacher_one from "../../../../assets/images/app/teachers/teacher_one.png";
import teacher_two from "../../../../assets/images/app/teachers/teacher_two.png";
import todo_book from "../../../../assets/images/app/teachers/todo_book.png";
import trace_instaprof from "../../../../assets/images/app/teachers/trace_instaprof.svg";
import trace_teachers_button from "../../../../assets/images/app/teachers/trace_teachers_button.svg";
import trace_teachers_title from "../../../../assets/images/app/teachers/trace_teachers_title.svg";
import st from "../../../app/common.module.css";
import s from "./style.module.css";

const SlideTeacher = () => {

    const navigate = useNavigate();

    const handleButtonClick = () => {
        navigate(appRoutes.LOGINS);
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.mainBox}>
                <div className={s.leftBox}>
                    <img src={trace_teachers_title} className={s.traceTitle}></img>
                    <h1 className={s.title}>Enseignez, Inspirez<br />et Gagnez avec<br />« Mon Ami Albert »</h1>
                    {isMobile ? <p className={s.message}>Notre soutien scolaire innovant combine des<br />techniques d’apprentissage modernes et un<br />accompagnement personnalisé pour assurer<br />votre succès académique.</p> : <p className={s.message}>Notre soutien scolaire innovant combine des techniques<br />d’apprentissage modernes et un accompagnement personnalisé<br />pour assurer votre succès académique.</p>}
                    <button className={st.button} style={{ marginTop: isMobile ? "10px" : "40px" }} onClick={handleButtonClick}>
                        S’inscrire
                    </button>
                    {!isMobile && <img src={trace_teachers_button} className={s.traceButton}></img>}
                </div>
                <div className={s.imagesBox}>
                    <img src={teacher_one} className={`${st.floatAnimation} ${s.teacherOne}`}></img>
                    <img src={teacher_two} className={`${st.floatAnimationOne} ${s.teacherTwo}`}></img>
                    <img src={join_community_lines} className={`${st.floatAnimationOne} ${s.communityLines}`}></img>
                    <img src={trace_instaprof} className={`${st.floatAnimationOne} ${s.traceInstaProf}`}></img>
                    <img src={todo_book} className={s.todoBook}></img>
                </div>
            </div>
            {isMobile && <img src={trace_teachers_button} className={s.traceButton}></img>}
            <div className={`${s.box} ${st.scaleAnimationSmall}`}>
                <div className={s.subBox}>
                    <FadeInOnScroll>
                        <div className={s.circleOne}>
                            <div className={s.circleOneNumber}>1</div>
                        </div>
                    </FadeInOnScroll>
                    <div className={s.boxTitle}>
                        <div className={s.circleTitle}>
                            <FadeInOnScroll>Inscrivez-vous</FadeInOnScroll>
                        </div>
                        <FadeInOnScroll>
                            <div className={s.boxMessage}>
                                <a className={s.circleMessage}>Et complétez votre profil</a>
                            </div>
                        </FadeInOnScroll>
                    </div>
                </div>
                <div className={s.subBox}>
                    <FadeInOnScroll>
                        <div className={s.circleTwo}>
                            <div className={s.circleTwoNumber}>2</div>
                        </div>
                    </FadeInOnScroll>
                    <div className={s.boxTitle}>
                        <div className={s.circleTitle}>
                            <FadeInOnScroll>Faites valider</FadeInOnScroll>
                        </div>
                        <FadeInOnScroll>
                            <div className={s.boxMessage}>
                                <a className={s.circleMessage}>Lors d’un entretien en ligne<br />Avec Mon Ami Albert !</a>
                            </div>
                        </FadeInOnScroll>
                    </div>
                </div>
                <div className={s.subBox}>
                    <FadeInOnScroll>
                        <div className={s.circleThree}>
                            <div className={s.circleThreeNumber}>3</div>
                        </div>
                    </FadeInOnScroll>
                    <div className={s.boxTitle}>
                        <div className={s.circleTitle}>
                            <FadeInOnScroll>Générez des REVENUS</FadeInOnScroll>
                        </div>
                        <FadeInOnScroll>
                            <div className={s.boxMessage}>
                                <a className={s.circleMessage}>En donnant des cours à des élèves<br />du monde entier</a>
                            </div>
                        </FadeInOnScroll>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SlideTeacher;