import { Select } from "antd";
import {
  findLevelsByIdStudent
} from "api/services/levels/levels.services";
import { getAvancement } from "api/services/quizz/quizz.services";
import * as appRoutes from "config/routes.config";
import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

import { StatusCode } from "common/enums";

import { TypeMatiers } from "common/enums";
import { useSelector } from "react-redux";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";
//!COMPONENTS
import Breadcrumbs from "components/breadcrumbs";
import AppScreen from "screens/teacher/ScreenWithSidebar";
import CardTracked from "../components/cardTracked";

//IMAGES
import { ReactComponent as Arrow } from "../../../assets/images/app/arrow.svg";
import ANGLAIS from "../../../assets/images/app/iconMatiers/Anglais.png";
import FRANCAIS from "../../../assets/images/app/iconMatiers/Francais.png";
import HISTOIREGEO from "../../../assets/images/app/iconMatiers/Histoire-geo.png";
import MATH from "../../../assets/images/app/iconMatiers/Mathematiques.png";
import PHILO from "../../../assets/images/app/iconMatiers/Philosophie.png";
import PHYSIQUE from "../../../assets/images/app/iconMatiers/Physique-chimie.png";
import SVT from "../../../assets/images/app/iconMatiers/SVT.png";

//!STYLES
import AppResult from "components/appResults";
import s from "./assets/dashboard.module.css";

//
const { Option, OptGroup } = Select;
const defaultLevel = "633f06bf56d09098666226be";

const StudentDashboard = () => {
  //!HOOKS
  const { data: levelsData, isLoading, isError } = useGetAllLevelsQuery("");
  const levels = useSelector((state) => state.levels);
  const { user } = useSelector((state) => state.auth);
  const [progress, setProgress] = useState<any>([]);
  //!STATE
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tempLevelId, setTempLevelId] = useState<any | null>("");

  const OnSelectLevel = useCallback(
    async (levelId: string | any) => {
      setLoading(true);
      const response = await TOPICS_SERVICES.findAll(levelId);
      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
        getProgression(user?._id, levelId);
        setLoading(false);
      }
    },
    [tempTopic]
  );

  const onFetchLevel = async (idLvel: string | any) => {
    try {
      const response = await findLevelsByIdStudent(idLvel);
      setTempLevelId(response.data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getProgression = useCallback(async (id: string | any, level: string | any) => {
    if (user) {
      const response = await getAvancement(id, level);
      if (response?.statusCode === StatusCode.OK) {
        setProgress(response?.data);
      }
    }
  }, []);

  useEffect(() => {
    let levelId = localStorage.getItem("tempLevelId");
    OnSelectLevel(levelId || user?.levelId || defaultLevel);
    onFetchLevel(levelId);
    setTempLevelId(levelId);
  }, [isLoading]);

  const allLevels = levelsData?.data;

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <AppScreen>
        <div className={s.dahboard}>
          <div className={s.header}>
            {/* Select Level */}
            <Select
              bordered={false}
              loading={isLoading}
              defaultValue={localStorage.getItem("tempLevelId") || user?.levelId || defaultLevel}
              suffixIcon={
                <Arrow
                  className={s.iconArrowClass}
                  style={{
                    position: "relative",
                    right: "10px",
                    top: "-5px",
                  }}
                />
              }
              placeholder="Selectionner un niveau"
              className={s.__select}
              style={{
                width: "300px",
              }}
              onClick={() => setTempLevelId("")}
              onChange={(id: string) => {
                OnSelectLevel(id);
                localStorage.setItem("tempLevelId", id);
              }}
            >
              <OptGroup label={"Primaire"}>
                {allLevels?.Primaire?.map((college: any) => (
                  <Option key={college?._id} value={college?._id}>
                    {tempLevelId?.title || college?.title}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label={"Collège"}>
                {allLevels?.Collège?.map((college: any) => (
                  <Option key={college?._id} value={college?._id}>
                    {tempLevelId?.title || college?.title}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label={"Lycée"}>
                {allLevels?.Lycée?.map((college: any) => (
                  <Option key={college?._id} value={college?._id}>
                    {tempLevelId?.title || college?.title}
                  </Option>
                ))}
              </OptGroup>

              <OptGroup label={"Prépa"}>
                {allLevels?.Prépa?.map((college: any) => (
                  <Option key={college?._id} value={college?._id}>
                    {tempLevelId?.title || college?.title}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </div>

          <div className={s.main}>
            {!loading ? (
              tempTopic.length === 0 ? (
                <AppResult
                  status="info"
                  title="Oups nous n'avons pas trouvé de matière !"
                />
              ) : (
                <div className={s.row}>
                  {tempTopic
                    ?.map((course: any) => (
                      <Link
                        to={`${appRoutes.STUDENT_CHAPTERS}/${course?._id}`}
                        className={s.__links}
                      >
                        <CardTracked
                          skillBarClassName={s.__skillbar}
                          name={course?.title}
                          id={course?._id}
                          progress={progress}
                          icon={
                            course?.description == TypeMatiers.mathematique
                              ? MATH
                              : course?.description == TypeMatiers.SVT
                                ? SVT
                                : course?.description ==
                                  TypeMatiers.PhysiqueChimie
                                  ? PHYSIQUE
                                  : course?.description == TypeMatiers.Philosophie
                                    ? PHILO
                                    : course?.description == TypeMatiers.HistoireGeo
                                      ? HISTOIREGEO
                                      : course?.description == TypeMatiers.Francais
                                        ? FRANCAIS
                                        : course?.description == TypeMatiers.Anglais
                                          ? ANGLAIS
                                          : ""
                          }
                          widthImage={70}
                        />
                      </Link>
                    ))
                    .reverse()}
                </div>
              )
            ) : (
              <React.Fragment>
                <div className={s.row}>
                  {[...Array(8)].map((i) => (
                    <CardTracked
                      key={i}
                      id=""
                      skillBarClassName={s.__skillbar}
                      widthImage={70}
                    />
                  ))}
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
      </AppScreen>
    </React.Fragment>
  );
};

export default StudentDashboard;
