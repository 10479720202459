import React, { FC } from "react";
import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

type Props = {
  content: string;
};

const AppLatex: FC<Props> = ({ content }) => {
  return (
    <Latex>
      {content ? content?.replaceAll("amp;", "")?.replaceAll("<br />", "") : ""}
    </Latex>
  );
};

export default AppLatex;
