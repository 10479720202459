import axios from "axios";
import FadeInOnScroll from "components/FadeIn";
import FadeInUpOnScroll from "components/FadeInUp";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TeacherCalendarForStudent from "screens/student/components/calendarTeacher";
import { setUserConnect } from "store/auth/authSlice";
import arrow_left_teachers from "../../../../../assets/images/app/home/arrow_left_teachers.png";
import arrow_right_teachers from "../../../../../assets/images/app/home/arrow_right_teachers.png";
import elipse_teachers from "../../../../../assets/images/app/home/elipse_teachers.svg";
import fleche_teachers from "../../../../../assets/images/app/home/fleche_teachers.svg";
import star from "../../../../../assets/images/app/home/star.svg";
import st from "../../../common.module.css";
import s from "./style.module.css";

const ChooseTeacher = () => {

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const [idTeacher, setIdTeacher] = useState<any>();
    const [showTeacherCalendar, setShowTeacherCalendar] = useState<boolean>(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [results, setResults] = useState<string[]>([]);
    const [dataLoaded, setDataLoaded] = useState<boolean>(false);

    const fetchData = async () => {
        try {
            const postData = {
                page: 1,
            };

            const res = await axios.post('https://api-ressources.monamialbert.com/api/v1/advertisements/reserve/search', postData);
            if (res.data) {
                res?.data?.data?.result.map((e: any) => {
                    results.push(e?.teacher);
                });
                setDataLoaded(true);
                console.log(results);
            }

        } catch (error) {
            console.error("There was an error making the POST request!", error);
        }
    };

    const [currentIndex, setCurrentIndex] = useState(0);

    const nextSlide = () => {
        if (currentIndex < results.length - 4) {
            setCurrentIndex(currentIndex + 1);
        }
    };

    const prevSlide = () => {
        if (currentIndex > 0) {
            setCurrentIndex(currentIndex - 1);
        }
    };

    const checkIfConnected = (id: string) => {
        const isLoggedIn = auth.isLoggedIn;
        if (!isLoggedIn) {
            dispatch(setUserConnect(true));
        }
        else {
            setIdTeacher(id);
        }
    }

    //!EFFECTS
    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if (idTeacher != undefined) {
            setShowTeacherCalendar(true);
        }
    }, [idTeacher]);

    return (
        <div className={s.mainContainer}>
            <div className={s.mainBox}>
                <FadeInUpOnScroll>
                    <h1 className={s.title}>Choisissez le meilleur enseignant pour VOUS</h1>
                </FadeInUpOnScroll>
                <img src={elipse_teachers} className={s.ellipse_title}></img>
                <FadeInOnScroll>
                    {isMobile ? <p className={s.message}>Choisissez le enseignant ayant la personnalité,<br />le style pédagogique, l’expérience professionnelle<br />et l’expertise dont vous avez réellement besoin!</p> : <p className={s.message}>Choisissez le enseignant ayant la personnalité, le style pédagogique,<br />l’expérience professionnelle et l’expertise dont vous avez réellement besoin!</p>}
                </FadeInOnScroll>
                {!isMobile && <img src={fleche_teachers} style={{ position: "relative", left: "-36%", width: "30px", bottom: "130px", transform: 'rotate(300deg)' }}></img>}
                <div className={s.teachersBox}>
                    {!isMobile && <div className={s.arrowLeftBox} onClick={prevSlide}>
                        <img src={arrow_left_teachers} style={{ height: "15px" }}></img>
                    </div>}
                    {dataLoaded && <div className={s.carouselContent}>
                        {isMobile ? results?.slice(currentIndex, currentIndex + 1).map((teacher: any, index: any) => (
                            <div key={index} className={`${s.slideTeacherBox} ${st.scaleAnimationSmall}`}>
                                <div className={s.teacherBoxImage}><img src={teacher?.profile} className={s.teacherBoxImageProfile}></img></div>
                                <div className={s.teacherBoxName}>{teacher?.firstname} {teacher?.lastname}</div>
                                <div className={s.teacherBoxCountry}>{teacher?.pays}</div>
                                <div className={s.teacherBoxCourses}>{teacher?.nivMat[0]?.matieres[0]?.matiere?.title}</div>
                                <div className={s.teacherBoxMark}>{teacher?.rateCours} <img src={star} style={{ height: "18px", marginLeft: "5px", position: "relative", bottom: "2px" }}></img></div>
                                <button className={st.button} style={{ marginTop: "20px" }} onClick={() => checkIfConnected(teacher?._id)}>
                                    Réservez un cours
                                </button>
                            </div>
                        )) : results?.slice(currentIndex, currentIndex + 4).map((teacher: any, index: any) => (
                            <div key={index} className={`${s.slideTeacherBox} ${st.scaleAnimationSmall}`}>
                                <div className={s.teacherBoxImage}><img src={teacher?.profile} style={{ height: "155px", width: "155px", borderRadius: "50%" }}></img></div>
                                <div className={s.teacherBoxName}>{teacher?.firstname} {teacher?.lastname}</div>
                                <div className={s.teacherBoxCountry}>{teacher?.pays}</div>
                                <div className={s.teacherBoxCourses}>{teacher?.nivMat[0]?.matieres[0]?.matiere?.title}</div>
                                <div className={s.teacherBoxMark}>{teacher?.rateCours} <img src={star} style={{ height: "18px", marginLeft: "5px", position: "relative", bottom: "2px" }}></img></div>
                                <button className={st.button} style={{ marginTop: "20px" }} onClick={() => checkIfConnected(teacher?._id)}>
                                    Réservez un cours
                                </button>
                            </div>
                        ))}
                    </div>}
                    {!isMobile && <div className={s.arrowRightBox} onClick={nextSlide}>
                        <img src={arrow_right_teachers} style={{ height: "15px" }}></img>
                    </div>}
                    {isMobile && <div style={{ display: "flex", flexDirection: "row", margin: "30px auto", alignItems: "center", justifyContent: "center" }}>
                        <div className={s.arrowLeftBox} onClick={prevSlide}>
                            <img src={arrow_left_teachers} style={{ height: "15px" }}></img>
                        </div>
                        <div className={s.arrowRightBox} onClick={nextSlide}>
                            <img src={arrow_right_teachers} style={{ height: "15px" }}></img>
                        </div>
                    </div>}
                </div>
            </div>
            <TeacherCalendarForStudent
                open={showTeacherCalendar}
                setOpen={setShowTeacherCalendar}
                currentTeacher={idTeacher}
            />
        </div>
    );
};

export default ChooseTeacher;