import styled from "styled-components";

// !style
export const StyledTabs = styled.div`
  .ant-tabs-tab {
    min-height: 50px !important;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 15px 20px !important;
    font-size: 13px !important;
    font-weight: 500 !important;
    background: #fafafa !important;
    border: 0px !important;
    border-top-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    color: var(--text-grisé) !important;
  }

  .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active,
  .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab-active {
    background: var(--first-color) !important;
    box-shadow: inset -3px -2px #5a8484;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: white !important;
    text-shadow: 0px 1.5px 0.5px gray !important;
  }

  .ant-tabs .ant-tabs-top .ant-tabs-card {
    background-color: #fafafa !important;
  }
`;

// !CONSTS
export const MESSAGE_CONFIRMATION =
  "Êtes-vous sûr de vouloir envoyer une demande de retrait";
export const MESSAGE_CONFIRMATION_2 =
  "Êtes-vous sûr de vouloir mettre à jour vos informations de paiement";
