import { Button, Skeleton, message } from "antd";
import { udpdateCreneaux } from "api/services/advertissements/advertissements.services";
import { StatusCode } from "common/enums";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import React, { FC, Fragment, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { displayDrawer } from "store/adventissements";
import { useGetAdQuery } from "store/services/teacher/ads.services";
import { convertDaysToFrench } from "utils/formats";
import CardAvailability from "./card";
import s from "./style.module.css";

interface Niche {
  _id: string;
  date: string;
  level: { _id: string; title: string; levelGroup?: string }[];
  topic: { _id: string; title: string; level: string }[];
  timeRanges: { range: { hour: string; allow: boolean }[] }[];
  idCreneau: string;
}

interface Data {
  _id: string;
  niches: Niche[];
}

const days = [
  { name: "Monday" },
  { name: "Tuesday" },
  { name: "Wednesday" },
  { name: "Thursday" },
  { name: "Friday" },
  { name: "Saturday" },
  { name: "Sunday" },
];

const TeacherAvailability: FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { data: crenaux, isLoading, refetch } = useGetAdQuery(user?._id);
  const [isDelete, setIsDelete] = useState<boolean>(false);

  function sortNicheByDate(data: Data[]): { [key: string]: Niche[] } {
    if (data) {
      const nichesByDate: { [key: string]: Niche[] } = {};

      for (const { niches, _id } of data) {
        for (const niche of niches) {
          if (!nichesByDate[niche.date]) {
            nichesByDate[niche.date] = [];
          }

          nichesByDate[niche.date].push({
            ...niche,
            _id: _id,
          });
        }
      }

      return nichesByDate;
    }

    return {};
  }

  const removeIndexFromLevelsAndTopics = useCallback(
    async (day: string, index: number) => {
      setIsDelete(true);
      const data = JSON.parse(JSON.stringify(crenaux?.data[0]?.niches));

      const date = data.find((item: any) => item.date === day);

      if (date) {
        if (date.hasOwnProperty("level") && Array.isArray(date.level)) {
          if (index >= 0 && index < date.level.length) {
            date.level.splice(index, 1);
          }
        }

        if (date.hasOwnProperty("topic") && Array.isArray(date.topic)) {
          if (index >= 0 && index < date.topic.length) {
            date.topic.splice(index, 1);
          }
        }

        if (
          date.hasOwnProperty("timeRanges") &&
          Array.isArray(date.timeRanges)
        ) {
          if (index >= 0 && index < date.timeRanges.length) {
            date.timeRanges.splice(index, 1);
          }
        }

        const currCre = data.map((niche: any) => {
          return {
            date: niche?.date,
            level: niche?.level?.map((l: any) => l?._id),
            topic: niche?.topic?.map((t: any) => t?._id),
            timeRanges: niche?.timeRanges.map((r: any) => {
              return {
                range: [
                  {
                    hour: r?.range[0].hour,
                  },

                  { hour: r?.range[r?.range.length - 1].hour },
                ],
              };
            }),
          };
        });

        const finalUpdateData = {
          niches: [...currCre],
          teacherId: user?._id,
        };

        try {
          const res = await udpdateCreneaux(
            crenaux?.data[0]?._id,
            finalUpdateData
          );
          if (res?.statusCode === StatusCode.OK) {
            message.success("supprimer avec succès !");
            refetch();
          }
        } catch (error) {
          message.error("Une erreur inconu a été survenue");
        }
      } else {
        console.log("Date non trouvée !");
      }
      setIsDelete(false);
    },
    [crenaux]
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const renderDayAvailability = (day: string, style?: React.CSSProperties) => {
    return (
      <div className={s.day} >
        <Button type="primary" style={isMobile ? { width: "125px" } : style}>
          {convertDaysToFrench(day)}
        </Button>
        {sortNicheByDate(crenaux?.data)[day]?.map((item: any, i: number) => (
          <CardAvailability
            item={item}
            key={i}
            handleDelete={removeIndexFromLevelsAndTopics}
          />
        ))}
      </div>
    );
  };

  return (
    <div className={s.creneaux}>
      {isDelete || isLoading ? (
        <Fragment>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </Fragment>
      ) : (
        <div className={s.__form}>
          <Fragment>
            {crenaux?.data.length <= 0 ? (
              <AppResult
                status={"info"}
                title={"Tu n'as aucun créneau disponible listé pour le moment"}
                extra={
                  <AppButton onClick={() => dispatch(displayDrawer(true))}>
                    Ajouter un créneau
                  </AppButton>
                }
              />
            ) : (
              <Fragment>
                {days.map((day, index) => (
                  <React.Fragment key={index}>
                    {renderDayAvailability(day.name)}
                  </React.Fragment>
                ))}
              </Fragment>
            )}
          </Fragment>
        </div>
      )}
    </div>
  );
};

export default TeacherAvailability;
