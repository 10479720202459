import { motion } from "framer-motion";
import { FC } from "react";
import { Link } from "react-router-dom";

//!ANTD
import { Image } from "antd";

//!ROUTES
import * as appRoutes from "config/routes.config";

//!IMAGES
import student from "../../../../../assets/images/app/home/etudiant.svg";
import organisation from "../../../../../assets/images/app/home/organisation.svg";
import parent from "../../../../../assets/images/app/home/parent.svg";
import prof from "../../../../../assets/images/app/home/prof.svg";

//!STYLES
import s from "./assets/typeProfile.module.css";

type typeLogin = {
  isLogin: boolean;
};

const TypeProfile: FC<typeLogin> = ({ isLogin }) => {
  return (
    <>
      <div className={s.choice__profile}>
        <Link
          to={
            isLogin
              ? `${appRoutes.LOGIN}/teacher`
              : appRoutes.REGISTER_TEACHER_BY_EMAIL
          }
        >
          <motion.div
            className={s.prof}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
          >
            <Image
              preview={false}
              // width={230}
              className={s.imageTypeProfile}
              src={prof}
              alt="prof maa"
            />
          </motion.div>

          <Link
            to={
              isLogin
                ? `${appRoutes.LOGIN}/teacher`
                : appRoutes.REGISTER_TEACHER_BY_EMAIL
            }
            style={{ marginTop: "1em" }}
          >
            Je suis prof
          </Link>
        </Link>

        <Link
          to={
            isLogin ? `${appRoutes.LOGIN}/student` : appRoutes.REGISTER_STUDENT
          }
        >
          <motion.div
            className={s.prof}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
          >
            <Image
              preview={false}
              className={s.imageTypeProfile}
              src={student}
              alt="student maa"
            />
          </motion.div>
          <Link
            style={{ marginTop: "1em" }}
            to={
              isLogin
                ? `${appRoutes.LOGIN}/student`
                : appRoutes.REGISTER_STUDENT
            }
          >
            Je suis étudiant
          </Link>
        </Link>

        <Link
          to={
            isLogin ? `${appRoutes.LOGIN_PARENT}` : appRoutes.REGISTER_PARENT
          }
        >
          <motion.div
            className={s.prof}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Image
              preview={false}
              src={parent}
              alt="parent maa"
            />
          </motion.div>
          <Link
            style={{ marginTop: "1em" }}
            to={
              isLogin
                ? `${appRoutes.LOGIN_PARENT}`
                : appRoutes.REGISTER_PARENT
            }
          >
            Je suis parent
          </Link>
        </Link>

        <Link
          to={
            isLogin ? `${appRoutes.LOGIN_ORGANISME}` : appRoutes.REGISTER_ORGANISME
          }
        >
          <motion.div
            className={s.prof}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.8 }}
            style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
          >
            <Image
              preview={false}
              src={organisation}
              alt="parent maa"
            />
          </motion.div>
          <Link
            style={{ marginTop: "1em" }}
            to={
              isLogin
                ? `${appRoutes.LOGIN_ORGANISME}`
                : appRoutes.REGISTER_ORGANISME
            }
          >
            Je suis organisme
          </Link>
        </Link>
      </div>
    </>
  );
};

export default TypeProfile;
