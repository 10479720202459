/* eslint-disable no-restricted-globals */
import React, { useEffect, useState } from "react";
// !!ANTD
import { Drawer, Image, Input, message, Popconfirm } from "antd";
import { ReactComponent as Back } from "../../assets/images/app/backBlack.svg";
import portefeuille from "./portefeuille.svg";

// !!REDUX
import { useDispatch, useSelector } from "react-redux";

// !!STYLES
import style from "./style.module.css";
// !ICONS
import { PlusOutlined } from "@ant-design/icons";
import questionMark from "./question_mark.svg";
// !STORE
import { closePaiementDrawer } from "store/chat/mainSlice";

// !COMPONENTS
import AppButton from "components/libs/button";
import { setShowDrawerPaiementOneMoreTimeHandlerToFalse } from "store/auth/authSlice";
import {
  getConvertedAmountFunction,
  getStripeUrlFunction,
} from "store/paiement/paiementSlice";
// !CONSTANTS
const SENTENCE_LABEL = "Tu as ";
interface DrawerPaiement {
  drawerPaiement?: boolean;
}
// !MEDIA_QUERIES
const smallDevices = window.matchMedia("only screen and (max-width: 992px)");
const mediumDevices = window.matchMedia("only screen and (min-width: 992px)");

export const DrawerPaiementComponent: React.FC<DrawerPaiement> = ({
  drawerPaiement,
}) => {
  // !HOOKS
  const [width, setwidth] = useState<string>();
  const { user } = useSelector((state) => state.auth);
  const { stripeInfos } = useSelector((state) => state.paiement);
  const dispatch = useDispatch();
  const { paiementDrawerOpen } = useSelector((state) => state.main);
  const [montant, setMontant] = useState(user?.coins);
  const visibleHandler = () => {
    if (user?.roles.includes("student")) dispatch(closePaiementDrawer());
  };
  const s_videoSizeController = (x: any) => {
    if (x.matches) {
      setwidth("100%");
    }
  };

  const m_videoSizeController = (x: any) => {
    if (x.matches) {
      setwidth("35%");
    }
  };
  // !USE_EFFECT
  useEffect(() => {
    s_videoSizeController(smallDevices);
    smallDevices.addListener(s_videoSizeController);
    m_videoSizeController(mediumDevices);
    mediumDevices.addListener(m_videoSizeController);
  }, []);
  const getConvertedAmount = (amount: String) => {
    if (amount !== "" && amount) {
      dispatch(
        getConvertedAmountFunction({
          amount,
        })
      );
    } else {
      dispatch(getConvertedAmountFunction(0));
    }
  };
  const getStripeUrl = (
    userId: string,
    amount: number,
    success: string,
    cancel: string
  ) => {
    if (amount === 0)
      message.success({
        content: "Veuillez ne pas rentrer un montant égale à 0",
      });
    else {
      dispatch(
        getStripeUrlFunction({
          userId,
          amount,
          success,
          cancel,
        })
      );
    }
  };

  useEffect(() => {
    getConvertedAmount("0");
    setTimeout(() => {
      dispatch(setShowDrawerPaiementOneMoreTimeHandlerToFalse());
    }, 10000);
  }, []);

  useEffect(() => {
    if (stripeInfos.statusCode === 201) {
      window.open(stripeInfos.data.url);
      location.reload();
    }
  }, [stripeInfos]);
  return (
    <Drawer
      placement={width === "100%" ? "bottom" : "right"}
      width={width}
      height={"100%"}
      onClose={visibleHandler}
      visible={paiementDrawerOpen || drawerPaiement}
      closable={false}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className={style.__header}>
        <Back
          onClick={visibleHandler}
          style={{
            position: "relative",
            width: "30px",
            top: "2px",
            cursor: "pointer",
          }}
        />
        {/* <H2 className={style.__title}>{HEADER_LABEL}</H2>  */}
      </div>
      <div className={`${style.__columnArrangement} ${style.convertedAmount}`}>
        <div>
          <Image
            preview={false}
            src={portefeuille}
            style={{ width: "30px", marginRight: "10px" }}
          />
          {SENTENCE_LABEL}
          {/* {!loading ? (
            <span>{items.data?.coins} €</span>
          )
            : (
            <span>en cours...</span>
            )
          } */}
          <span>{user?.coins} €</span>
        </div>
        <>
          {user?.coins === 0 ? (
            <>
              penses à recharger ton portefeuille pour réserver des cours
              particuliers
            </>
          ) : (
            <div style={{ visibility: "hidden" }}>
              penses à recharger ton portefeuille pour réserver des cours
              particuliers
            </div>
          )}
        </>
      </div>
      <div className={`${style.__topBlock}`}>
        <div>Recharge ton compte</div>
        <div className={`${style.__topBlockEntry}`}>
          <Input
            className={style.field__text}
            type="number"
            placeholder="Montant de recharge"
            min="0"
            onChange={(e) => {
              if (e.target.value === "") {
                setMontant(0);
                // getConvertedAmount("0");
              } else {
                setMontant(parseInt(e.target.value));
                // getConvertedAmount(e.target.value);
              }
            }}
          />
          <div className={style.right}>
            <Popconfirm
              placement="top"
              title={"Voulez-vous continuer ?"}
              onConfirm={() =>
                getStripeUrl(
                  user?._id || "",
                  montant,
                  "https://monamialbert.com/achat/success",
                  "https://monamialbert.com/achat/echec"
                )
              }
              okText="Oui"
              cancelText="Non"
              icon={
                <img
                  src={questionMark}
                  alt=""
                  style={{ float: "left", width: "15px" }}
                />
              }
            >
              <AppButton icon={<PlusOutlined />}>PAYER</AppButton>
            </Popconfirm>
          </div>
        </div>
      </div>
    </Drawer>
  );
};
