import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  Col,
  Form,
  Input,
  Popconfirm,
  Popover,
  Result,
  Row,
  Select,
  Skeleton,
} from "antd";
import * as ADS_SERVICES from "api/services/advertissements/advertissements.services";
import {
  findAllTeacherInsta,
  killAllTeacherInsta,
} from "api/services/advertissements/advertissements.services";
import { FLAGS, StatusCode } from "common/enums";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerProfileTeacher from "screens/teacher/components/drawerProfileTeacher";
import {
  setIdReceiver,
  setSelectUserChat,
  toggleAppChat,
} from "store/AppChat/appChat";
import { setIsDrawerInstaProf } from "store/chat/roomSlice";
import { useFindRoomMutation } from "store/services/chat";
import {
  useLastSessionInstaProfQuery,
  useStopRequestInstaProfMutation,
} from "store/services/teacher/notification.services";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import SuccesSessionInsta from "./components/succesSessionInst";

//!COMPONENT
import CountdownTimer from "components/appCountDown";
import AppDrawer from "components/appDrawer";
import AppButton from "components/libs/button";
import GridInstaProf from "components/libs/gridInstaProf";
import H1 from "components/libs/title";
import CardAdsTeacher from "./components";

//!ASSETS
import instaBadges from "assets/images/students/badgeInstaProf.svg";

//!STYLE
import axios from "axios";
import { AppNotification } from "components/appNotification";
import AppResult from "components/appResults";
import AppSelectGroupInscription from "components/libs/selectGroupInscription";
import { useCheckSaissonMutation } from "store/services/student/ads.services";
import s from "./assets/style.module.css";

type Props = {
  openDrawerInstaProf: boolean;
  showDrawerSearchInstaProf: () => void;
  isInstaProf?: boolean;
  isBtnInstaProf: boolean;
  dataOfTeacher: any;
  setIsBtnInstaProf: React.Dispatch<React.SetStateAction<boolean>>;
};

type ErrorResponse = {
  error: string;
};

const { Option } = Select;

const DrawerSearchInstaProf: React.FC<Props> = ({
  openDrawerInstaProf,
  showDrawerSearchInstaProf,
  isInstaProf,
  isBtnInstaProf,
  dataOfTeacher,
  setIsBtnInstaProf,
}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const [findRoom, { isSuccess }] = useFindRoomMutation();
  const {
    isSuccess: isSesssionSuccess,
    data: sessionData,
    isLoading,
    refetch,
  } = useLastSessionInstaProfQuery(user?._id);
  const [checkSaisson, { data: dataCheckSaisson }] = useCheckSaissonMutation();
  const [
    stopRequestInstaProf,
    {
      isSuccess: isSuccessStopSession,
      isLoading: isStopingRequestInstaProf,
      isError,
    },
  ] = useStopRequestInstaProfMutation();
  const { socket } = useSelector((state) => state.appChat);
  const popupRef = useRef<HTMLDivElement>(null);

  //!STATES
  const [top, setTop] = useState(10);
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [instaProf, setInstaProf] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [currentProfAccepted, setCurrentProfAccepted] = useState<any>(null);
  const [openD, setOpenD] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isAcceptedProf, setIsAcceptedProf] = useState<boolean>(false);
  const [teacherHasSession, setTeacherHasSession] = useState<boolean>(false);
  const [isStopingInstaProf, setIsStopingInstaProf] = useState<boolean>(false);
  const [infoTeacherAcceptedReservation, setInfoTeacherAcceptedReservation] =
    useState<any>(null);
  const [openDrawerProfileTeacher, setOpenDrwerProfileTeacher] =
    useState<boolean>(false);
  const [uidTeacherInvite, setUidTeacherInvite] = useState<string>("");

  const THREE_DAYS_IN_MS = 5 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  //!FUNCTIONS
  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      const response = await TOPICS_SERVICES.findAll(levelId);

      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
      }
    },
    [tempTopic]
  );

  const onSearchTeacherInsta = async (values: any) => {
    setIsSearch(true);
    const topicId =
      dataOfTeacher?.teacher?.nivMat[0]?.matieres[0]?.matiere?._id;

    const bodyInviteProf = {
      ...values,
      userProf: dataOfTeacher?.teacher?._id,
      level: dataOfTeacher ? user?.levelId : values?.level,
      matiere: dataOfTeacher ? topicId : values?.matiere,
    };

    try {
      const response = await findAllTeacherInsta(
        isBtnInstaProf ? bodyInviteProf : values
      );
      if (response?.statusCode === StatusCode.CREATED) {
        setInstaProf(response?.data);

        if (
          response?.message.includes("Prof is in a current session InstaProf")
        ) {
          setTeacherHasSession(true);
        }

        localStorage.setItem(
          "studentTempCodeInstaProf",
          response?.data?.session?.code
        );
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        console.error(error.response, "Prof is in a current");
        if (
          error?.response?.data?.message?.includes(
            "Prof is in a current session InstaProf"
          )
        ) {
          setTeacherHasSession(true);
        }
      } else {
        console.error(error);
      }
    }
  };

  const onClear = (ad: any) => {
    const teacher = ad.prof;
    const final = Object.assign(ad, { teacher: teacher });
    // delete ad.prof;

    return final;
  };

  const resetDrawerInstaProf = () => {
    showDrawerSearchInstaProf();
    setIsSearch(false);
    setData([]);
    setInstaProf([]);
    setIsBtnInstaProf(false);
    localStorage.removeItem("studentTempCodeInstaProf");
  };

  const handleStopRequestInstaProf = async () => {
    await killAllTeacherInsta(user?._id, instaProf?.session?.code);
    await socket?.emit("killRequestInstaProf", instaProf?.session?.code);
    setIsStopingInstaProf(false);
    setIsSearch(false);
    setData([]);
    setInstaProf([]);
    setIsBtnInstaProf(false);
    localStorage.removeItem("studentTempCodeInstaProf");
  };

  const handleCloseDrawer = () => {
    showDrawerSearchInstaProf();
  };

  const handleClear = (res: any[]) => {
    return res.filter(
      (arr: any, index: number, self: any[]) =>
        index ===
        self.findIndex(
          (t: any) => t.code === arr.code && t.prof?._id === arr.prof?._id
        )
    );
  };

  type AcceptTeacherProps = {
    teacher: { _id: string };
  };

  const handleStudentAcceptTeacher = useCallback(
    async (value: AcceptTeacherProps) => {
      try {
        const data = await ADS_SERVICES.StudentCheckSession(value.teacher._id);

        if (data?.data.inCurrentSession === false) {
          setIsAcceptedProf(true);

          const { session } = instaProf || {};

          const finalBody = {
            studentId: user?._id,
            profId: value.teacher._id,
            level: session?.level,
            topic: session?.topic,
            range: session?.range || ["12:00", "14:00"],
            startDate: session?.createdAt,
            codeInstaProf: session?.code,
          };

          const res = await ADS_SERVICES.AcceptAProfByStudent(finalBody);

          const dataRefuseProfByStudent = {
            codeInstaProf: currentProfAccepted?.codeInstaProf,
            selectedProfId: currentProfAccepted?.teacher?._id,
          };

          await Promise.all([
            socket.emit("acceptRequestByStudent", {
              code: currentProfAccepted?.code,
              reserveObj: res?.data,
            }),
            socket.emit("refuseProfByStudent", dataRefuseProfByStudent),
          ]);

          setInfoTeacherAcceptedReservation(res?.data);
          setIsSearch(false);
          resetDrawerInstaProf();
          setOpenD(true);
          refetch();
        } else {
          setTeacherHasSession(true);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsAcceptedProf(false);
        localStorage.removeItem("studentTempCodeInstaProf");
      }
    },
    [instaProf, user, dataCheckSaisson, currentProfAccepted, refetch]
  );

  const handleDisplayTeacher = (idTeacher: string) => {
    if (isInstaProf) {
      dispatch(setIsDrawerInstaProf(true));
    }

    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: `${isInstaProf ? "instaprof" : ""}`,
      instaprof: `${isInstaProf ? "find" : ""}`,
    };
    findRoom(finalData);
    dispatch(setIdReceiver(idTeacher));
  };

  const handleOpenDrwerProfileTeacher = () => {
    setOpenDrwerProfileTeacher(true);
  };

  useEffect(() => {
    OnSelectLevel(user?.levelId);
  }, [user?.levelId]);

  useEffect(() => {
    if (isSearch) {
      socket?.on("receivedProfAcceptedToStudent", (resp: any) => {
        setData((res: any) => {
          return [...res, resp];
        });
      });

      socket?.on("receivedProfRefusedToStudent", (resp: any) => {
        console.log(resp, "respresp0000");
      });
    }

    socket?.on("receivedProfRefusedToStudent", (resp: any) => {
      console.log(resp, "respresp0000");
    });
  }, [isSearch, loading, instaProf, data]);

  useEffect(() => {
    socket?.on("receivedStopRequest", (resp: any) => {
      if (resp) {
        setIsStopingInstaProf(true);
        setOpenD(false);
        showDrawerSearchInstaProf();
        refetch();
      }
    });
  }, [isStopingInstaProf]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessStopSession) {
      AppNotification(<span>Session arreter avec succès</span>, "AlbertHappy");
      setIsSearch(false);
      setData([]);
      setInstaProf([]);
      localStorage.removeItem("studentTempCodeInstaProf");
      refetch();
    }
  }, [isSuccessStopSession]);

  const content = (
    <div>
      <Row justify="center" align="middle" style={{ marginBottom: "0.5em" }}>
        <Col>
          <CloseOutlined style={{ color: "red", fontSize: "23px" }} />
        </Col>
      </Row>

      <Row justify="center" align="middle" style={{ marginBottom: "0.5em" }}>
        <Col>
          <h3 style={{ color: "#333" }}>Votre solde est insuffisant</h3>
        </Col>
      </Row>

      <Row justify="center" align="middle" style={{ marginBottom: "0.5em" }}>
        <Col>
          <h3>
            <span style={{ color: "red" }}>{user?.coins}</span> €
          </h3>
        </Col>
      </Row>
    </div>
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <>
      <AppDrawer
        headerStyle={{ border: "unset" }}
        title={
          <div>
            <div>
              <Row justify="center">
                <div className={s.drawer__badge}>
                  <img src={instaBadges} alt="" width={40} />
                </div>
              </Row>
              {!isStopingInstaProf && isSearch ? (
                <Fragment>
                  {" "}
                  {data.length > 0 ? (
                    <div className={s.drawer__title}>
                      <H1>Ta demande instaprof expire dans</H1>
                    </div>
                  ) : (
                    <H1>Alerte lancée !</H1>
                  )}
                </Fragment>
              ) : (
                <Row justify="center">
                  <div className={s.drawer__title}>
                    <H1>Lance une alerte aux profs</H1>
                    <p>
                      Nouveau sur <strong>Monamialbert.com</strong>, trouves ton
                      prof sauveteur <br />
                      pour un cours particulier MAINTENANT !
                    </p>
                  </div>
                </Row>
              )}
            </div>

            {isSearch && (
              <Affix offsetTop={top}>
                <div
                  style={{
                    width: "100%",
                    textAlign: "end",
                    paddingRight: "10%",
                  }}
                >
                  <Popconfirm
                    title="Attention, tu es sur le point de suspendre la recherche de profs pour ta session InstaProf."
                    onConfirm={async () => {
                      handleStopRequestInstaProf();
                      // setIsSearch(false);
                      // setData([]);
                      // setInstaProf([]);
                    }}
                    onCancel={() => { }}
                    okText="Oui"
                    cancelText="Non"
                  >
                    <Button danger onClick={() => setTop(top - 10)}>
                      Arrêter l'alerte
                    </Button>
                  </Popconfirm>
                </div>
              </Affix>
            )}
          </div>
        }
        bodyStyle={{ paddingTop: "1px" }}
        onClose={isSearch ? () => null : handleCloseDrawer}
        visible={openDrawerInstaProf}
        isDisplayingIcon={isSearch ? false : true}
      >
        {isLoading ? (
          <Fragment>
            <Skeleton active style={{ margin: "1em" }} />
            <Skeleton active style={{ marginBottom: "1em" }} />
            <Skeleton active style={{ marginBottom: "1em" }} />
          </Fragment>
        ) : (
          <Fragment>
            {isStopingInstaProf ? (
              <Result
                status="warning"
                title="Le prof a arrêté la session"
                extra={
                  <AppButton onClick={handleCloseDrawer}>
                    {" "}
                    Quitter la Session
                  </AppButton>
                }
              />
            ) : (
              <Fragment>
                {sessionData?.data[0]?.data?.etat === "booked" ? (
                  <Result
                    status="success"
                    title={
                      <h4 style={{ color: "#333" }}>
                        Hi{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {user?.firstname}
                        </span>{" "}
                        vous avez une réservation en cours
                      </h4>
                    }
                    extra={[
                      <a
                        href={
                          sessionData?.data[0]?.data?.studentLink ||
                          infoTeacherAcceptedReservation?.studentLink
                        }
                        target="_blank"
                      >
                        <AppButton>Lien de la visioconférence</AppButton>
                      </a>,
                      <Popconfirm
                        title="Tu veux bien stopper la session instaProf ?"
                        onConfirm={async () => {
                          stopRequestInstaProf({
                            id: sessionData?.data[0]?._id,
                            type: "teacher",
                          });
                        }}
                        onCancel={() => { }}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <AppButton loading={isStopingRequestInstaProf} style={{ marginTop: isMobile ? "10px" : "0px" }}>
                          Arrêter la session
                        </AppButton>
                      </Popconfirm>,
                    ]}
                  />
                ) : (
                  <Fragment>
                    {isSearch ? (
                      <Fragment>
                        <Row
                          justify="center"
                          style={{
                            marginBottom: "8px",
                          }}
                        >
                          <Col>
                            {data.length > 0 && (
                              <LoadingOutlined
                                style={{
                                  fontSize: "30px",
                                  color: "#0ED290",
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row
                          justify="center"
                          style={{
                            marginBottom: "8px",
                          }}
                        >
                          <Col>
                            {data.length > 0 && (
                              <CountdownTimer
                                targetDate={dateTimeAfterThreeDays}
                                handleCloseDrawer={handleCloseDrawer}
                              />
                            )}
                          </Col>
                        </Row>
                      </Fragment>
                    ) : (
                      <Form onFinish={onSearchTeacherInsta}>
                        <div className={s.drawer__search}>
                          {!isBtnInstaProf && (
                            <Row
                              justify="space-between"
                              gutter={12}
                              align="middle"
                            >
                              <Col span={12}>
                                <div className={s.field}>
                                  <Form.Item
                                    style={{ margin: 0, padding: 0 }}
                                    name={"level"}
                                    initialValue={user?.levelId}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Ce champ est requis",
                                      },
                                    ]}
                                  >
                                    <AppSelectGroupInscription
                                      placeholder="Choisir un niveau"
                                      onChange={(value: string) =>
                                        OnSelectLevel(value)
                                      }
                                    />
                                  </Form.Item>
                                </div>
                              </Col>

                              <Col span={12}>
                                <div className={s.field}>
                                  <Form.Item
                                    style={{ margin: 0, padding: 0 }}
                                    // initialValue="633f070956d09098666226f9"
                                    name={"matiere"}
                                    rules={[
                                      {
                                        required: true,
                                        message: "Ce champ est requis",
                                      },
                                    ]}
                                  >
                                    <Select
                                      placeholder="Choisir une matiere"
                                      style={{ width: "100%" }}
                                      bordered={false}
                                    >
                                      {tempTopic?.map((topic: any) => (
                                        <Option
                                          key={topic._id}
                                          value={topic._id}
                                        >
                                          {topic.title}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                </div>
                              </Col>
                            </Row>
                          )}

                          <div className={s.fullField}>
                            <Form.Item
                              name={"probleme"}
                              rules={[
                                {
                                  required: true,
                                  message: "Ce champ est requis",
                                },
                              ]}
                              style={{ margin: 0, padding: 2 }}
                            >
                              <Input.TextArea
                                showCount
                                maxLength={150}
                                bordered={false}
                                rows={6}
                                style={{ marginBottom: "1em" }}
                                placeholder="Décris brièvement ton problème ici"
                              />
                            </Form.Item>
                          </div>

                          <div className={s.fullField}>
                            <Form.Item
                              name={"budget"}
                              style={{ margin: 0, padding: 2, width: "100%" }}
                              rules={[
                                {
                                  required: true,
                                  message: "Un budget est requis",
                                },
                              ]}
                              hasFeedback
                              validateStatus={disabled ? "warning" : "success"}
                            >
                              <Input
                                bordered={false}
                                onChange={(e) => {
                                  if (e.target.value > user?.coins) {
                                    setDisabled(true);
                                    popupRef.current?.click();
                                  } else {
                                    setDisabled(false);
                                  }
                                }}
                                width="100%"
                                placeholder="Ton budget max pour cette session instaprof"
                                type="number"
                                suffix={
                                  <Popover
                                    content={disabled ? content : ""}
                                    title={false}
                                    trigger="click"
                                  >
                                    <div
                                      ref={popupRef}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <span>
                                        <strong>Euro</strong>
                                      </span>
                                    </div>
                                  </Popover>
                                }
                              />
                            </Form.Item>
                          </div>

                          <Row justify="center">
                            <Col>
                              {disabled ? (
                                <AppButton
                                  className={s.button}
                                  onClick={() =>
                                    disabled && popupRef.current?.click()
                                  }
                                >
                                  Lancer une alerte
                                </AppButton>
                              ) : (
                                <AppButton
                                  className={s.button}
                                  htmlTypeSubmit={true}
                                >
                                  Lancer une alerte
                                </AppButton>
                              )}
                            </Col>
                          </Row>
                        </div>
                        <Form.Item
                          name="uid"
                          initialValue={user?._id}
                        ></Form.Item>
                      </Form>
                    )}

                    {isSearch && (
                      <div className={s.main}>
                        {data.length <= 0 && (
                          <Row
                            justify="center"
                            style={{
                              paddingTop: "0.5em",
                            }}
                          >
                            <div className={s.wait}>
                              <LoadingOutlined
                                style={{
                                  fontSize: "40px",
                                }}
                              />

                              <h3>En attente des profs disponibles!</h3>
                            </div>
                          </Row>
                        )}

                        <GridInstaProf className={s.grid}>
                          {handleClear(data)?.map((ad: any, index: number) => (
                            <div
                              key={index}
                              onClick={() => setCurrentProfAccepted(ad)}
                            >
                              <CardAdsTeacher
                                onShowDrawerCalendarProf={() => null}
                                setIsBtnInstaProf={() => null}
                                isBtnInstaProf={isBtnInstaProf}
                                showDrawerSearchInstaProf={() => null}
                                resetDrawerInstaProf={resetDrawerInstaProf}
                                handleStudentAcceptTeacher={() =>
                                  handleStudentAcceptTeacher(ad)
                                }
                                PlanningIsVisble={false}
                                isInstaProf={true}
                                ad={onClear(ad)}
                                instaProfData={instaProf}
                                infoTeacherAcceptedReservation={
                                  infoTeacherAcceptedReservation
                                }
                                setCurrentProfAccepted={setCurrentProfAccepted}
                                currentProfAccepted={currentProfAccepted}
                                handleDisplayTeacher={handleDisplayTeacher}
                                handleOpenDrwerProfileTeacher={
                                  handleOpenDrwerProfileTeacher
                                }
                              />
                            </div>
                          ))}
                        </GridInstaProf>
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
            <AppDrawer
              title={<H1>Session déjà réservée</H1>}
              visible={teacherHasSession}
              onClose={() => {
                setTeacherHasSession(false);
                handleCloseDrawer();
              }}
            >
              <AppResult
                status={"404"}
                title={`Le prof ${currentProfAccepted?.teacher?.firstname} a déjà une réservation en cours.`}
                isMobile={false}
              />
            </AppDrawer>
          </Fragment>
        )}
      </AppDrawer>

      <SuccesSessionInsta
        openD={openD}
        setOpenD={setOpenD}
        currentProfAccepted={currentProfAccepted}
        infoTeacherAcceptedReservation={infoTeacherAcceptedReservation}
        handleDisplayTeacher={() =>
          handleDisplayTeacher(currentProfAccepted?.prof?._id)
        }
      />

      <DrawerProfileTeacher
        profId={currentProfAccepted?.prof?._id}
        setOpenDrwerProfileTeacher={setOpenDrwerProfileTeacher}
        openDrawerProfileTeacher={openDrawerProfileTeacher}
      />
    </>
  );
};

export default DrawerSearchInstaProf;
