import React from "react";

// !ANTD
import { Collapse, Image, Input } from "antd";

// !SVGS
import bank from "./assets/bank-transfer.svg";
import union from "./assets/western-union.svg";
import StyledAccordion, {
  VIREMENT_BANCAIRE,
  WESTERN_UNION,
} from "./constants/StylizedAccordion";
// import StyledAccordion, {
//   VIREMENT_BANCAIRE,
//   WESTERN_UNION,
// } from "./constants/StylizedAccordion";

// !COMPONENTS
const { Panel } = Collapse;
const { TextArea } = Input;

interface myInterface {
  updateWesternUnionHandler: any;
  updateRIBHandler: any;
  defaultValues?: any;
}
const Accordion: React.FC<myInterface> = (props) => {
  return (
    <StyledAccordion>
      <Collapse ghost defaultActiveKey={["1"]}>
        <Panel
          header={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                position: "relative", bottom: "10px",
              }}
            >
              <Image
                preview={false}
                width={25}
                src={bank}
                alt="bank-transfer"
              />
              <span>{VIREMENT_BANCAIRE}</span>
            </div>
          }
          key="1"
        >
          <div>
            <TextArea
              rows={7}
              onChange={(e) => props.updateRIBHandler(e.target?.value)}
              placeholder="Veuillez saisir votre RIB"
              defaultValue={props.defaultValues?.rib}
            />
          </div>
        </Panel>
        <Panel
          header={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                position: "relative", bottom: "10px",
              }}
            >
              <Image
                preview={false}
                width={25}
                src={union}
                alt="Western Union"
              />
              <div>{WESTERN_UNION}</div>
            </div>
          }
          key="2"
        >
          <div>
            <TextArea
              rows={7}
              onChange={(e) => props.updateWesternUnionHandler(e.target?.value)}
              placeholder="Veuillez saisir votre carte d’identité"
              defaultValue={props.defaultValues?.westernUnion}
            />
          </div>
        </Panel>
      </Collapse>
    </StyledAccordion>
  );
};
export default Accordion;
