import { Drawer } from "antd";
import React, { FC } from "react";

//!ASSETS
import H2 from "components/libs/subtitle";
import { useWindowSize } from "hooks/useWindowSize.hook";
import { useSelector } from "react-redux";
import { ReactComponent as Back } from "../../assets/images/app/backBlack.svg";

type Props = {
  title?: React.ReactNode;
  visible: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  closeIcon?: React.ReactNode;
  bodyStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  width?: string;
  style?: React.CSSProperties | undefined;
  isDisplayingIcon?: boolean;
};

const AppDrawer: FC<Props> = ({
  title,
  width,
  onClose,
  visible,
  children,
  closeIcon,
  bodyStyle,
  headerStyle,
  style,
  isDisplayingIcon = true,
}) => {
  // HOOK
  const size = useWindowSize();
  const { currentChat } = useSelector((state) => state.appChat);

  return (
    <Drawer
      title={<H2>{title}</H2>}
      placement={size.width <= 600 ? "bottom" : "right"}
      width={size.width <= 600 ? "100%" : "80%"}
      height={"100%"}
      onClose={onClose}
      visible={visible}
      style={style}
      closeIcon={isDisplayingIcon ? <Back style={{ width: "25px" }} /> : null}
      // closeIcon={!currentChat && <Back style={{ width: "25px" }} />}
      headerStyle={{ border: "none", marginBottom: 0, padding: "8px 12px" }}
      bodyStyle={bodyStyle}
    >
      {children}
    </Drawer>
  );
};

export default AppDrawer;
