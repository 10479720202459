import axios from "axios";
import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";

import * as BOOKED_RESERVATIONS from "../advertissements/advertissements.api.routes";
import {
  AUTH_PARENT_ORGANISATION,
  AUTH_STUDENT,
  AUTH_TEACHER,
  AUTH_USER,
  RESSOURCES_BO,
} from "./auth.api.routes";
import { CreateUserDto } from "./dto/create-user.dto";

import { getToken } from "api";
import { UserRoles } from "common/enums";
import { ApiResponse } from "common/interfaces";
import { FirebaseUser } from "config/firebase.config";
import { loginUserDto, registerUserDto } from "./dto/login-user.dto";

export const logout = async (): Promise<boolean> => {
  try {
    const auth = getAuth();
    await signOut(auth);
    return true;
  } catch (error: any) {
    console.log("error", error.message);
    return false;
  }
};

export const registerStudent = async (
  user: CreateUserDto
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${AUTH_STUDENT}`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const registerTeacher = async (
  user: CreateUserDto
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    // if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${AUTH_TEACHER}`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    const auth = getAuth();
    const currentUser: any = auth.currentUser;
    // if the  user is new delete the user
    const isNew =
      currentUser?.metadata?.createdAt === currentUser?.metadata?.lastLoginAt;
    if (isNew && currentUser) {
      await deleteUser(currentUser);
    } else {
      await logout();
    }

    throw error;
  }
};

export const registerParentOrganisation = async (
  values: CreateUserDto,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.post(`${AUTH_PARENT_ORGANISATION}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    const auth = getAuth();
    const currentUser: any = auth.currentUser;
    const isNew = currentUser?.metadata?.createdAt === currentUser?.metadata?.lastLoginAt;
    if (isNew && currentUser) {
      await deleteUser(currentUser);
    } else {
      await logout();
    }
    throw error;
  }
};

export const reauthenticateParentOrganisation = async (
  email: string,
  password: string,
): Promise<FirebaseUser | undefined> => {
  try {
    const user = {email: email, password: password};
    const response = await logInFirebaseWithEmailAndPassword(user);
    return response;
  } catch (error: any) {
    throw error;
  }
};

export const registerSponsoredStudentWithEmailPassword = async (
  values: any,
): Promise<ApiResponse | undefined> => {
  try {
    const response = await registerInFirebaseWithEmailAndPassword(values);
    console.log('User UID : ' + response?.uid);
    if (response) {
      const studentData = {
        uid: response.uid,
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        pseudo: values.pseudo,
        levelId: values.levelId,
        profile: "/assets/avatars/student.svg",
        avatar: "",
        roles: [UserRoles.STUDENT],
        pays: values.pays,
        phone: "0000000000",
        education: "École française internationale de Tirana",
        etablissement: "École française internationale de Tirana",
        educSystem: values.educSystem,
        accountManaged: values.accountManaged,
        organisationName: values.organisationName,
      };
      console.log(studentData);
      const result = await registerSponsoredStudent(studentData);
      return result;
    }
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const registerSponsoredStudent = async (
  values: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${AUTH_STUDENT}`, values, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const registerInFirebaseWithEmailAndPassword = async (
  infos: registerUserDto
): Promise<FirebaseUser | undefined> => {
  try {
    const { email, password } = infos;
    const auth = getAuth();
    const userCredential: any = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    // await userCredential?.user?.sendEmailVerification();
    return user;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const registerInFirebaseWithGoogle = async (): Promise<
  FirebaseUser | undefined
> => {
  try {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();
    provider.addScope("profile");
    provider.addScope("email");
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    return user;
  } catch (error: any) {
    console.log("error", error.message);
    await logout();
    throw error;
  }
};

export const logInFirebaseWithEmailAndPassword = async (
  infos: loginUserDto
): Promise<FirebaseUser | undefined> => {
  try {
    const auth = getAuth();
    const { email, password } = infos;
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    localStorage.setItem('userPasscode', password);
    return user;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getUserByUid = async (
  uid: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`${AUTH_USER}/uid/${uid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const loginUser = {};

export const updateTeacher = async (
  id: string,
  user: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.patch(`${AUTH_TEACHER}/${id}`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const updateStudent = async (
  id: string,
  user: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.patch(`${AUTH_STUDENT}/${id}`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const updateParentOrganisme = async (
  id: string,
  user: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.patch(`${AUTH_PARENT_ORGANISATION}/${id}`, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getBookedReservations = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(
      `${BOOKED_RESERVATIONS.getBookedReservations(id)}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const createRetrait = async (
  demande: any
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${RESSOURCES_BO}`, demande, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const inviteStudents = async (
  id: string,
  listInvite: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/assistance/superStudent/invite/student/send`, {id, listInvite}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const registerStudents = async (
  id: string,
  listInvite: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/assistance/superStudent/invite/student/send`, {id, listInvite}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getSponsoredStudents = async (
  search: string,
  level: string,
  id: string,
  page: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/quizz/superStudent/search/subStudents?search=${search}&level=${level}&id=${id}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getStudentStats = async (
  id: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/quizz/superStudent/search/subStudents/stats/more?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getStudentPaiementHistory = async (
  id: string,
  page: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/advertisements/historique/paiements/find/one?id=${id}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getParentOrganismTopUpHistory = async (
  id: string,
  page: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-userData.monamialbert.com/api/v1/payments/superStudent/historique?id=${id}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getParentOrganismPaiementHistory = async (
  id: string,
  page: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/advertisements/historique/paiements/find/all?id=${id}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const suggestTeacher = async (
  id: string,
  idProf: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/users/superStudent/prof/affect`, {id, idProf}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const cancelSuggestTeacher = async (
  id: string,
  idProf: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/users/superStudent/prof/desaffect`, {id, idProf}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const askBudget = async (
  id: string,
  idProf: string,
  idStud: string,
  montant: any,
  message: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/retraits/student/budget/demande`, {id, idProf, idStud, montant, message}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getParentStudents = async (
  id: string,
  page: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/users/superStudent/registred/students/find?id=${id}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getSuggestedTeachers = async (
  page: string,
  userId: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/advertisements/reserve/search`, {page, userId}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const sendBudgetDemand = async (
  id: string,
  idProf: string,
  idStud: string,
  montant: any,
  message: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/retraits/student/budget/demande`, {id, idProf, idStud, montant, message}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const sendAccountTopUp = async (
  id: string,
  idStud: string,
  montant: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/users/superStudent/coins/affect/one`, {id, idStud, montant}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const sendEqualTopUp = async (
  id: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/users/superStudent/coins/affect`, {id}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const dissociateAccount = async (
  id: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/users/superStudent/registred/students/disassociate`, {id}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getStudentHistoryData = async (
  id: string,
  page: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/advertisements/parent/students/find/one/historique?idStud=${id}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getTeacherStudents = async (
  search: string,
  id: string,
  page: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/advertisements/prof/students/find/all?search=${search}&id=${id}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getTeacherStudentHistoryData = async (
  idStud: string,
  idProf: string,
  page: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.get(`https://api-ressources.monamialbert.com/api/v1/advertisements/prof/students/find/one/historique?idStud=${idStud}&idProf=${idProf}&page=${page}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const rateStudent = async (
  id: string,
  rateCours: any,
  rateApplication: any,
  rateExercice: any,
  comment: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/advertisements/prof/students/session/one/rate`, {id, rateCours, rateApplication, rateExercice, comment}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const getStudentEmailPseudo = async (
  firstname: string,
  lastname: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/assistance/superStudent/invite/student/send`, {firstname, lastname}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};

export const returnUserEmail = async (
  pseudo: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`https://api-ressources.monamialbert.com/api/v1/assistance/superStudent/invite/student/send`, {pseudo}, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    console.log("error", error.message);
    throw error;
  }
};
