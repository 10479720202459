import { CheckCircleOutlined } from "@ant-design/icons";
import { Avatar, Divider, Row, Skeleton, Tag, Typography } from "antd";
import Col from "antd/es/grid/col";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import Matiers from "components/matiers";
import moment from "moment";
import { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useGetHistoriqueQuery } from "store/services/teacher/classes.services";
import s from "./assets/calendar.module.css";

const { Text } = Typography;

const CalendarOfReservationTeacher = () => {
  const { user } = useSelector((state) => state.auth);
  const { data, isLoading, isError, refetch } = useGetHistoriqueQuery(
    user?._id
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          {data?.data?.length > 0 ? (
            <div className={s.calendar}>
              {data &&
                data?.data?.map((cren: any, index: number) => (
                  <div key={index}>
                    <Row
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: "2em" }}
                    >
                      <Col>
                        <H2>{moment(cren?.day).format("LL")}</H2>
                      </Col>
                    </Row>

                    <div className={s.creneaux} key={index}>
                      <div className={s.card}>
                        <div>
                          <Row
                            justify="space-between"
                            align="middle"
                            gutter={24}
                          >
                            <Col>
                              <Avatar.Group
                                maxCount={2}
                                maxStyle={{
                                  color: "#f56a00",
                                  backgroundColor: "#fde3cf",
                                }}
                              >
                                <Avatar
                                  size={44}
                                  style={{ backgroundColor: "#7fb1b2" }}
                                  src={cren?.studId?.profile}
                                />
                              </Avatar.Group>
                            </Col>
                            <Col>
                              <H2>
                                {cren?.studId?.firstname}{" "}
                                {cren?.studId?.lastname}
                              </H2>
                            </Col>
                            <Col>
                              <div className={s.range}>
                                <span>{cren?.range[0]}</span>
                              </div>
                            </Col>

                            <Col>
                              <div style={{ marginBottom: "0.5em" }}>
                                <span>50 min</span>{" "}
                                <span className={s.bar}>|</span>{" "}
                                <Tag color="default">{cren?.price} Euro</Tag>
                              </div>
                            </Col>

                            <Col>
                              <div className={s.matiere}>
                                <Matiers
                                  style={{ margin: 0 }}
                                  cours={cren?.topic?.title}
                                  level={cren?.level?.title}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>

                        <div
                          style={{
                            marginLeft: "auto",
                          }}
                        >
                          <div>
                            <Row justify="end">
                              <Col>
                                {false ? (
                                  <Tag
                                    icon={<CheckCircleOutlined />}
                                    color="success"
                                  >
                                    success
                                  </Tag>
                                ) : (
                                  <a href={cren?.profLink} target="_blank">
                                    <AppButton
                                      style={{
                                        maxWidth: "200px",
                                        minWidth: "200px",
                                      }}
                                    >
                                      Rejoindre le meet
                                    </AppButton>
                                  </a>
                                )}
                              </Col>
                            </Row>
                          </div>
                        </div>
                      </div>

                      {data?.data[data?.data.lenght - 2] && <Divider />}
                    </div>
                  </div>
                ))}
            </div>
          ) : (
            <AppResult status={"info"} title="Aucune réservation" />
          )}
        </Fragment>
      )}
    </div>
  );
};

export default CalendarOfReservationTeacher;
