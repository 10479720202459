import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_BASE_URL } from "./user.api.routes";

export const findOne = async (
  search: string,
  roles: string[]
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(`${API_BASE_URL}`, {
      roles: roles,
      search: search,
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
