import { Image, Steps } from "antd";
import { FC, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    closePorteFeuilleDrawer__,
    closeProfileDrawer,
    openProfileDrawer,
} from "store/auth/authSlice";
import {
    closePaiementDrawer,
    openChatDrawerStudent,
    openPaiementDrawer,
} from "store/chat/mainSlice";

import * as appRoutes from "config/routes.config";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

//!COMPONENTS

//!IMAGES
import logo from "assets/images/app/header/logo.svg";

import Student from "./assets/images/mesclasse.svg";
import Message from "./assets/images/msg.svg";
import FindTeacher from "./assets/images/prof.svg";
import Wallet from "./assets/images/wallet.svg";

import MenuIcon from "./assets/images/menu.svg";

//!STYLES
import MenuModal from "screens/teacher/components/menu";
import style from "./assets/user.header.module.css";

//!REDUX
import { UserRoles } from "common/enums";
import ParentOrganismNavBar from "components/parentOrganismNavBar";
import { toggleAppChat } from "store/AppChat/appChat";
import { useGetNotifByUserQuery } from "store/services/teacher/notification.services";
import NavUser from "./NavUser";
import WhenParentOrganismeNotConnected from "./header.parent";

const { Step } = Steps;

const UserParentOrganisation: FC = () => {
    //!HOOKS
    const { isLoggedIn } = useSelector((state) => state.auth);
    const { isFullscreen } = useSelector((state) => state.app);
    const { isRegistering } = useSelector((state) => state.app);

    //!RETURN
    return (
        <div>
            {isLoggedIn && !isFullscreen && !isRegistering && <WhenConnected />}
            {isLoggedIn && !isFullscreen && <WhenParentOrganismeNotConnected />}
            {!isLoggedIn && <WhenParentOrganismeNotConnected />}
        </div>
    );
};

const WhenConnected: FC = () => {

    //!HOOKS
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user } = useSelector((state) => state.auth);
    const { currentChat } = useSelector((state) => state.appChat);
    const isParent = user?.roles?.includes(UserRoles.PARENT);

    //!STATE
    const [visible, setVisible] = useState(false);

    const openMessagerieDrawer = () => {
        dispatch(openChatDrawerStudent());
        dispatch(closeProfileDrawer());
        dispatch(closePorteFeuilleDrawer__());
        dispatch(closePaiementDrawer());
        setVisible(false);
    };

    const openPaiementStudentDrawer = () => {
        dispatch(openPaiementDrawer());
    };
    //!FUNCTIONS

    const openDrawer = () => dispatch(openProfileDrawer());

    const MENU = [
        {
            id: 1,
            name: "Mes élèves",
            icon: Student,
            iconSize: 30,
            link: isParent ? appRoutes.PARENT_DASHBOARD : appRoutes.ORGANISME_DASHBOARD,
        },
        {
            id: 2,
            name: "Annuaire des profs",
            icon: FindTeacher,
            iconSize: 30,
            link: isParent ? appRoutes.FIND_STUDENT_TEACHER : appRoutes.ORGANISM_FIND_STUDENT_TEACHER,
        },
        {
            id: 3,
            name: "Messagerie",
            icon: Message,
            iconSize: 25,
            link: "#",
        },

        {
            id: 4,
            name: "Portefeuille",
            icon: Wallet,
            iconSize: 25,
            link: isParent ? appRoutes.WALLETS : appRoutes.ORGANISM_WALLETS,
        },
    ];

    const {
        data: dataNotif,
        refetch,
        isLoading,
    } = useGetNotifByUserQuery(user._id);

    let filterNotifForMsg =
        dataNotif?.data?.filter(
            (data: any) => data?.type === "messagerie" && data?.seen === false
        ) || [];

    const openChat = () => {
        dispatch(toggleAppChat(true));
        ///handleViewAllMessage(filterNotifForMsg);
    }

    //!RETURN
    return (
        <>
            <div className={style.header} style={{}}>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", maxWidth: "1200px", margin: "0px auto" }}>
                    <div className={style.menu} style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <div className={style.logo}>
                            <Link to={"/"}>
                                <Image preview={false} src={logo} width={150} />
                            </Link>
                        </div>

                        <div
                            className={style.header__menu}
                            onClick={() => setVisible(!visible)}
                        >
                            <img src={MenuIcon} alt="" width={26} />
                        </div>
                    </div>

                    <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                        <nav className={style.nav__bar} style={{ marginRight: "10px" }}>
                            <ul className={style.nav__item}>
                                {MENU.map((item) => (
                                    <li className={style.__isMobile}>
                                        <ul>
                                            <li>
                                                <Link to={item?.link} onClick={() => item.name === "Messagerie" ? openChat() : {}}>
                                                    <Image
                                                        preview={false}
                                                        src={item.icon}
                                                        width={item?.iconSize || 29}
                                                    />
                                                </Link>
                                            </li>
                                            <li>
                                                {" "}
                                                <Link to={item?.link} onClick={() => item.name === "Messagerie" ? openChat() : {}}>{item.name}</Link>
                                            </li>
                                        </ul>
                                    </li>
                                ))}
                            </ul>
                        </nav>
                        <ParentOrganismNavBar user={user} />
                    </div>
                </div>


            </div>

            <MenuModal
                visible={visible}
                setVisible={setVisible}
                openDrawer={openDrawer}
                openMessagerieDrawerStudent={openMessagerieDrawer}
                openPaiementDrawer={openPaiementStudentDrawer}
            />

            {!currentChat && (
                <NavUser
                    openMessagerieDrawer={openMessagerieDrawer}
                    visible={visible}
                    setVisible={setVisible}
                />
            )}

        </>
    );
};

export default UserParentOrganisation;
