import axios from "axios";
import { API_BASE_URL, GET_LIST_DATA } from "./teacher.routes";
import { ApiResponse } from "common/interfaces";
import { getToken } from "api";

export const findAll = async (): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getDataList = async (
  type: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(GET_LIST_DATA(type), {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const uploadToCloudinary = async (
  image: any,
  type: any
): Promise<ApiResponse | undefined> => {

  const formData = new FormData();
  formData.append("file", image);

  try {
    const response = await axios.post(
      `https://api-ressources.monamialbert.com/api/v1/users/profil/update/upload?type=${type}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }

};

export const checkCourseStatus = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchCourseDocs = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const fetchSommary = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};