import { findAllByTeacher } from "api/services/advertissements/advertissements.services";
import { useState, useEffect, useCallback } from "react";

// type Exercise = {
//   _id?: string;
//   title?: string;
//   description?: string;
//   solutions?: any[];
//   clues?: any[];

//   createdAt?: Date;
//   updatedAt?: Date;
// };

const useTeacherAds = (id: string) => {
  //!STATE
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<Boolean>(true);
  const [error, setError] = useState<string>("");


  const fetchData = useCallback(async (passedId: string) => {
    try {
      setLoading(true);
      const response = await findAllByTeacher(passedId);
      setData(response?.data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [])

  // const fetchData = async (passedId: string) => {
  //   try {
  //     setLoading(true);
  //     const response = await findAllByTeacher(passedId);
  //     setData(response?.data);
  //   } catch (error: any) {
  //     setError(error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    fetchData(id);
  }, [id]);

  return { data, loading, error, setData };
};

export default useTeacherAds;
