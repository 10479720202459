import { Button, Skeleton } from "antd";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import moment from "moment";
import { FC, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { displayDrawerAdsSpecific } from "store/adventissements";
import { useGetAllAdSpecificQuery } from "store/services/teacher/ads.services";
import CardAvailabilitySpecific from "./card";
import DrawerAdsCreneauSpecific from "./drawer";
import s from "./style.module.css";

interface Niche {
  _id: string;
  date: string;
  level: { _id: string; title: string; levelGroup?: string }[];
  topic: { _id: string; title: string; level: string }[];
  timeRanges: { range: { hour: string; allow: boolean }[] }[];
  idCreneau: string;
}

interface Data {
  _id: string;
  niches: Niche[];
}

const days = [
  { name: "Lundi", key: "lundi" },
  { name: "Mardi", key: "mardi" },
  {
    name: "Mercredi",
    key: "mercredi",
  },
  { name: "Jeudi", key: "jeudi" },
  { name: "Vendredi", key: "vendredi" },
  { name: "Samedi", key: "samedi" },
  { name: "Dimanche", key: "dimanche" },
];

const TeacherAvailabilitySpecific: FC = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { data: crenaux, isLoading } = useGetAllAdSpecificQuery(user?._id);

  function sortNicheByDate(data: Data[]): { [key: string]: Niche[] } {
    if (data) {
      const nichesByDate: { [key: string]: Niche[] } = {};

      for (const { niches, _id } of data) {
        for (const niche of niches) {
          const dayName = moment(niche.date).format("dddd");

          if (!nichesByDate[dayName]) {
            nichesByDate[dayName] = [];
          }

          nichesByDate[dayName].push({
            ...niche,
            _id: _id,
          });
        }
      }

      return nichesByDate;
    }

    return {};
  }

  return (
    <div>
      <div className={s.__form}>
        {isLoading ? (
          <>
            <Skeleton active />
            <Skeleton active />
            <Skeleton active />
          </>
        ) : (
          <Fragment>
            {crenaux?.data.length <= 0 ? (
              <AppResult
                status={"info"}
                title={"Vous avec aucun créneau pour le moment "}
                extra={
                  <AppButton
                    onClick={() => dispatch(displayDrawerAdsSpecific(true))}
                  >
                    Ajouter un créneau spécifique
                  </AppButton>
                }
              />
            ) : (
              <>
                {days.map((day) => (
                  <div key={day.key}>
                    <Button type="primary">{day.name}</Button>
                    {sortNicheByDate(crenaux?.data)[day.key]?.map(
                      (item: any, i: number) => (
                        <CardAvailabilitySpecific item={item} key={i} />
                      )
                    )}
                  </div>
                ))}
              </>
            )}
          </Fragment>
        )}
      </div>
      <DrawerAdsCreneauSpecific />
    </div>
  );
};

export default TeacherAvailabilitySpecific;
