import { Drawer, Form, Input, Row } from "antd";
import { UserRoles } from "common/enums";
import AppButton from "components/libs/button";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import s from "./password.module.css";

type Props = {
    visible: boolean,
    onClose: () => void,
}

const SendUpdatePasswordRequestDrawer: React.FC<Props> = ({ visible, onClose }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    //!HOOKS
    const { t } = useTranslation();
    const auth = useSelector((state) => state.auth);
    const [formInfos] = Form.useForm();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [isLoading, setIsLoading] = useState<boolean>(false);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    const closeDrawer = async () => {
        onClose();
    }

    const [currentEmail, setCurrentEmail] = useState('');
    const [error, setError] = useState<any>(null);
    const [successMessage, setSuccessMessage] = useState<string>("");

    const handlePasswordRequest = async () => {

        const isParent = auth.user?.roles?.includes(UserRoles.PARENT);
        const isOrganism = auth.user?.roles?.includes(UserRoles.ORGANISME);
        const userEmail = user?.email;

        if (isParent) {
            if (userEmail === currentEmail) {
                sendPasswordRequest();
            }
            else {
                setError("Cette addresse email est différente de la tienne. Entre ton adresse email!");
            }
        }
        else if (isOrganism) {
            if (userEmail === currentEmail) {
                sendPasswordRequest();
            }
            else {
                setError("Cette addresse email est différente de la tienne. Entre ton adresse email!");
            }
        }
        else {
            sendPasswordRequest();
        }

    };

    const sendPasswordRequest = async () => {
        setIsLoading(true);
        try {
            const auth = getAuth();
            await sendPasswordResetEmail(auth, currentEmail);
            setSuccessMessage('E-mail de réinitialisation du mot de passe envoyé avec succès. Merci de consulter vos emails.');
            setIsLoading(false);
        } catch (error) {
            setError(error);
            setIsLoading(false);
        }
    }

    return (
        <div>
            <Drawer
                title={<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                    <div style={{ textTransform: "uppercase", fontSize: "1.2em", margin: "0px auto" }}>{"Réinitialiser le mot de passe"}</div>
                </div>}
                placement="right"
                closable={true}
                onClose={closeDrawer}
                visible={visible}
                width={isMobile ? "100%" : "70%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "0px" }}
            >
                <div style={{ alignItems: "flex-start", margin: "40px auto" }}>
                    <Form
                        validateTrigger={["onFinish"]}
                        validateMessages={validateMessages}
                        name="registerParent"
                        autoComplete="off"
                        form={formInfos}
                        onFinish={handlePasswordRequest}
                    >
                        <div style={{ width: "55%", margin: "0px auto" }}>

                            <Form.Item
                                name="email"
                                rules={[{ required: true }]}
                                style={{ marginBottom: "20px" }}
                            >
                                <Input
                                    type="email"
                                    className={s.field}
                                    placeholder={"Entre ton adresse email"}
                                    value={currentEmail}
                                    onChange={(e) => setCurrentEmail(e.target.value)}
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>

                        </div>

                        <Row justify="center" style={{ marginTop: "30px" }}>
                            <Form.Item>
                                <AppButton loading={isLoading} htmlTypeSubmit={true}><a className={s.nextButton}>Valider</a></AppButton>
                            </Form.Item>
                        </Row>
                    </Form>
                    {error && <div style={{ color: 'red', margin: "0px auto", textAlign: "center" }}>{error}</div>}
                    {successMessage && <div style={{ color: 'green', margin: "0px auto", textAlign: "center" }}>{successMessage}</div>}
                </div>

            </Drawer>
        </div>
    );

};

export default SendUpdatePasswordRequestDrawer;