export const HOME = "/";
export const PROFESSEUR = "/teacher";
export const PARENT = "/parent";
export const ORGANISME = "/organisme";

export const LOGIN = "/login";
export const REGISTER = "/register";
export const REGISTER_TEACHER = "/register/teacher";
export const REGISTER_TEACHER_BY_EMAIL = "/register/teacher/email";
export const TEACHER_ACTIVITIES = "/teacher/activites";
export const TEACHER_DASHBOARD = "/teacher/dashboard";
export const TEACHER_SET_CALENDAR = "/teacher/calendrier"
export const TEACHER_COURSES = "/teacher/courses";
export const TEACHER_QUIZZ = "/teacher/quizz";
export const TEACHER_EXERCICES = "/teacher/exercises";
export const TEACHER_ROOMS = "/teacher/rooms";
export const TEACHER_CALENDAR = "/teacher/calendar";
export const TEACHER_ADS = "/teacher/ads";
export const TEACHER_CREATE__COURS = "/teacher/create/cours";
export const TEACHER_CREATE__QUIZ = "/teacher/create/quiz";
export const TEACHER_CREATE__EXO = "/teacher/create/exercice";
export const TEACHER_CONTACT = "/contact";
export const TEACHER_PROPOSITION_COURS = "/teacher/course";
export const TEACHER_PROPOSITION_EXO = "/teacher/exercice";
export const TEACHER_GET_EXO = "/teacher/view/exercice";
export const FAQ = "/faq";
export const PRIVACY = "/privacy";
export const LOGINS = "/start";
export const COURSESBYLEVELS = "/teacher/courses-by-levels";
export const GETCHAPTERS = "/teacher/view/chapters";
export const GETCHAPTER = "/teacher/view/chapter";
export const FINDQUIZZFORCHAPTER = "/teacher/view/quizz";
export const FINDEXOFORCHAPTER = "/teacher/view/exercice/for/chapter";
export const PROFILETEACHER = "/prof";

export const COMPLETE_PROFILE = "/teacher/profile/complete";
export const TEACHER_STUDENTS = "/teacher/students";
export const TEACHER_STUDENT_HISTORY = "/teacher/studentHistory";

//!STUDENT
export const REGISTER_STUDENT = "/register/student";
export const REGISTER_STUDENT_BY_EMAIL = "/register/student/email";
///export const STUDENT_DASHBOARD = "/student/dashboard";
export const STUDENT_DASHBOARD = "/eleve/dashboard";
export const STUDENT_CHAPTERS = "/student/chapters";
export const STUDENT_LEVELS = "/student/quizz/levels";
export const STUDENT_SEARCH_QUIZZ = "/student/search/quizz";
export const STUDENT_QUIZZ = "/student/quizz";
export const STUDENT_QUIZZ_ = "/student/quizz";
export const STUDENT_STATS = "/student/stats";
export const STUDENT_EXOS = "/student/exercices";
export const STUDENT_EXO = "/student/exercice";
///export const STUDENT_CALENDAR = "/student/calendar";
export const STUDENT_CALENDAR = "/eleve/calendrier";
///export const STUDENT_SEARCH_BEST_TEACHER = "/student/search/best-teacher";
export const STUDENT_SEARCH_BEST_TEACHER = "/eleve/recherche/trouver-un-prof-en-ligne";
///export const STUDENT_MY_CLASS = "/student/myclasses";
export const STUDENT_MY_CLASS = "/eleve/mes-classes";
export const STUDENT_FIND_COURSE = "/student/courses";
///export const STUDENT_MY_CONTENT = "/student/my-content";
export const STUDENT_MY_CONTENT = "/eleve/mon-contenu";
export const FORBIDDEN = "/401";
export const NOT_FOUND = "*";
export const SUCCESS_ACHAT = "/achat/success";
export const ECHEC_ACHAT = "/achat/echec";
//Parents & Organisme
export const REGISTER_PARENT = "/parent/register";
export const LOGIN_PARENT = "/parent/login";
export const PARENT_DASHBOARD = "/parent/dashboard";
export const STUDENT_HISTORY = "/parent/studentHistory";
export const STUDENT_STATISTIQUES = "/parent/studentStats";
export const FIND_STUDENT_TEACHER = "/parent/teachers"
export const WALLETS = "/parent/wallet"

export const REGISTER_ORGANISME = "/organisme/register";
export const LOGIN_ORGANISME = "/organisme/login";
export const ORGANISME_DASHBOARD = "/organisme/dashboard";
export const ORGANISME_STUDENT_HISTORY = "/organisme/studentHistory";
export const ORGANISM_STUDENT_STATISTIQUES = "/organisme/studentStats";
export const ORGANISM_FIND_STUDENT_TEACHER = "/organisme/teachers";
export const ORGANISM_WALLETS = "/organisme/wallet";